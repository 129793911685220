.body-rejoin {
    *, ::before, ::after { box-sizing: border-box; }
    box-sizing: border-box;
    /*== GLOBAL ==*/
    header {
        background-color: #F7F7F7;
        padding: 10px 0;
    }
    
    header a {
        display: inline-block;
  }
    
    header p {
        font-size: 35px;
        font-family: "Eni";
        display: inline-block;
        vertical-align: bottom;
        margin-bottom: 20px;
        margin-left:20px;
        font-weight: 700;
        
    }
    
    .footer-rejoin {
        background-color: #272A34;
        padding: 30px 0;
        text-align: right;
    }
    
    .footer-rejoin ul {
        list-style: none;
        margin: 0;
        padding: 0
    }
    
    .footer-rejoin ul li {
        display: inline-block;
        border-right: 1px solid white
    }
    
    .footer-rejoin ul li a {
        color: white;
        padding: 7px 20px;
        font-size: 14px;
        text-decoration:none;
    }
    
    .footer-rejoin ul li a:hover {
        color: white;
        text-decoration: underline;
    }
    
    .footer-rejoin ul li:last-child {
        border: none;
        padding-top:20px
    }
    
    .footer-rejoin ul li:last-child a {
        padding-right: 0;
        padding-top:20px;
    }


    @media (max-width:767px) {
        .footer-rejoin ul li {
            display: block;
            border: none;
        }
    
        .footer-rejoin ul li a,
        .footer-rejoin ul li:last-child a {
            padding: 20px 20px
        }
    
        .footer-rejoin {
            text-align: center;
        }

        header p {
            font-size:20px;
            margin-bottom:2px;
        }
    }
    
    @media (max-width:767px) {

        header p {
            font-size:20px;
            margin-bottom:2px;
        }
    }

    .main-container {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
    
    .main-container .main-content {
        flex-grow: 1
    }
    
    
    .contain {
        width: 100%;
        max-width: 1360px;
        margin: 0 auto;
        padding: 0 15px;
    }
    
    .main-content {
        flex-grow: 1
    }
}