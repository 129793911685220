@include phone {
    * {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
    }
}

.body-dashboard {
    background-color: white;
    width: 100%;
    height: 100%;
    position: relative;

    @include fadeIn($duration: 0.3s, $function: ease-in-out, $delay: 0.1s);

    .loading {
        margin-top: 80px;

        @include tablet {
            margin-top: 50px;
        }

        @include phone {
            margin-top: 30px;
        }
    }

    .share-modal {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        z-index: 10000;

        .content-modal {
            display: none;
            position: fixed;
            text-align: center;
            top: 30%;
            left: 35%;
            width: 600px;
            height: 350px;
            background-color: #fff;
            z-index: 10000;
            box-shadow: 0px 0px 36px -10px rgba(0, 0, 0, 0.25);

            > .modal-title {
                font-family: $first-font;
                font-size: 25px;
                margin: 20px 0 20px 0;
            }

            > button {
                display: inline-block;
                width: 180px;
                height: 180px;
                background-color: none;
                border: none;
                cursor: pointer;
                font-size: 15px;
                font-family: $second-font;
                transition: background-color 0.3s;

                > p {
                    margin-top: 14px;
                }

                > img {
                    width: 50%;
                    width: 50%;
                }

                &:hover {
                    background-color: #f7f7f7;
                }
            }

            .consent {
                font-family: $second-font;
                padding: 20px;
            }

            @include limit-screen {
                left: 25%;
            }

            @include tablet {
                left: 20%;
            }

            @include phone {
               top: 150px;
               left: 0;
               width: 100%;
            }

        }
    }

    .main-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-family: $first-font;
        font-size: 16px;
        flex: 1;

        max-width: 1360px;
        width: 100%;
        margin: auto;

        @include tablet {
            max-width: calc(100% - 40px);
        }

        @include limit-screen {
            max-width: calc(100% - 40px);
        }

        @include phone {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            max-width: calc(100% - 40px);
        }

        .title-ranking {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: 40px 0 25px 0px;
            font-size: 30px;

            @include tablet {
                font-size: 30px;
            }

            @include phone {
                font-size: 20px;
            }

            .date-ranking {
                margin-left: 8px;
            }
        }

        .row-container {
            display: flex;
            flex-direction: row;
            width: 100%;

            @include tablet {
                flex-direction: column;
                height: 100%;
            }

            @include phone {
                flex-direction: column;
                width: 100%;
            }

            .left-container {
                font-family: $second-font;
                width: 60%;
                background-color: #f7f7f7;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include tablet {
                    width: 100%;
                    padding: 0px;
                }

                @include phone {
                    width: 100%;
                    width: unset;
                    padding: 10px;
                }
            }

            .right-container {
                width: 40%;
                display: flex;
                flex-direction: column;
                align-items: center;

                @include tablet {
                    width: 100%;
                    margin-top: 20px;
                }


                @include phone {
                    width: 100%;
                    margin-top: 20px;
                    background: none;
                    background-color: #373941;
                }
            }
        }

        .comparison-container {
            position: relative;
            padding: 20px 40px 10px 20px;

            @include phone{
              padding-right: 20px;
              width: 100%;
            }

            .title-container {
                font-size: 30px;
                font-family: $first-font;
                margin: 5px 0px 10px 0;
                font-weight: 400;

                @include tablet {
                    margin-top: 10px;
                }

                @include phone {
                    .title-container {
                        font-size: 24px;
                    }
                }
            }

            #comparison-sub-title-container {
                margin-bottom: 20px;
                img {
                    height: 25px;
                    margin-left: 5px;
                }
            }

            .no-comparison {
                img {
                    width: 135px;
                    margin-bottom: 20px;
                }
            }

            .content-container {
                display: flex;
                flex-direction: row;
                width: 100%; // height: 100%;
                justify-content: center;
                margin-top: 15px;

                @include phone {
                    flex-direction: column;
                }


                .emote-container {
                    flex: 1;

                    @include phone {
                        flex: initial;
                        margin-top: 40px;
                        height: 60px;
                        position: relative;
                        text-align: left;
                        padding-left: 100px;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: flex-start;
                    }


                    .emote-image {
                        width: 100px;
                        margin: 10px 0;

                        @include phone{
                            width: 50px;
                            height: 50px;
                            position: absolute;
                            top: 0;
                            left: 20px;
                        }

                        @include bounceIn($duration: 0.4s, $function: ease-in-out, $delay: 0.7s);
                    }

                    .emote-description {
                        font-weight: 400;

                        @include tablet {
                            padding: 0 10px;
                        }

                        // @include phone{
                        //     height: 55px;
                        // }
                    }

                    .title-emote {
                        font-weight: 600;
                    }
                }

                .chart-ranking-container-placeholder {
                    padding: 50px 50px 0 50px;
                    margin-bottom: 100px;

                    @include tablet {
                        margin-bottom: 100px;
                    }

                    @include phone {
                        margin-bottom: 0px;
                    }

                }

                .chart-ranking-container {
                    padding: 0 20px;
                    flex: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .chart-tooltip-container {
                        display: flex;
                        flex-direction: row;
                        background-color: #35383f;
                    }

                    .chart-rank {
                        width: 100%;
                        height: 25px;
                        min-width: 60%;
                        border-radius: 100px;

                        .score-alone {
                            margin-right: 0px;
                            margin-left: 30px !important;
                        }
                    }

                    .middle {
                        margin: 10px 0;
                        background-color: $primary-color;
                    }

                    .one,
                    .two {
                        width: 1%;
                        position: relative; // transition: .4s ease-in-out;
                        background-color: #9ad877;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        overflow: hidden;

                        img {
                            border-radius: 300px;
                            position: absolute;
                            left: 0;
                            width: 16px;
                            color: white;
                            top: 1px;
                            padding: 4px;
                            background-color: $button-color;
                        }

                        .user {
                            margin-left: 30px;
                            top: 5px;
                            color: white;
                            font-weight: 300;

                            @include phone {
                                font-size: 14px;
                                overflow: hidden;
                            }


                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.8s);
                        }

                        .score {
                            margin-right: 15px;
                            color: white;
                            font-weight: 300;
                            overflow: hidden;

                            @include phone {
                                font-size: 14px;
                                overflow: hidden;
                            }


                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.7s);
                        }
                    }

                    .two {
                        background-color: $primary-color;
                        width: 1%;

                        img {
                            background-color: $primary-color-hover;
                            color: black;
                        }

                        .user {
                            margin-left: 30px;
                            top: 5px;
                            color: black;
                            font-weight: 300;

                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.85s);
                        }

                        .score {
                            margin-right: 15px;
                            color: black;
                            font-weight: 300;
                        }
                    }

                    .three {
                        width: 1%; // -webkit-transition: width 2s ease 0s;
                        position: relative;
                        background-color: #444750;
                        display: flex; // transition: 0.4s ease-in-out;
                        justify-content: space-between;
                        align-items: center;
                        overflow: hidden;

                        img {
                            border-radius: 100px;
                            position: absolute;
                            left: 0;
                            width: 16px;
                            color: white;
                            top: 1px;
                            padding: 4px;
                            background-color: #373941;
                        }

                        .user {
                            margin-left: 30px;
                            top: 5px;
                            color: white;
                            font-weight: 300;

                            @include phone {
                                font-size: 14px;
                                overflow: hidden;
                            }


                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.9s);
                        }

                        .score {
                            margin-right: 15px;
                            color: white;
                            font-weight: 300;

                            @include phone {
                                font-size: 14px;
                                overflow: hidden;
                            }


                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.7s);
                        }
                    }

                    .color-caption-container {
                        display: none;
                        justify-content: center;
                        align-items: center;
                        flex-direction: row;
                        margin-top: 30px;

                        .color-caption {
                            display: flex;
                            justify-content: center;
                            flex-direction: row;
                            align-items: center;
                            margin: 0 20px;

                            &:first-of-type {
                                .color-round {
                                    background-color: #9ad877;
                                }
                            }

                            &:last-of-type {
                                .color-round {
                                    background-color: #444750;
                                }
                            }

                            .color-round {
                                width: 15px;
                                margin-right: 5px;
                                height: 15px;
                                background-color: $primary-color;
                                border-radius: 100px;
                            }

                            .caption {
                                font-family: $first-font;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            .information-container {
                font-size: 14px;
                margin-top: 10px;
                font-weight: 400;
                margin-top: 30px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                @include phone {
                    margin-top: 40px;
                }


                @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.2s);

                .info-icon {
                    width: 8px;
                    margin-right: 10px;
                }
            }

            .tool-container{
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px 0;
                a {
                    text-decoration: none;
                    color: black;
                    .edit-icon{
                        width: 13px;
                        margin-right: 5px;
                    }

                    &:nth-of-type(2){
                        .edit-icon{
                            width: 7px;
                        }
                    }

                    &:hover{
                        cursor: pointer;
                        color: #3a3a3a;
                    }
                }

                div{
                    margin: 0 40px;
                    transition: .2s ease-in-out;
                    .edit-icon{
                        width: 13px;
                        margin-right: 5px;
                        transform: rotate(180deg);
                    }

                    &:hover{
                        cursor: pointer;
                        color: #3a3a3a;
                    }
                }

                .info-btn {
                    display: none;
                }

            }
        }

        .ecotroks-gains-container {
            background: (center / cover) no-repeat url("/images/ecotroks-win-bg-dark.png");
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 20px 0 20px 0;

            .title-container {
                font-size: 30px;
                margin-top: 5px;
                color: white;

                @include tablet {
                    font-size: 30px;
                }


                @include phone {
                    font-size: 24px;
                }
            }

            .amount-ecotroks-won {
                font-size: 40px;
                color: $button-color;
                margin-top: 30px;
                margin-bottom: 20px;
                font-weight: 400;
                min-height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 40px;
                    margin-left: 10px;
                }

                @include tablet {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }


                @include phone {
                    margin-top: 20px;
                    margin-bottom: 10px;
                }
            }

            .ecotroks-text-win {
                display: flex;
                flex-direction: row;
                font-weight: bold;
                justify-content: center;
                text-align: center;
                color: white;
                font-size: 30px;

                .name-user {
                    margin: 0 5px;
                }
            }

            .ecotroks-text-win-top {
                display: none;
                font-size: 24px;
            }

            @include phone {
                .ecotroks-text-win-top {
                    display: flex;
                }

                .title-container {
                    display: none;
                }

                .ecotroks-text-win-bottom {
                    display: none;
                }
            }

            .win-text {
                color: white;
                margin-top: 10px;
                padding: 0 10%;
                font-family: $second-font;
                font-weight: 400;
                font-size: 16px;
                min-height: 40px;

                @include tablet {
                    padding: 0 20%;
                }
            }

            .plurality-ecotroks {
                color: white;
                display: flex;
                flex-direction: row;
                text-align: center;
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 10px;
                font-size: 25px;

                @include tablet {
                    margin-top: 20px;
                    padding-bottom: 20px;
                }


                @include phone {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    font-size: 17px;
                }


                .amount-ecotroks {
                    color: $button-color;
                    margin: 0 5px;
                }

                .ecotroks-logo {
                    width: 26px;
                    height: 26px;
                    margin-left: 5px;
                }
            }

            .btn-container {
                align-items: center;
                align-content: center;
                margin-top: 15px;
            }

            .btn-mes-points {
                background-color: $primary-color;
                padding: 7px 35px;
                width: 100%;
                border-radius: 40px;
                text-decoration: none;
                color: black;
                transition: .2s ease-in-out;

                &:hover {
                    background-color: $primary-color-hover;
                }
            }
        }

        .consumption-container {
            width: 100%;
            padding: 20px;
            font-family: $first-font;

            .title-consumption {
                font-size: 24px;
            }

            .consumption-cards {
                display: flex;
                justify-content: center;
                align-items: center;
                justify-content: space-between;

                @include phone {
                    flex-direction: column;
                }

                .consumption-card {
                    width: 400px;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @include phone {
                        width: calc(100% - 20px);

                        &:first-of-type {
                            padding-bottom: 40px;
                            border-bottom: 1px solid rgb(204, 204, 204);
                        }
                    }

                    .title-card {
                        display: flex;
                        align-items: center;
                        font-size: 24px;
                        margin: 30px 0px;
                        margin-bottom: 10px;
                    }

                    .icon-elec, .icon-gas {
                        transition: background-color 0.2s;
                        height: 30px;
                        width: 30px;
                        -webkit-mask-repeat: no-repeat;
                        mask-repeat: no-repeat;
                        -webkit-mask-position: center;
                        mask-position: center;
                        margin-right: 10px;
                    }

                    .icon-elec {
                        background-color: #ffd400;
                        -webkit-mask-image: url(/images/icons/elec_icon.svg);
                        mask-image: url(/images/icons/elec_icon.svg);
                    }

                    .icon-gas {
                        background-color: #0fa2c0;
                        -webkit-mask-image: url(/images/icons/gas_icon.svg);
                        mask-image: url(/images/icons/gas_icon.svg);
                    }

                    .amount-container {
                        display: flex;
                        flex-direction: row;
                        text-align: bottom;
                        justify-content: center;

                        .amount {
                            font-size: 60px;
                        }
                    }

                    .text-card {
                        font-size: 14px;
                        font-weight: 400;
                        font-family: $second-font;

                        @include tablet {
                            font-size: 18px;
                        }
                    }

                    .btn-container {
                        display: flex;
                        align-items: center;
                        align-content: center;
                        margin-top: 50px;
                    }

                    .btn-card-consumption {
                        background-color: $primary-color;
                        font-size: 16px;
                        font-family: "Open Sans";
                        padding: 9px 35px;
                        width: 100%;
                        border-radius: 40px;
                        text-decoration: none;
                        top: 40px;
                        color: black;
                        transition: .2s ease-in-out;

                        &:hover {
                            background-color: $primary-color-hover;
                        }

                        &.blue-btn {
                            background-color: #0fa2c0;
                            color: #fff;
                        }
                    }

                    .arrow-up {
                        color: $button-color;
                        width: 20px;
                        height: 20px;
                        margin-top: 30px;
                        margin-right: 10px;
                    }

                    .arrow-down {
                        color: $alert-color;
                        width: 20px;
                        height: 20px;
                        margin-top: 30px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .disaggregation-container {
            width: 100%;
            height: 100%;
            position: relative;

            .title-card {
                font-size: 24px;
                position: absolute;
                top: 20px;
                color: white;
                width: 100%;

                .sub-title-card {
                    font-size: 18px;
                }

                sup {
                    font-weight: 400;
                }
            }

            .usage-center {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                margin-top: 85px;

                .usage-container {
                    background-color: white;
                    width: 100px;
                    border-radius: 1000px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;

                    @include tablet {
                        margin-top: 20px;
                    }


                    @include phone {
                        margin-top: 20px;
                    }


                    @include little-phone {
                        margin-top: 20px;
                    }


                    .image-usage {
                        width: 35px;
                        height: 35px;
                        margin-bottom: 5px;
                    }
                }

                .usage-text {
                    font-size: 20px !important;
                    margin-top: 10px;
                    text-shadow: 0 0 25px #000;
                }

                .text-card {
                    font-size: 16px;
                    font-weight: 600;
                    color: white;
                }

                .btn-container {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    margin-top: 20px;
                    z-index: 10;

                    @include tablet {
                        margin-top: 20px;
                    }


                    @include phone {
                        margin-top: 20px;
                    }


                    @include little-phone {
                        margin-top: 20px;
                    }
                }

                .btn-card-consumption {
                    background-color: $primary-color;
                    padding: 9px 35px;
                    font-family: "Open Sans";
                    width: 100%;
                    border-radius: 40px;
                    text-decoration: none;
                    top: 40px;
                    color: black;
                    transition: .2s ease-in-out;

                    &:hover {
                        background-color: $primary-color-hover;
                    }

                    @include tablet {
                        margin-bottom: 30px;
                    }


                    @include phone {
                        margin-bottom: 30px;
                    }


                    @include little-phone {
                        margin-bottom: 30px;
                    }
                }
            }

            .no-consent-usage {
                background-color: white;
                border-radius: 3px;
                padding: 20px;
                max-width: 75%;
                margin: auto;
                margin-top: 65px;
                margin-bottom: 30px;

                @include phone {
                    width: 95%;
                }

                a {
                    display: block;
                    background-color: $primary-color;
                    font-size: 16px;
                    font-family: "Open Sans";
                    padding: 9px 35px;
                    border-radius: 40px;
                    text-decoration: none;
                    color: black;
                    margin-top: 20px;

                    &:hover {
                        background-color: $primary-color-hover;
                    }
                }
            }
        }

        .btn-consent {
            background-color: $primary-color;
            padding: 7px 35px;
            width: 100%;
            border-radius: 40px;
            text-decoration: none;
            color: black;
            transition: .2s ease-in-out;

            &:hover {
                background-color: $primary-color-hover;
            }
        }

        .consent-container-comparison {
            img {
                width: 120px;
                margin-bottom: 20px;
            }

            button {
                background-color: #ffd401;
                padding: 7px 35px;
                width: 100%;
                border-radius: 40px;
                text-decoration: none;
                color: black;
                transition: .2s ease-in-out;
                border: none;
                width: 250px;
                margin-top: 20px;
                cursor: pointer;
            }
        }

        .how-to-ecotroks-container {
            width: 100%;
            margin-top: 30px;
            background-color: $primary-color;
            display: flex;
            //justify-content: center;
            flex-direction: row;
            height: 250px;
            // text-align: left;
            // align-items: center;
            line-height: 35px;
            position: relative;
            margin-bottom: 30px;
            overflow: hidden;

            @include tablet {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            @include limit-screen {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            @include phone {
                margin-top: 0px;
                margin-bottom: 20px;
                flex-direction: column-reverse;
                height: calc(100% - 100px);
                background-color: $primary-color;
            }


            .ecotroks-image {
                width: 100%;
                height: 100%;
                background-position: bottom right !important;
                z-index: 2;
                background-repeat: no-repeat;
                background-image: url("/images/banners/ecotroks_home_banner_bg.jpg");

                @include tablet {
                    width: 100%;
                    background-position: -200px !important;
                }


                @include phone {
                    margin-bottom: 0;
                    margin-left: 0;
                    margin-right: auto;
                    height: 173px;
                    background-position: left;
                    background-size: 230px;
                    background-repeat: no-repeat;
                    background-image: url("/images/banners/mespoints_dashboard_bg.jpg");
                }
            }

            .ecotroks-container {
                width: 70%;
                margin-left: 24px;
                padding: 0 50px 0 0;
                display: flex;
                justify-content: center;
                flex-direction: column;
                // align-items: center;
                z-index: 10;

                @include tablet {
                    padding: 0 25px 0 0;
                }


                @include phone {
                    justify-content: unset;
                    width: 100%;
                    margin: 0px;
                    text-align: center;
                }


                .text-ecotroks {
                    font-size: 25px;
                    text-align: left;

                    @include phone {
                        font-size: 20px;
                        line-height: 20px;
                        margin-top: 20px;
                        padding: 0 40px;
                    }

                    .ecotroks-logo {
                        height: 25px;
                        z-index: 6;

                        @include phone {
                            height: 20px;
                        }
                    }
                }

                .cta-ecotroks {
                    background-color: $secondary-color;
                    font-family: "Open sans";
                    border-radius: 30px;
                    margin-top: 15px;
                    text-align: center;
                    display: flex;
                    padding: 0px 40px;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    text-decoration: none;
                    font-weight: 300;
                    transition: .2s ease-in-out;
                    width: 160px;
                    font-size: 20px;

                    &:hover {
                        cursor: pointer;
                        background-color: #5d6069;
                    }

                    @include phone {
                        font-size: 14px;
                        background-color: #35383f;
                        color: white;
                        position: absolute;
                        bottom: 40%;
                        left: 5%;

                        &:hover {
                            background-color: #44464e;
                        }
                    }
                }
            }
        }

        .no-data-container {
            background: #20a1c0;
            width: 100%;
            max-width: calc(100% - 40px);
            padding: 12px 0px;
            font-family: "Open Sans", sans-serif;
            color: white;
            font-size: 16px;

            i {
                margin-right: 10px;
                font-size: 1.4em;
            }
        }

        .moving-out-container {
            width: 100%;
            margin-top: 50px;
            background-color: $primary-color;
            display: flex;
            justify-content: space-evenly;
            flex-direction: row;
            height: 300px;
            text-align: left;
            align-items: center;
            line-height: 35px;
            position: relative;

            .left-container {
                margin-left: 100px;

                .title-moving-out {
                    font-size: 35px;
                    font-weight: 300;
                }

                hr {
                    width: 80px;
                    border: none;
                    height: 3px;
                    margin: 10px 0;
                    margin-bottom: 20px;
                    background-color: black;
                }

                .text-moving-out {
                    margin: 0px;
                    line-height: 18px;
                    font-weight: 300;
                }

                .btn-container {
                    background-color: $secondary-color;
                    border-radius: 30px;
                    margin-top: 15px;
                    width: 220px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 30px;
                    color: white;
                    text-decoration: none;
                    font-weight: 300;
                    transition: .2s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        background-color: #444750;
                    }

                    img {
                        width: 10px;
                        margin-left: 10px;
                    }
                }

                .title-second-moving-out {
                    font-size: 35px;
                    font-weight: 300;
                    display: flex;
                    flex-direction: row;

                    .bold-text {
                        font-weight: 400;
                        margin-right: 10px;
                    }
                }
            }

            .right-container {
                img {
                    height: 300px;
                    margin-top: 12px;
                }
            }
        }

        .conso-text {
            margin-top: 30px;
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            font-style: italic;
            padding: 0 100px;
            margin-bottom: 30px;
            line-height: 24px;

            @include phone {
                margin-top: 0px;
                padding: 0;
            }
        }

        .no-accept-enedis-container, .no-accept-grdf-container {
            width: 100%;

            display: none;
            align-items: center;
            justify-content: center;

            background: url(/images/consumption/warning.svg) #F7F7F7 no-repeat;
            background-size: 30%;
            background-position: -5%;

            @include tablet {
                max-width: calc(100% - 40px);
                background: #F7F7F7;
            }

            @include limit-screen {
                max-width: calc(100% - 40px);
            }

            @include phone {
                max-width: calc(100% - 40px);
                background: #F7F7F7;
                flex-direction: column;
                padding-top: 50px;
            }

            .btn-profile {
                margin: 10% 0;
                background-color: #ffd401;
                border: none;
                padding: 7px 35px;
                width: 200px;
                border-radius: 40px;
                text-decoration: none;
                color: black;
                transition: .2s ease-in-out;
                font-size: 14px;
                cursor: pointer;
                z-index: 1;
            }

            .wrapper {
                @include tablet {
                    width: 100%;
                }

                width: 80%;
                display: flex;
                align-items: center;

                > .message-container {
                    padding: 0 25%;
                    font-size: 20px;
                    z-index: 1;

                    @include limit-screen {
                        font-size: 18px;
                        padding: 0 15%;
                    }

                    @include tablet {
                        font-size: 16px;
                        padding: 0 10%;
                    }

                    @include phone {
                        padding: 0 5%;
                    }
                }

            }
            > img {
                width: 100%;
                max-width: 450px;
                height: auto;

                @include phone {
                    width: initial;
                }

                @include little-phone {
                    width: 100%;
                }
            }
        }
    }
}
