/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.ecotroks-rules {
    font-family: $second-font;
    font-weight: 300;
    font-size: 14px;

    h2 {
        margin-top: 10px;
        font-size: 30px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 20px;
        @include phone{
            padding-top: 20px;
            margin-top: 0;
        }
    }

    article{
        @include phone {
            padding: 20px;
        }
    }

    h3 {
        font-size: 18px;
        font-weight: bold;
        margin: 10px 0;
    }

    p {
        font-size: 14px;
        text-align: justify;
        text-justify: inter-word;
    }

    ol {
        list-style-type: decimal;
        margin-left: 20px;

        li > p {
            font-weight: bold;
        }
    }

    .sub {
        list-style-type: circle;
    }

    li {
        margin: 10px 0;
    }

    ul {
        list-style-type: square;
        margin-left: 20px;
    }
}