/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.grd_demands_body {
    font-family: $first-font;

    .container {
        margin: auto;
        min-height: calc(100vh - 170px);
        width: 65%;

        @include tablet {
            width: 80%;
        }

        @include phone {
            width: 90%;
            min-height: calc(100vh - 300px);
        }

        h2 {
            font-size: 28px;
            text-align: center;
            margin-top: 30px;
            margin-bottom: 15px;
        }

        .info {
            margin-bottom: 30px;
        }

        .error_notice {
            text-align: center;
            color: red;
            margin-bottom: 15px;
        }

        .success_notice {
            text-align: center;
            color: green;
            margin-bottom: 15px;
        }

        .contract_selector_container {
            text-align: center;
            margin-bottom: 30px;

            select {
                padding-left: 5px;
                width: 220px;
                height: 30px;
                border: none;
                border-bottom: solid 1px black;
                cursor: pointer;
            }
        }

        h3 {
            font-size: 22px;
            margin-bottom: 15px;
        }

        ul {
            margin-bottom: 30px;

            li {
                position: relative;
                padding: 15px 0;
                border-bottom: solid 1px black;
                cursor: pointer;

                img {
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    right: 0px;
                }
            }
        }
    }

    .footer {
        margin-top: 60px;
    }
}