.header-menu {
    background-color: #f7f7f7;
    height: 60px;
    display: flex;
    justify-content: center;
    cursor: inherit;
    .alert-popup {
        display: none;
    }
    @media screen and (min-width: 850px) {
        .container-desktop {
            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);
            padding: 0 10px;
            max-width: 1360px;
            width: 100%;
            height: 100%;
            background-color: transparent;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            .left-container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .logo-eni {
                    height: 50px;
                    padding: 10px 5px 10px 0px !important;
                }
                .link {
                    text-decoration: inherit;
                    font-family: $second-font;
                    font-size: 16px;
                    padding: 20px 25px;
                    color: black;
                    font-weight: 400;
                    text-align: center;
                    position: relative;
                    cursor: pointer;
                }
                .five~div.alert-popup {
                    background-color: red;
                    width: 5px;
                    height: 5px;
                    border-radius: 50px;
                    position: absolute;
                    color: white;
                }

            }
            .hr-container{
                // width: 100%;
                position: absolute;
            }
            hr#hr-style {
                height: .35rem;
                // width: 5rem;
                background: $primary-color;
                border: none;
                transition: .2s ease-in-out;
                position: absolute;
                top: -0.5rem;
                z-index: 6;
                width: 0px;
            }
            .right-container {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                a.account-handling-link{
                  text-decoration: none;
                  .btn{
                    background-color: #ffd401;
                    height: 38px;
                    width: 161px;
                    display: flex;
                    border: none;
                    padding-left: 9px;
                    padding-right: 9px;
                    cursor: pointer;

                  }
                  .text_handling_contract{
                    margin: auto;
                    font-family: "Eni", sans-serif;
                    font-size: 15px;
                  }
                  img {
                    margin: auto;
                  }
                  &:hover #hr-style {
                    margin-right: 300px;
                  }
                }
                a.ecotroks-container {
                    font-family: $first-font;
                    font-size: 16px;
                    color: black;
                    flex-direction: row;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    cursor: pointer;
                    padding: 5px 15px;
                    z-index: 4;
                    width: 100%;
                    // margin-right: 25px;
                    .ecotroks-icon {
                        width: 50px;
                        height: 50px;
                    }
                    .eco-subcontainer {
                        margin-left: 10px;
                        .ecotroks-title {
                            color: black;
                            font-size: 18px;
                        }
                        .chevron-amount {
                            display: flex;
                            flex-direction: row;
                            justify-content: right;
                            .ecotroks-amount {
                                color: $button-color;
                                font-family: $second-font;
                                font-size: 18px;
                                text-align: right;
                                margin-left: auto;
                                margin-right: 0;
                                margin-top: 5px;
                            }
                            .chevron-down {
                                width: 10px;
                                height: 10px;
                                margin-top: 10px;
                                transition: .2s ease-out;
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
                a.account-link {
                    padding: 0 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .profile-image {
                        width: 40px;
                        height: 40px;
                        border-radius: 80px;
                        margin-top: 3px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    &:hover #hr-style {
                        margin-left: 300px;
                    }
                }
                .ecotroks-details {
                    width: 200px;
                    height: 50px;
                    // margin-left: -60px;
                    transition: .2s ease-out;
                    z-index: 2;
                    position: absolute;
                    background-color: #f7f7f7;
                    padding: 5px;
                    align-self: normal;
                    canvas {
                        margin-top: 50px;
                    }
                }
            }
        }
        .container-mobile {
            display: none;
        }
    }
    @media screen and (max-width: 915px) {
      .container-desktop
        .right-container {
          a.account-handling-link{
            text-decoration: none;

            .btn{
              height: 36px;
              width: 117px;
              display: flex;
              border: none;
              padding-left: 5px;
              padding-right: 5px;
              cursor: pointer;
              background-color: #ffd401;
            }

            .text_handling_contract{
              margin: auto;
              font-family: "Eni", sans-serif;
              font-size: 14px;
            }
            img {
              margin: auto;
              height: 12px;
              display: none;
            }

          }
        }
    }
    @media screen and (max-width: 850px) {
        .container-mobile {
            display: flex;
            flex-direction: row;
            justify-items: center;
            align-items: center;
            width: 100%;
            .left-container {
                background-color: #f7f7f7;
                width: 50%;
                height: 100%;
                z-index: 16;
                img {
                    margin-top: 5px;
                    height: 50px;
                    margin-left: 10px;
                }
            }
            .right-container {
                background-color: #f7f7f7;
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 16;
                .homemade-burgermenu {
                    margin-left: auto;
                    margin-right: 10px;
                }
                .active-cross-up{
                    animation: 1s crossBounceUp;
                    transform: rotate(45deg);
                    margin-bottom: -10px
                }
                .active-burger-up{
                    animation: 1s burgerBounceUp;
                    transform: rotate(0);
                    margin-top: 0px !important;
                    margin-block-start: 0px;

                }
                .active-burger-down{
                    animation: 1s burgerBounceDown;
                    transform: rotate(0);
                    margin-bottom: 0px !important;

                }
                .active-cross-down{
                    animation: 1s crossBounceDown;
                    transform: rotate(-45deg);
                    margin-top: -10px
                }
                .hide-hr{
                    animation: 1s middleHrDisappear;
                    background-color: transparent;
                }
                .show-hr{
                    animation: 1s middleHrShow;
                    // background-color: black !important;
                }
            }
            .menu-content-container {
                position: fixed;
                top: 0px;
                left: 0;
                background-color: $primary-color;
                min-width: 100%;
                height: 0px;
                z-index: 14;
                overflow: hidden;
                transition: .2s ease-in-out;
                .ecotroks-link {
                    @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);
                    background-color: #f7f7f7;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    padding: 5% 0;
                    position: absolute;
                    top: 5%;
                    width: 100%;
                    text-decoration: none;
                    color: black;
                    font-family: $first-font;
                    img {
                        width: 80px;
                        height: 80px;
                    }
                    @include little-phone{
                        top: 6%;
                    }
                    .ecotroks-container {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        margin-left: 30px;
                        .ecotroks-title {
                            @include phone {
                                font-size: 1.5rem;
                            }
                            font-size: 2.5rem;
                        }
                        .ecotroks-amount {
                            @include phone {
                                font-size: 1.5rem;
                            }
                            font-size: 2.5rem;
                            color: $button-color;
                            align-self: flex-end;
                            font-weight: 600;
                        }
                    }
                }
                .title-container {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: $first-font;
                    a.account-handling-link{
                      text-decoration: none;
                      .btn{
                        background-color: #f7f7f7;
                        @include little-phone {
                          width: 215px;
                        }
                        height: 55px;
                        width: 245px;
                        display: flex;
                        border: none;
                        padding-left: 14px;
                        padding-right: 9px;
                        cursor: pointer;
                        margin-top: 65%;
                      }
                      .text_handling_contract{
                        margin: auto;
                        font-family: "Eni", sans-serif;
                        font-size: 24px;
                        padding-top: 3px;
                        padding-left: 8px;
                        @include little-phone {
                          font-size: 21px;
                        }
                      }
                      img {
                        margin: auto;
                        height: 30px;
                        @include little-phone {
                          height: 27px;
                        }

                      }

                    }
                    a {
                        text-decoration: none;
                        color: black;
                        font-size: 3rem;
                        margin: 1rem 0;
                        @include little-phone{
                            &:first-of-type {
                                margin-top: 70px;
                            }
                            font-size: 2.5rem;
                        }
                    }
                }
            }
        }
        .container-desktop {
            display: none;
        }
    }
    .alert-popup {
        background-color: rgb(214, 0, 54);
        width: 12px;
        height: 12px;
        text-align: center;
        border-radius: 50px;
        position: absolute;
        color: white;
        font-size: 10px;
        margin-left: 55px;
        margin-top: -23px;
        animation: alertBounce 3s infinite;
    }
    @keyframes alertBounce {
        0% {
            transform: translateY(0);
        }
        20% {
            transform: translateY(-5px);
        }
        35% {
            transform: translateY(1px);
        }
        50% {
            transform: translateY(-2px);
        }
        70% {
            transform: translateY(0.5px);
        }
        100% {
            transform: translateY(0);
        }
    }
}

.hide-for-demo {
    color: transparent !important;
    .alert-popup {
        display: none;
    }
}

.hide-div {
    position: absolute;
    top: 0;
    height: 80px;
    width: 20%;
    left: 42%;
}
