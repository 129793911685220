/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */
.slider-ecotroks {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    width: 100%;

    .tiny-slider-ecotroks {
        font-family: $first-font;
        font-weight: 300;
        height: 370px;
        max-height: 370px;

        > * {
            background-color: $primary-color;
            z-index: 0;
            height: inherit;
        }

        // SLIDES

        > .first-slide {
            overflow: hidden;
        }

        > .slide-b {
            display: none;
        }

        > .slide-c {
            display: none;
        }

        > .slide-e {
          display: none;
      }

        .content {
            margin: auto;
            max-width: 1360px;
            height: inherit;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // overflow: hidden;
            flex-direction: row;
            transition: .2s ease-in-out;
        }

        .text {
            z-index: 10;
            position: absolute;
            margin-left: 60px;
            > h1 {
                color: black;
                font-size: 44px;
                font-weight: $light-weight;
                &:before {
                    content: '';
                    position: absolute;
                    background-color: black;
                    width: 80px;
                    height: 4px;
                    margin-top: 110px;
                }
            }

            > p {
                color: black;
                margin-top: 7px;
                margin-bottom: 7px;
                font-size: 20px;
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                &:first-of-type{
                    margin-top: 50px;
                }

                i{
                    margin-right: 10px;
                    margin-top: 0px;
                }
            }

            .bold-title {
                font-weight: $bold-weight;


            }

            > a {
                @extend %primary-button;
                text-decoration: none;
                font-size: 18px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 50%;

                img {
                    margin-left: 7px;
                    width: 15px;
                    height: 15px;
                }
                &:hover{
                    background-color: $secondary-color-hover;
                    cursor: pointer;
                }
            }
        }

        .ecotroks-banner{
            position: relative;
            width: 100%;
            height: 100%;
            margin-left: 320px;
            z-index: 4;
            img{
                position: absolute;
                top: 0;
                left: 70px;
            }
        }
    }

    // SLIDER PART

    .tns-controls, .tns-nav {
        z-index: 1;
        position: absolute;
    }

    .tns-nav {
        text-align: center;
        width: 100%;
        bottom: 30px;

        > button {
            padding: 0;
            background-color: #fff;
            height: 12px;
            width: 12px;
            border-radius: 100px;
            border: none;
            margin-right: 15px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

            &.tns-nav-active {
                background-color: $primary-color;
            }
        }
    }

    .slider-arrows-ecotroks {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 370px;
        width: 100%;
        max-width: 1360px;
        align-self: center;

        > * {
            display: inline-block;
            background-image: url(/images/icons/arrow_carousel.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            cursor: pointer;
            width: 18px;
            height: 29px;
            transition: .2s;
            z-index: 1;

            &:hover {
                opacity: .7;
                transition: .2s;
            }

            &:before {
                display: none;
            }
        }

        .arrow-prev {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            margin-left: 5px;
            transition: .2s ease-in-out;
            @include limit-screen{
                margin-left: 20px;
            }
        }
        .arrow-next{
            margin-right: 5px;
            @include limit-screen{
                margin-right: 20px;
            }
            z-index: 12;
        }
    }

    @include little-phone {
        .first-slide{
            .content{
                .text{
                    > h1{
                        font-size: 22px;
                    }
                }
                .ecotroks-banner{
                    display: none;
                }
            }

        }
    }

    @include phone {
        .tns-nav {
            > button {
                display: none;
            }
        }

        .tiny-slider-ecotroks {
            height: 200px;
            max-height: 200px;
        }

        .first-slide {
            background: $primary-color;
            .content{
                .ecotroks-banner{
                    margin-left: 12%;
                    img {
                        height: 100%;
                    }
                }
            }
            .text {
                margin-left: 20px;
                > h1 {
                    color: black;
                    font-size: 25px;
                    &:before{
                        display: none;
                    }
                }

                > p {
                    color: black;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    font-size: 15px;
                    &:first-of-type{
                        margin-top: 10px;
                    }
                }

                > a {
                    font-size: 12px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 40%;
                    padding-right: 10px;
                    img {
                        width: 10px !important;
                        height: 10px !important;
                    }
                }
            }

            .bold-title {
                &:before {
                    display: none;
                }
            }
        }

        .slider-arrows-ecotroks {
            display: none;

            > * {
                width: 10px;
                height: 20px;
            }

        }
    }

    @include tablet {
        .tns-nav {
            > button {
                display: none;
            }
        }

        .tiny-slider-ecotroks {
            height: 300px;
            max-height: 300px;
        }

        .first-slide {
            .content{
                .ecotroks-banner{
                    margin-left: 10%;
                }
            }

            .text {
                > h1 {
                    color: black;
                    font-size: 40px;
                    font-weight: $light-weight;
                }

                > p {
                    color: black;
                    margin-top: 20px;
                    margin-bottom: 25px;
                    font-size: 20px;
                }

                > a {
                    font-size: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                }
            }

            .bold-title {
                &:before {
                    display: none;
                }
            }
        }

        .slider-arrows-ecotroks {
            height: 300px;

            > * {
                width: 20px;
                height: 20px;
            }
            .arrow-prev{
                margin-left: 20px;
            }
            .arrow-next{
                margin-right: 20px;
            }
        }
    }
}
