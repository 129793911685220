/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.body-alert {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;

    a.prim-btn {
        display: block;
        background-color: $primary-color;
        font-size: 16px;
        font-family: "Open Sans";
        padding: 9px 35px;
        border-radius: 40px;
        text-decoration: none;
        color: black;
        margin: 20px;

        &:hover {
            background-color: $primary-color-hover;
        }
    }

    .container-no-consent-alerts {
        flex: 1;
        font-family: $first-font;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 80px;
        text-align: center;

        @include phone {
            padding-top: 20px;
        }

        p {
            font-size: 20px;
            max-width: 60%;

            @include phone {
                max-width: 90%;
            }
        }



        img {
            width: 120px;
            margin-bottom: 40px;

            @include phone {
                width: 80px;
                margin-bottom: 20px;
            }
        }
    }

    .container-alert {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title-alert {
            font-family: $first-font;
            font-size: 44px;
            color: black;
            margin-top: 20px;
            font-weight: 100;
            margin-bottom: 20px;
            align-self: center;
        }

        .tabs-container {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $second-font;
            font-size: 18px;

            @include phone {
                width: calc(100% - 20px);
            }

            .tabs {
                background-color: #f7f7f7;
                padding: 10px 0;
                width: 300px;
                margin: 20px 30px;
                margin-bottom: 30px;
                transition: .2s ease-in-out;
                display: flex;
                justify-content: center;

                @include phone {
                    padding: 10px 20px;
                    margin: 20px 10px;
                    font-size: 12px;
                    flex: 1;
                }

                &:hover {
                    background-color: #f3f3f3;
                    cursor: pointer;
                }
            }

            .active {
                background-color: $primary-color;

                &:hover {
                    background-color: $primary-color;
                }
            }
        }

        .first-container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            @include limit-screen {
                // padding: 0 20px;
            }

            @include tablet {
                // padding: 0 20px;
            }

            .main-alert {
                max-width: 1380px;
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .error-adding {
                    display: none;
                    font-family: $second-font;
                    font-size: 18px;
                    color: $alert-color;
                    margin-bottom: 10px;
                    align-self: center;
                }

                .card-container {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    width: 100%;
                    align-items: center;

                    @include limit-screen {
                        width: calc(100% - 20px);
                        align-self: center;
                    }
                    
                    @include tablet {
                        width: calc(100% - 20px);
                        align-self: center;
                    }

                    @include phone{
                        justify-content: center;
                    }

                    .alert-card {
                        background-color: #f7f7f7;
                        height: auto;
                        width: calc((100% / 3) - 20px);
                        height: 200px;
                        margin: 10px 10px;
                        position: relative;
                        overflow: hidden;

                        @include limit-screen {
                            width: calc((100% / 3) - 20px);
                        }

                        @include tablet {
                            width: calc(50% - 20px);
                        }

                        @include phone {
                            width: calc(100% - 40px);
                        }

                        .delete-button,
                        .delete-button-email {
                            background-color: $alert-color;
                            height: 30px;
                            width: 0;
                            border-radius: 100px;
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: white;
                            overflow: hidden;
                            font-family: $second-font;
                            transition: .2s ease-in-out;
                            z-index: 4;

                            &:hover {
                                cursor: pointer;
                                background-color: #c74d4b;
                            }
                        }

                        .delete-button-active,
                        .delete-button-active-email {
                            width: 150px;
                            // padding: 0 10px 0 20px;
                        }

                        .delete-icon,
                        .edit-icon,
                        .delete-icon-email {
                            width: 20px;
                            height: 20px;
                            border-radius: 100px;
                            -webkit-mask-image: url('/images/icons/trash_ico.svg');
                            mask-image: url('/images/icons/trash_ico.svg');
                            transition: .2s ease-in-out;
                            background-color: #aaaaaa;
                            position: absolute;
                            top: 15px;
                            right: 20px;
                            z-index: 2;

                            &:hover {
                                cursor: pointer;
                                animation: wiggle 2s infinite;
                                background: $alert-color;
                            }
                        }

                        .delete-icon,
                        .delete-icon-email {
                            margin-left: 20px;
                        }

                        .edit-icon {
                            mask-image: url('/images/icons/edit_icon.svg');
                            -webkit-mask-image: url('/images/icons/edit_icon.svg');
                            left: 20px;
                            width: 15px;
                            height: 15px;
                            border-radius: 0px;

                            &:hover {
                                background-color: black;
                                cursor: pointer;
                                animation: wiggle 2s infinite;
                            }
                        }

                        .delete-icon-button,
                        .delete-icon-button-email {
                            background-color: white;
                            width: 20px;
                            height: 20px;
                            margin-left: 10px;
                            border-radius: 100px;
                            -webkit-mask-image: url('/images/icons/trash_ico.svg');
                            mask-image: url('/images/icons/trash_ico.svg');
                            transition: .2s ease-in-out;
                        }

                        .alert-icon {
                            width: auto;
                            height: 70px;
                            margin-top: 40px;
                        }

                        .over-to {
                            font-family: $first-font;
                            font-size: 14px;
                            font-weight: 300;
                            margin-top: 15px;
                        }

                        .card-title {
                            padding-bottom: 20px;
                            font-family: $first-font;
                            font-size: 30px;
                            font-weight: 300;
                            margin-top: 5px;
                        }

                        .no-over-to {
                            margin-top: 35px;
                        }

                        .add-icon {
                            background-color: $primary-color;
                            padding: 20px;
                            border-radius: 100px;
                            height: 40px;

                            &:hover {
                                cursor: pointer !important;
                            }
                        }

                        .add-title {
                            font-size: 20px;
                            font-weight: 400;
                            margin-top: 25px;
                        }
                    }

                    .add-alert {
                        transition: .2s ease-in-out;

                        &:hover {
                            cursor: pointer !important;
                            background-color: #f3f3f3;
                        }
                    }
                }
            }
        }

        .second-container {
            background-color: #f7f7f7;
            margin-top: 32px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            flex: 1;
            align-items: center;
            padding-bottom: 24px;

            @include limit-screen {
                // padding: 0 20px;
            }

            @include tablet {
                // padding: 0 20px;
            }


            .main-recipient {
                max-width: 1380px;
                width: 100%;
                height: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .title-recipient {
                    font-family: $first-font;
                    font-size: 44px;
                    color: black;
                    margin-top: 20px;
                    font-weight: 100;
                    margin-bottom: 20px;
                }

                .card-container-bottom {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    width: 100%;
                    padding-bottom: 50px;

                    @include limit-screen {
                        width: calc(100% - 20px);
                        align-self: center;
                    }
                    
                    @include tablet {
                        width: calc(100% - 20px);
                        align-self: center;
                    }

                    @include phone{
                        justify-content: center;
                    }

                    &:after {
                        content: "";
                        width: calc(62% + 40px);
                    }

                    .recipient-card {
                        background-color: white;
                        height: auto;
                        width: calc((100% / 3) - 20px);
                        height: 200px;
                        margin: 10px 10px;
                        position: relative;
                        overflow: hidden;

                        @include limit-screen {
                            width: calc((100% / 3) - 20px);
                        }

                        @include tablet {
                            width: calc(50% - 20px);
                        }

                        @include phone {
                            width: calc(100% - 40px);
                        }

                        .delete-button-email {
                            background-color: $alert-color;
                            height: 30px;
                            width: 0;
                            border-radius: 100px;
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: white;
                            overflow: hidden;
                            font-family: $second-font;
                            transition: .2s ease-in-out;
                            z-index: 4;

                            &:hover {
                                cursor: pointer;
                                background-color: #c74d4b;
                            }
                        }

                        .delete-icon-email {
                            margin-left: 20px;
                        }

                        .delete-button-active-email {
                            width: 150px;
                        }

                        .delete-icon-button-email {
                            background-color: white;
                            width: 20px;
                            height: 20px;
                            margin-left: 10px;
                            border-radius: 100px;
                            -webkit-mask-image: url('/images/icons/trash_ico.svg');
                            mask-image: url('/images/icons/trash_ico.svg');
                            transition: .2s ease-in-out;
                        }

                        .delete-icon-email {
                            width: 20px;
                            height: 20px;
                            border-radius: 100px;
                            -webkit-mask-image: url('/images/icons/trash_ico.svg');
                            mask-image: url('/images/icons/trash_ico.svg');
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            transition: .2s ease-in-out;
                            background-color: #aaaaaa;

                            &:hover {
                                cursor: pointer;
                                animation: wiggle 2s infinite;
                                background: $alert-color;
                            }
                        }

                        .recipient-icon {
                            width: auto;
                            height: 70px;
                            margin-top: 40px;
                        }

                        .over-to {
                            font-family: $first-font;
                            font-size: 14px;
                            font-weight: 300;
                            margin-top: 15px;
                        }

                        .card-title {
                            padding-bottom: 20px;
                            font-family: $first-font;
                            font-size: 20px;
                            font-weight: 300;
                            margin-top: 35px;
                            padding: 0px 15px;
                        }

                        .no-over-to {
                            margin-top: 35px;
                        }

                        .add-icon {
                            background-color: $primary-color;
                            padding: 20px;
                            border-radius: 100px;
                            height: 40px;

                            &:hover {
                                cursor: pointer !important;
                            }
                        }

                        .add-title {
                            font-size: 20px;
                            font-weight: 400;
                            margin-top: 25px;
                        }
                    }

                    .add-recipient {
                        transition: .2s ease-in-out;

                        &:hover {
                            background-color: #fcfcfc;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .historic-container {
            display: none;
            flex-direction: column;
            font-family: $second-font;
            font-size: 16px;
            justify-content: center;
            align-items: center;

            @include phone {
                width: calc(100% - 20px);

            }

            .subtitle-historic {
                align-self: center;
                margin-bottom: 50px;
            }

            .alert-hist-container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                max-width: 1360px;
                position: relative;
                
                @include phone {
                    width: 100%;
                }

                .date-alert-title {
                    align-self: flex-start;
                    margin-bottom: 30px;
                    font-weight: 500;
                    font-size: 18px;

                    @include phone {
                        margin-left: 20px;
                    }
                }

                .no-alert-title {
                    background-color: #f7f7f7;
                    display: flex;
                    flex-direction: row;
                    padding: 10px;
                    width: 400px;
                    border-radius: 5px;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 30px;
                    font-size: 18px;
                }

                .alert-card {
                    background-color: #f7f7f7;
                    display: flex;
                    flex-direction: row;
                    padding: 10px;
                    width: 500px;
                    height: 100px;
                    border-radius: 5px;
                    align-items: center;
                    justify-content: space-evenly;
                    margin-bottom: 30px;
                    position: relative;
                    overflow: hidden;

                    @include phone {
                        width: calc(100% - 40px);
                    }

                    .new-alert-round{
                        background-color: $alert-color;
                        width: 12px;
                        height: 12px;
                        border-radius: 100px;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        display: none;
                        animation: flash 2s infinite;
                    }

                    .alert-icon {
                        height: 40px;
                    }

                    .title-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-left: 50px;

                        @include phone{
                            text-align: center;
                        }

                        .alert-title {
                            font-size: 16px;
                            margin-bottom: 20px;
                            @include phone{
                                margin-bottom: 10px;
                            }
                        }

                        .spec-alert {
                            font-size: 18px;
                            margin-top: 10px;
                            display: flex;
                            @include phone{
                                font-size: 14px;
                            }

                            .conso-highlight{
                                color: $alert-color;
                                margin-right: 5px;
                            }
                            
                            .power {
                                margin-left: 5px;
                            }
                        }

                        .date-alert {
                            margin-top: 10px;
                            opacity: .4;
                            align-self: flex-end;
                        }
                    }
                }

                .new-alert-historic {
                    // background-color: blue;
                    .new-alert-round{
                        display: flex;
                    }
                    animation: neon 3s infinite;
                }
            }
        }
    }

    .shadow {
        @include fadeIn($duration: 0.2s, $function: ease);
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(white, .4);
        width: 100%;
        height: 100%;
        display: none;
        z-index: 6;
    }

    .add-modal,
    .add-modal-email,
    .confirm-remove-modal,
    .modify-modal {
        z-index: 20;
        max-height: 95%;
        @include fadeIn($duration: 0.2s, $function: ease);
        background-color: white;
        position: fixed;
        // margin-left: 30%;
        // width: 40%;
        display: none;
        top: 5%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        overflow: scroll;
        box-shadow: 0px 0px 36px -10px rgba(0, 0, 0, 0.25);

        // @include limit-screen {
        //     width: 64%;
        //     margin-left: 18%;
        // }

        // @include tablet {
        //     width: 80%;
        //     margin-left: 7%;
        // }

        // @include phone {
        //     width: 100%;
        //     height: 100%;
        //     margin-left: 0%;
        //     top: 0;
        //     padding: 0;
        // }

        .cross-close-modal{
            width: 20px;
            transform: rotate(45deg);
            margin-right: 0px;
            margin-top: 20px;
            align-self: flex-end;
            display: none;
        }

        @include tablet {
            .cross-close-modal{
                display: flex;
            }
        }

        @include phone {
            width: 100%;
            height: 100%;
            top: 0px;
            overflow: scroll;

            .cross-close-modal{
                display: flex;
                right: 50px;
                top: 10%;
                position: absolute;
                background-color: #f7f7f7;
                border-radius: 100px;
                padding: 10px;
            }
            .title{
                text-align: center;
            }
        }

        .title-add-modal{
            text-align: center;
        }

        .modal-container,
        .modal-container-modify {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include phone {
                padding: 0px 20px;
                //background-color: red;
                width: 100%;
            }


            .alert-type-container {
                display: flex;
                flex-direction: row;
                overflow: hidden;

                @include phone {
                    flex-direction: row;
                    justify-content: center;
                    width: 100%;
                }

                .alert-type {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 35px;
                    width: 100px;
                    border-radius: 5px;
                    background-color: white;
                    flex-direction: column;
                    margin: 0 10px;
                    margin-bottom: 20px;
                    transition: .2s ease-in-out;

                    &:hover {
                        background-color: #f7f7f7;
                        cursor: pointer;
                    }

                    .alert-icon {
                        height: 50px;
                        width: auto;
                    }

                    .type-title {
                        margin-top: 20px;
                        font-family: $second-font;
                        font-size: 18px;
                    }

                    @include phone{
                        width: calc(100% / 3);
                        padding: 10px;

                        .alert-icon {
                            height: 8vw;
                        }
                        .type-title{
                            font-size: 4vw;
                            margin-top: 10px;
                        }
                    }
                }

                .not-selectable {
                    background-color: #d1d1d11a !important;
                    color: rgba(black, .2);

                    &:hover {
                        cursor: initial;
                    }

                    .alert-icon-adding {
                        opacity: .4;
                    }
                }

                .type-selected {
                    background-color: #eeeeee !important;
                }
            }

            .config-type-container {
                display: flex;
                flex-direction: column;
                font-family: $second-font;
                font-size: 18px;
                margin-top: 20px;
                align-items: center;
                justify-content: center;

                .impossible-add{
                    color: $alert-color;
                    font-family: $second-font;
                    font-size: 14px;
                    margin-bottom: 40px;
                    width: 600px;
                    text-align: center;

                    @include phone {
                        width: 100%;
                    }
                }

                .inputs-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;

                    .conso-input {
                        width: 150px;
                        background-color: #f7f7f7;
                        border: none;
                        padding: 8px 12px;
                        font-size: 18px;
                        border-radius: 100px;
                        font-family: $second-font;
                    }

                    .error-input {
                        border: 1px solid $alert-color;
                    }

                    .energy-icon {
                        margin: 0 10px;
                    }

                    select {
                        padding: 8px 12px;
                        border: none;
                        font-size: 18px;
                        box-shadow: none;
                        background: transparent;
                        background-image: none;
                        -webkit-appearance: none;
                        width: 180px;
                        background: #f7f7f7 url("../../../images/icons/chevron_down_solid.svg") no-repeat 90% 50%;
                        background-size: 10px;
                        transition: .2s ease-in-out;
                        margin: 10px 5px;
                        border-radius: 100px;

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            cursor: pointer;
                            background-color: #eeeeee;
                        }
                    }
                }

                .slide-container {
                    width: 60%;
                    margin-top: 10px;

                    .slider {
                        -webkit-appearance: none;
                        appearance: none;
                        width: 100%;
                        height: 5px;
                        background: #eeeeee;
                        outline: none;
                        border: none;
                        opacity: 0.7;
                        -webkit-transition: .2s;
                        transition: opacity .2s;
                    }

                    .slider:hover {
                        opacity: 1;
                        /* Fully shown on mouse-over */
                    }

                    .slider::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        width: 20px;
                        height: 20px;
                        border-radius: 100px;
                        background: $primary-color;
                        cursor: pointer;
                        outline: none;
                        border: none;
                    }

                    .slider::-moz-range-thumb {
                        width: 20px;
                        height: 20px;
                        border-radius: 100px;
                        background: $primary-color;
                        cursor: pointer;
                        outline: none;
                        border: none;
                    }
                }

                .subtitle-add {
                    font-family: $second-font;
                    font-size: 14px;
                    margin-bottom: 20px;
                    width: 600px;
                    text-align: center;

                    @include phone {
                        width: 100%;
                    }
                }
            }

            .button-container,
            .button-container-email {
                display: none;
                justify-content: flex-end;
                align-items: flex-end;
                width: 100%;
                margin-top: 30px;
                margin-bottom: 20px;

                @include phone {
                    padding-right: 20px;
                }

                .cancel-button,
                .cancel-button-email,
                .cancel-button-remove,
                .cancel-button-modify {
                    padding: 8px 15px;
                    background-color: $black;
                    border-radius: 100px;
                    color: white;
                    font-family: $second-font;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 10px;
                    transition: .2s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        background: $secondary-color-hover;
                    }
                }

                .confirm-button,
                .confirm-button-email,
                .confirm-button-remove,
                .confirm-button-modify {
                    padding: 8px 15px;
                    background-color: $primary-color;
                    border-radius: 100px;
                    color: black;
                    font-family: $second-font;
                    font-size: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: .2s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        background: $primary-color-hover;
                    }
                }
            }

            .button-container-email {
                display: flex;
            }

            .email-input {
                width: 450px;
                background-color: #f7f7f7;
                border: none;
                padding: 8px 12px;
                font-size: 18px;
                border-radius: 100px;
                font-family: $second-font;

                &.error-input-email {
                    border: solid 2px #ff0040;
                }

                @include phone{
                    width: 120%;
                }
                @include little-phone{
                    width: 100%;
                }
            }

            .error-input {
                border: 1px solid $alert-color;
            }
        }
    }

    // .add-modal,
    // .add-modal-email {
    //     width: inherit;
    //     margin-left: initial;

    //     @include phone {
    //         width: 100%;
    //         height: 100%;
    //     }

    // }

    .confirm-remove-modal {
        width: 40%;

        @include limit-screen {
            width: 64%;
            margin-left: 18%;
        }

        @include tablet {
            width: 80%;
            margin-left: 10%;
        }

        @include phone {
            width: 100%;
            height: 100%;
            margin-left: 0%;
            top: 0;
            padding: 0;
        }

        @include slideInDown($duration: 0.4s, $function: ease);

        .title {
            text-align: center;
        }

        .loading-remove {
            display: none;
        }
    }
}

.footer {
    margin-top: inherit;
    bottom: 0;
}

.chart-line {
    stroke-dashoffset: 0;
    stroke-dasharray: 290;
    animation: dash 1.3s 0s forwards infinite;
}

@keyframes dash {
    from {
        stroke-dashoffset: 290;
    }

    to {
        stroke-dashoffset: 0;
    }
}

@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(-20deg) scale(1.1);
    }

    30% {
        transform: rotate(20deg);
    }

    40% {
        transform: rotate(-10deg) scale(1);
    }

    50% {
        transform: rotate(10deg);
    }

    60% {
        transform: rotate(-5deg);
    }

    70% {
        transform: rotate(5deg);
    }

    80% {
        transform: rotate(0deg);
    }
}

@keyframes flash {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .1;
    }

    100%{
        opacity: 1;
    }
}