.simulation-alert-placeholder {
    height: 70px;
}
.simulation-alert {
    position: fixed;
    z-index: 999999999;
    width: 100%;
    padding: 20px;
    font-family: 'Eni', sans-serif;
    top: 0;
    background: #fa0;
    display: flex;
    justify-content: space-between;
    font-size: 18px;

    @include tablet {
        font-size: 16px;
    }
    
    .text-container {
        i {
            margin-right: 10px;
        }

        .simu-username {
            font-weight: 600;
        }
    }

    .button-container {
        padding: 0px 40px 0px 15px;
        text-align: right;

        button {
            background-color: #353841;
            font-family: "Open sans";
            border-radius: 30px;
            text-align: center;
            display: flex;
            padding: 4px 40px;
            justify-content: center;
            align-items: center;
            color: white;
            text-decoration: none;
            font-weight: 300;
            transition: .2s ease-in-out;
            border: none;
            cursor: pointer;
        }
    }
    
}