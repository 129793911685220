.comparaison-equivalent {
    $bg-transparent-color: transparentize($secondary-color, 0.3);

    background: linear-gradient($bg-transparent-color, $bg-transparent-color), url("/images/car-comparaison.png") center;
    background-size: cover;
    font-family: $first-font;

    .info-button {
        @extend %secondary-button;
        font-family: $third-font;
        margin: 20px 30px 0 0;
        padding: 5px 12px;
        float: right;
    }

    .main-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;

        > * {
            width: 80%;
        }

        > p {
            font-size: 20px;
        }

        > h3 {
            margin-top: 10px;
            font-size: 30px;
            font-weight: $bold-weight;
        }
    }
}
