/*
 * File: _letter.scss
 * File Created: Tuesday, 30th April 2019
 * Author: Oskar Gruszczynski (o.gruszczynski@i4b.pl)
 * -----
 * GridPocket SAS Copyright (C) 2018 All Rights Reserved
 * This source is property of GridPocket SAS.
 * Please email contact@gridpocket.com for more information.
 */

 $EniYellow: #ffd401;
 $MainBackground : #f7f7f7;
 $BackgroundColor: #ebebeb;
 $ContainerColor:#ffffff;

.body-letter{
    background-color:$MainBackground;   
    div.page-first{
        width: 21cm;
        min-height: 29.7cm; 
        max-height: 29.7cm;
        background-color:$ContainerColor;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        display:flex;
        flex-direction: column;
        font-family: $first-font;
        font-size: 16px;
        overflow: hidden;
        .header{
            display: flex;  
            flex-direction: row;
            padding:30px;
            justify-content: left;
            align-items: center;
            position: relative;
            .logo{
                padding-left:10px;
                padding-right: 45px;
                z-index: 2;
            }
            .logo-eni{
                height:100px; 
            }
            .text-box{   
                padding-top:15px;
                height: 100px; 
                line-height: 25px; 
                z-index: 2;               
                .user-text{
                    font-weight: bold; 
                    font-size: 30px;
                }
                .sub-text{
                    font-weight: 600;
                    font-size: 30px;
                }

            }  
            .bg-grey{
                width: 110%;
                height: 200px;
                position: absolute;
                top: -40px;
                left: -30px;
                z-index: 0;
                transform: rotate(-2deg);
                background-color: $BackgroundColor;
            }          
        }
        .title-report{
            background-color: $ContainerColor;
            padding:40px; 
            height: 80px; 
            display: flex;
            flex-direction: row;
            position: relative;
            .magnifier-glass-icon{
                height: 85%;
                z-index: 9999;
                position:absolute;
                top:0px;
                right:30%;
            }
            .chart-icon{
                height:70%;
                z-index: 9999;
                position:absolute;
                top:10px;
                right:10%; 
                transform:rotate(20deg) ;
            }
            .text-box{ 
                padding-left:30px;
                position: relative;
                display:flex;     
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;         
                border-radius: 4px;
                background-image: linear-gradient(to right, $EniYellow 55%, yellow);
                width:100%;
                height:80px;
                .text-main{
                    font-weight: 600;
                    font-size: 20px;
                    &::first-letter{
                        font-size: 30px;
                    }
                }
                .sub-text{
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 300;
                    margin-top: 8px;
                    font-size: 21px;
                    
                    p {
                        margin: 0 5px;
                    }
                }
            }
            .text-box:after {
                content: '';
                position: absolute;
                bottom: 5px;
                left: 5.5px;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-top-color: $EniYellow;
                border-bottom: 0;
                border-left: 0;
                margin-left: -5.5px;
                margin-bottom: -10px;
            }
        }
        .chart{
            background-color: $ContainerColor;           
            height: 500px; 
            display: flex;
            margin-top: -20px;
            .chart-containter{
                height: 450px;
                width:100%;
                div {
                    canvas {
                        top: -30px !important;
                    }
                }
            }
        }
        .distribution{  
            padding-left:40px;
            padding-right: 40px;  
            margin-top: -80px;
            div.consumption {
                width: 100%;
                display: flex;
                flex-direction: column;               
                align-items: left;
                transition: .2s ease-in-out;
                padding-right: 20px;
                @include limit-screen {
                    width: 400px;
                }
                @include tablet {
                    margin-top: -135px;
                    max-width: 600px;
                }
                @include phone {
                    margin-top: -100px;
                    padding-right: 0px;
                    width: calc(100% - 40px);
                }
                div.title-from {
                    font-size: 25px;
                    font-weight: 300;
                    height: 30px;
                }
                div.chart-box{
                    display: flex;
                    flex-direction: row;
                    width:100%;
                    div.kwh-wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: left; // margin-top: 30px;
                        div.kwh-number {
                            margin-top: 32px;
                            font-size: 40px;
                            font-weight: 300;
                        }
                    }
                    div.price-wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: left;
                        div.kwh-price {
                            margin-top: 32px;
                            font-size: 40px;
                            font-weight: 300;
                        }
                    }
                    div.consumptionKWH {
                        width: 90%;
                        height: 23px;
                        margin-top: 10px;
                        display: flex;
                        flex-direction: row;
                        div.consumptionKWH1 {
                            text-align: left;
                            color: black;
                            font-weight: 500;
                            font-size: 13px;
                            padding-left: 5px;
                            line-height: 20px;
                            width: 0px;
                            overflow: hidden;
                            background-color: $primary-color; // background: linear-gradient(to right, #278fd1 60%, #24b687);
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            @include tablet {
                                font-size: 18px;
                            }
                        }
                        div.consumptionKWH2 {
                            text-align: left; // background-color: #1b445b;
                            background-color: #353841;
                            color: white;
                            font-weight: 500;
                            font-size: 13px;
                            vertical-align: center;
                            line-height: 20px;
                            padding-left: 5px;
                            margin-left: 3px;
                            width: 0px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            @include tablet {
                                font-size: 18px;
                            }
                        }
                    }
                    div.consumptionEUR {
                        width: 90%;
                        height: 22px;
                        margin-top: 10px;
                        display: flex;
                        flex-direction: row;
                        div.consumptionEUR1 {
                            text-align: left;
                            color: black;
                            font-weight: 500;
                            font-size: 13px;
                            padding-left: 5px;
                            line-height: 20px;
                            width: 0px;
                            overflow: hidden;
                            background-color: $primary-color; // background: linear-gradient(to right, #278fd1 60%, #24b687);
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            @include tablet {
                                font-size: 18px;
                            }
                        }
                        div.consumptionEUR2 {
                            text-align: left; // background-color: #1b445b;
                            background-color: #353841;
                            color: white;
                            font-weight: 500;
                            font-size: 13px;
                            vertical-align: center;
                            line-height: 20px;
                            padding-left: 5px;
                            margin-left: 3px;
                            width: 0px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            @include tablet {
                                font-size: 18px;
                            }
                        }
                        div.consumptionEUR3 {
                            text-align: left;
                            background-color: #DED7CA;
                            color: #000;
                            font-weight: 500;
                            font-size: 13px;
                            vertical-align: center;
                            line-height: 20px;
                            padding-left: 5px;
                            margin-left: 3px;
                            width: 0px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            @include tablet {
                                font-size: 18px;
                            }
                        }
                    }
                }
                div.color-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    font-family: $second-font;
                    margin-top: 28px;
                    width: 100%;
                    div.color-1 {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        div.color-round {
                            background-color: $primary-color;
                            border-radius: 100px;
                            width: 13px;
                            height: 13px;
                        }
                        div.color-legend {
                            margin-left: 10px;
                            font-weight: 400;
                            font-size: 14px;
                            @include tablet {
                                font-size: 16px;
                            }
                            @include little-phone{
                                font-size: 10px;
                            }
                        }
                    }
                    div.color-2 {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        margin: 0 30px;
                        div.color-round {
                            background-color: rgb(53, 56, 64);
                            border-radius: 100px;
                            width: 13px;
                            height: 13px;
                        }
                        div.color-legend {
                            margin-left: 10px;
                            font-weight: 400;
                            font-size: 14px;
                            @include tablet {
                                font-size: 16px;
                            }
                            @include little-phone{
                                font-size: 10px;
                            }
                        }
                    }
                    div.color-3 {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        div.color-round {
                            background-color: #DED7CA;
                            border-radius: 100px;
                            width: 13px;
                            height: 13px;
                        }
                        div.color-legend {
                            margin-left: 10px;
                            font-weight: 400;
                            font-size: 14px;
                            @include tablet {
                                font-size: 16px;
                            }
                            @include little-phone{
                                font-size: 10px;
                            }
                        }
                    }
                }
                div.conso-comparaison {
                    width: 100%;
                    margin-top: 20px;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    align-content: center;
                    align-items: center;
                    div.comp-text {
                        font-size: 16px;
                        font-weight: 600;
                        font-family: $first-font;
                        @include tablet {
                            font-size: 20px;
                            margin-top: -30px;
                        }
                        @include phone {
                            margin-top: -30px;
                        }
                        span.conso {
                            font-size: 20px;
                            font-weight: 400;
                            @include tablet {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            .conso-text {
                position: absolute;
                bottom: 60px;
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                font-style: italic;
                @include limit-screen {
                    font-size: 16px;
                    margin-left: 3.5%;
                }
                @include tablet {
                    bottom: 40px;
                    padding: 0 20px;
                    width: 80%;
                }
                @include phone {
                    bottom: 40px;
                    font-size: 12px;
                    width: 80%;
                }
            }
        }
        .footer{          
            height: 200px; 
            display: flex;
            flex-direction: row;   
            margin-top: auto;  
            background-color: $BackgroundColor;
            .section-first{
                display: flex;
                flex-direction: column;
                background-color: $EniYellow;
                width: 50%;
                justify-content: center;
                align-items: center; 
                .header-text{
                    font-weight: bold;
                    font-size: 30px;
                    text-align: center;
                    width: 80%;
                }
                .percent-block{                   
                    display: flex;
                    flex-direction: row;
                    justify-content: right;
                    align-items: center;    
                    margin-top:10px;             
                    .percent{
                        color:#af3433;
                        font-weight: bold;
                        font-size: 51px;
                        margin-right:5px;
                    }
                }
            }
            .section-second{
                background-color: $BackgroundColor;
                width: calc(100% / 3 * 2);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;   
                padding-top:10px;
                .text-box{
                    background-color: $EniYellow;
                    padding:5px;                   
                    width:max-content;
                    font-weight: bold;
                    font-size: 20px;
                }
                .label-container{
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    font-size: 20px;
                    margin-top: 10px;
                    .temperature{                    
                        padding:5px;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        margin-left: -5px;
                        .icon{
                            height:20px;
                            margin-right: 20px;
                        }
                    }
                    .usages{
                        padding:5px;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        .icon{
                            height:20px;
                            margin-right: 20px;
                        }
                    }
                    .reglemente{
                        padding:5px;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        .icon{
                            height:20px;
                            margin-right: 20px;
                        }
                    }

                    .positive{
                        color: green;
                    }

                    .negative{
                        color: #af3433;
                    }
                    
                } 
            }
        }
        .info-text{
            margin-top: 5px;
            background: $ContainerColor;
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left{
                float:left; 
                text-align: left;
                margin-left:15px;
                display: flex;
                .date{
                    margin: 0 5px;
                }
            }
            .right{
                float: right;
                text-align: right;
                margin-right:15px;
            }
        }
    }
    div.page-second{
        width: 21cm;
        min-height: 29.7cm; 
        background-color:$ContainerColor;
        margin-left: auto;
        margin-right: auto;
        display:flex;
        flex-direction: column;
        font-family: $first-font;
        font-size: 16px; 
        .title-second{
            background-color: $ContainerColor;
            display: flex;  
            flex-direction: row;
            padding:40px;
            justify-content: center;
            align-items: center;
            align-content: center; 
            position: relative; 
            .text-box{
                position: relative;
                background-image: linear-gradient(to right, $EniYellow, yellow);
                width:100%;
                height:100px;
                border-radius: 4px;
                .title-understand{
                    font-weight: 600;
                    font-size: 20px;
                    text-transform: uppercase;
                    margin-left: 30px;
                    margin-top: 20px;
                    padding-bottom: 10px;
                    &::first-letter{
                        font-size: 30px;
                    }
                    .bubble-icon{
                        width: 50px;
                        height: 120px;
                        float: right;  
                        transform-origin: 0 50%
                    }
                }
                .subtitle{
                    margin-left: 30px;
                    font-size: 21px;
                    font-weight: 300;
                    display: flex;
                    .date{
                        margin: 0 5px;
                    }
                }
                .content{
                    margin-top: 40px;
                    margin-left: 50px;
                    display: flex;
                    .left{
                        font-weight: bold;
                    }.right{
                        margin-top:5px;
                    }
                    .device-icon{
                        width: 30px;
                        height: 30px;
                        padding-left: 20px;
                        padding-right: 20px;
                    }
                }
                
            }
            .text-box:after {
                content: '';
                position: absolute;
                bottom: 5px;
                left: 5.5px;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-top-color: $EniYellow;
                border-bottom: 0;
                border-left: 0;
                margin-left: -5.5px;
                margin-bottom: -10px;
            }
            .lightbubble-icon{
                height:80%;
                z-index: 9999;
                position:absolute;
                top:20px;
                right:15%;
                transform: rotate(25deg)
            }
        } 
        .usages-overview{
            margin-top: 50px;
            width:100%;
            height:212px;
            background-color:#f7f5f2;
            .title{
                margin-left: 50px;
                margin-top: 20px;
                font-weight: bold;
                font-size: 20px;
                text-align: center;
            }
            .content{
                display: flex;
                margin: -51px 20px 10px;
                position: relative;
                .usages-container{
                    background-color: white;
                    border-radius: 1000px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    .title-repartition{
                        position: absolute;
                        top: 150px;
                        font-size: 14px;
                    }
                    &.first{
                        width: 120px;
                        height: 120px;
                        margin: 20px 5px 0px;
                        .image-usage{
                            width: 55px;
                            height: 55px;
                            margin-bottom: 5px;
                        }
                    }
                    &.second{
                        width: 110px;
                        height: 110px;
                        margin: 26px 5px 0px;
                        .image-usage{
                            width: 50px;
                            height: 50px;
                            margin-bottom: 5px;
                        }
                    }
                    &.third{
                        margin: 33px 5px 0px;
                        width: 100px;
                        height: 100px;
                        .image-usage{
                            width: 45px;
                            height: 45px;
                            margin-bottom: 5px;
                        }
                    }
                    &.fourth{
                        width: 90px;
                        height: 90px;
                        margin: 39px 5px 0px;
                        .image-usage{
                            width: 40px;
                            height: 40px;
                            margin-bottom: 5px;
                        }
                    }
                    &.fifth{
                        width: 80px;
                        height: 80px;
                        margin: 46px 5px 0px;
                        .image-usage{
                            width: 35px;
                            height: 35px;
                            margin-bottom: 5px;
                        }
                    }
                    &.sixth{
                        width: 70px;
                        height: 70px;
                        margin: 57px 5px 0px;
                        .image-usage{
                            width: 30px;
                            height: 30px;
                            margin-bottom: 5px;
                        }
                    }
                    &.seventh{
                        width: 60px;
                        height: 60px;
                        margin: 63px 5px 0px;
                        .image-usage{
                            width: 25px;
                            height: 25px;
                            margin-bottom: 5px;
                        }
                    }
                    &.eighth{
                        width: 50px;
                        height: 50px;
                        margin: 69px 5px 0px;
                        .image-usage{
                            width: 20px;
                            height: 20px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            .subconent{
                display: flex;
                margin-left: 40px;
                .subcontent-title{
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .comparison{
            .comparison-text-box{
                margin: 20px 130px 20px;
                border-radius: 4px;
                background-image: linear-gradient(to right, #ffd401, yellow);
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                .comparison-title{
                    text-align: center;
                    font-weight: bold;
                    font-size: 20px;
                    text-transform: uppercase;
                    &::first-letter{
                        font-size: 30px;
                    }
                }
                &:last-of-type{
                    color: red;
                    &:after{
                        border-top-color: red;
                    }
                }
            }
            .comparison-text-box:after {
                content: '';
                position: absolute;
                bottom: 5px;
                right: 5.5px;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-top-color: yellow;
                border-bottom: 0;
                border-right: 0;
                margin-right: -5.5px;
                margin-bottom: -10px;
            }
            .content-container {
                display: flex;
                flex-direction: row;
                width: 100%; // height: 100%;
                justify-content: center;
                margin-top: 15px;
                @include phone {
                    flex-direction: column;
                }
                .emote-container {
                    flex: 1;
                    @include phone {
                        margin-top: 40px;
                    }
                    .emote-image {
                        width: 80px;
                        margin: 10px 92px;
                        @include bounceIn($duration: 0.4s, $function: ease-in-out, $delay: 0.7s);
                    }
                    .emote-description {
                        font-weight: 400;
                        text-align: center;
                        @include tablet {
                            padding: 0 10px;
                        }
                    }
                    .title-emote {
                        font-weight: 600;
                        text-align: center;
                    }
                }
                .chart-ranking-container-placeholder {
                    padding: 50px 50px 0 50px;
                    margin-bottom: 100px;
                    @include tablet {
                        margin-bottom: 100px;                            
                    }
                    @include phone {
                        margin-bottom: 0px;                            
                    }
                }
                .chart-ranking-container {
                    padding: 0 20px;
                    flex: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    .chart-tooltip-container {
                        display: flex;
                        flex-direction: row;
                        background-color: #35383f;
                    }
                    .chart-rank {
                        width: 100%;
                        height: 25px;
                        border-radius: 100px;
                        .score-alone {
                            margin-right: 0px;
                            margin-left: 30px !important;
                        }
                    }
                    .middle {
                        margin: 10px 0;
                        background-color: $primary-color;
                    }
                    .one,
                    .two {
                        width: 1%;
                        position: relative; // transition: .4s ease-in-out;
                        background-color: #9ad877;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        overflow: hidden;
                        img {
                            border-radius: 300px;
                            position: absolute;
                            left: 0;
                            width: 16px;
                            color: white;
                            top: 1px;
                            padding: 4px;
                            background-color: $button-color;
                        }
                        .user {
                            margin-left: 30px;
                            top: 5px;
                            color: white;
                            font-weight: 300;
                            @include phone {
                                font-size: 14px;
                                overflow: hidden;
                            }
                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.8s);
                        }
                        .score {
                            margin-right: 15px;
                            color: white;
                            font-weight: 300;
                            overflow: hidden;
                            @include phone {
                                font-size: 14px;
                                overflow: hidden;
                            }
                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.7s);
                        }
                    }
                    .two {
                        background-color: $primary-color;
                        width: 1%;
                        img {
                            background-color: $primary-color-hover;
                            color: black;
                        }
                        .user {
                            margin-left: 30px;
                            top: 5px;
                            color: black;
                            font-weight: 300;
                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.85s);
                        }
                        .score {
                            margin-right: 15px;
                            color: black;
                            font-weight: 300;
                        }
                    }
                    .three {
                        width: 1%; // -webkit-transition: width 2s ease 0s;
                        position: relative;
                        background-color: #444750;
                        display: flex; // transition: 0.4s ease-in-out;
                        justify-content: space-between;
                        align-items: center;
                        overflow: hidden;
                        img {
                            border-radius: 100px;
                            position: absolute;
                            left: 0;
                            width: 16px;
                            color: white;
                            top: 1px;
                            padding: 4px;
                            background-color: #373941;
                        }
                        .user {
                            margin-left: 30px;
                            top: 5px;
                            color: white;
                            font-weight: 300;
                            @include phone {
                                font-size: 14px;
                                overflow: hidden;
                            }
                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.9s);
                        }
                        .score {
                            margin-right: 15px;
                            color: white;
                            font-weight: 300;
                            @include phone {
                                font-size: 14px;
                                overflow: hidden;
                            }
                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.7s);
                        }
                    }
                    .color-caption-container {
                        display: none;
                        justify-content: center;
                        align-items: center;
                        flex-direction: row;
                        margin-top: 30px;
                        .color-caption {
                            display: flex;
                            justify-content: center;
                            flex-direction: row;
                            align-items: center;
                            margin: 0 20px;
                            &:first-of-type {
                                .color-round {
                                    background-color: #9ad877;
                                }
                            }
                            &:last-of-type {
                                .color-round {
                                    background-color: #444750;
                                }
                            }
                            .color-round {
                                width: 15px;
                                margin-right: 5px;
                                height: 15px;
                                background-color: $primary-color;
                                border-radius: 100px;
                            }
                            .caption {
                                font-family: $first-font;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        .daily-tip-widget{
            height: 125px;
            background-color:#e4e4e3;
            margin-top: 20px;
            display: flex;
            margin-left: 15%;
            margin-right: 15%;
            border-radius: 60px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .left-container-leftside{
                background-color: $primary-color;
                width: 125px;
                height: 125px;
                border-radius: 100px;
                background-size: 40%;
                background-position: center;
                background-repeat: no-repeat;
            }
            .right-container-leftside{
                width: 70%;
                margin-right: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .title-container{
                    font-size: 25px;
                    font-weight: 600;
                    align-self: flex-start;
                    margin-bottom: 10px;
                    margin-left: 10px;
                }
            }
        }
        .boutique-banner{
            height: 50px;
            background-color:#fbd035;
            margin-top: 20px;
            .boutique-banner-title{
                text-align: center;
                font-weight: bold;
                font-size: 20px;
                margin-top: 16px;
                padding-bottom: 13px;
            }
            .boutique-banner-content{
                display: flex;
                height: 220px;
                background-color:#e4e4e3;
                .point-balance{
                    display: flex;
                    flex-direction: column;
                    width: calc(100% / 3 * 1);
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 10px;
                    .balance-text{
                        font-size: 20px;
                        margin-bottom: 5px
                    }
                    .ecotroks-container{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .ecotroks-amount{
                            font-size: 35px;
                            font-weight: 400;
                        }
                        img {
                            height: 20px;
                            margin-left: 10px;
                        }
                    }
                    .white-box{
                        background-color: white;
                        height: 77px;
                        width: 172px;
                        margin-top: 10px;
                        border-radius: 4px;
                        p{  
                            text-align: center;
                            font-size: 13px;
                            font-weight: 300;
                            margin-top: 15px;
                        }
                    }
                }
                .purchasable{
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-top: 10px;
                    width: calc(100% / 3 * 2);
                    @include limit-screen{
                        width: calc(100% - 40px);
                    }
    
                    @include tablet{
                        width: calc(100% - 40px);
                    }
    
                    @include phone{
                        width: calc(100% - 40px);
                    }
                    .product-eco {
                        width: 240px;
                        height: 180px;
                        background-color: #f7f7f7;
                        text-align: center;
                        position: relative;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        font-family: 'Roboto', sans-serif;
                        margin: 10px 10px;
                        transition: .2s ease-in-out;
                        overflow: hidden;
                        cursor: pointer;
                        &:hover{
                            cursor:  pointer;
                        }
                        @include tablet {
                            width: 300px;
                            height: 255px;
                        }
                        .ecotroks-container {
                            border-radius: 30px;
                            background-color: #505050;
                            float: right;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            position: absolute;
                            align-items: center;
                            padding: 5px 10px;
                            z-index: 10;
                            right: 10px;
                            top: 8px;
                            img {
                                width: 20px;
                            }
                            .amount-eco {
                                font-family: 'Roboto', sans-serif;
                                font-size: 12px;
                                color: white;
                                margin-left: 3px;
                            }
                        }
                        .product-image-container{
                            width: 100%;
                            height: 80%;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        .image-eco {
                            width: 100%;
                            z-index: 2;
                            @include little-phone {
                                width: 120%;
                            }
                        }
                        .title-eco {
                            margin-top: 8px;
                            font-weight: 300;
                            font-size: 14px;
                        }
                        .text-eco {
                            font-size: 12px;
                            padding: 0 16px;
                            font-weight: 300;
                        }
                        .ecotroks-btn-product {
                            border-radius: 40px;
                            height: 20px;
                            background-color: #ffd42a;
                            position: absolute;
                            padding: 3px 16px;
                            font-weight: 400;
                            font-size: 14px;
                            opacity: 0;
                            justify-content: center;
                            align-items: center;
                            bottom: 75px;
                            display: flex;
                            z-index: 6;
                            transition: .2s ease-in-out;
                            &:hover {
                                background-color: #ffde40;
                            }
                            img.cart {
                                width: 20px;
                                flex-direction: row;
                                animation: cart 1s ease-in-out infinite alternate;
                            }
                        }
                        &:hover>div.ecotroks-btn-product {
                            cursor: pointer;
                            opacity: 1;
                            bottom: 35px;
                            transition: .2s ease-in-out;
                        }
                    }
                    .available {
                        .ecotroks-container {
                            .amount-eco {
                                color: $button-color;
                            }
                        }
                    }
                    .not-available {
                        .ecotroks-container {
                            .amount-eco {
                                color: $alert-color;
                            }
                        }
                    }
                    .new-available {
                        transition: .5 ease-in-out; // border: 3px solid gold;
                        animation: neon1 1.5s ease-in-out infinite alternate;
                    }
                }
            }
        }
    }
}

