/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */
.header-menu-admin {
    background-color: #f7f7f7;
    height: 60px;
    display: flex;
    justify-content: center;
    cursor: inherit;

    .container-admin-desktop {
        @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);
        padding: 0 10px;
        max-width: 1360px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;

        .hr-container{
            position: absolute;
        
            hr#hr-style{
                height: .35rem;
                // width: 5rem;
                background: $primary-color;
                border: none;
                transition: .2s ease-in-out;
                position: absolute;
                top: -2rem !important;
                z-index: 6;
                width: 0px;
            }
        }

        .left-container {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            img.logo-eni {
                height: 60px;
                padding: 10px 5px 10px 0px !important;
            }

            .link {
                text-decoration: inherit;
                font-family: $second-font;
                font-size: 16px;
                padding: 20px 25px;
                color: black;
                font-weight: 400;
                text-align: center;
                position: relative;
                cursor: pointer;
            }

            .hr-container{
                position: absolute;
            }
        }

        .right-container {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            i{
                margin-left: 0px;
                margin-top: 4px;
                font-size: 20px;
                padding: 15px 20px;
                color: #bbbbbb;
                transition: .2s ease-in-out;
                
                &:hover{
                    color: black;
                    cursor: pointer;
                }
            }
        }
    }

    .alert-popup {
        background-color: rgb(214, 0, 54);
        width: 12px;
        height: 12px;
        text-align: center;
        border-radius: 50px;
        position: absolute;
        color: white;
        font-size: 10px;
        margin-left: 45px;
        margin-top: -22px;
        animation: alertBounce 3s infinite;
    }

    @keyframes alertBounce {
        0% {
            transform: translateY(0);
        }

        20% {
            transform: translateY(-5px);
        }

        35% {
            transform: translateY(1px);
        }

        50% {
            transform: translateY(-2px);
        }

        70% {
            transform: translateY(0.5px);
        }

        100% {
            transform: translateY(0);
        }
    }
}
