.body-advices {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    font-family: $second-font;

    .container-advices {
        flex: 1;
        display: flex;
        justify-content: center;
        text-align: center;
        z-index: 6;
        margin-bottom: 30px;
        .title{
            margin-bottom: 10px;
        }
        .subtitle {            
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            margin-bottom: 50px;
            a, a:visited {
                color: black;
                text-decoration: underline;
            }
        }
        .main-advices {
            max-width: 1200px;
            width: 100%;
            height: 100%;
            padding: 0 10px;
            div.advices-category-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: start;
                width: 100%;
                margin: 0 auto;
                div.advices {
                    margin: 10px;
                    text-align: center;
                    z-index: 5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .2s ease-in-out;
                    flex-direction: column;
                    position: relative;
                    overflow: hidden;
                    position: relative;
                    width: 100px;

                    @include aspect-ratio(1,1);
                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 20%;

                    @include tablet {
                        flex-basis: 30%;
                    }

                    @include phone {
                        flex-basis: 40%;
                        height: 200px;
                    }
                    @include little-phone{
                        flex-basis: 90%;
                    }
                    // &:before {
                    //     content: '';
                    //     display: block;
                    //     padding-top: 100%;
                    // }
                    &:hover {
                        cursor: pointer;
                    } // @include phone {
                    //     width: 20vh;
                    //     height: 20vh;
                    //     margin-top: 3vh;
                    //     margin: 2vh;
                    // }
                    // @include limit-screen{
                    //     width: 30vh;
                    //     height: 30vh;
                    //     margin-top: 5vh;
                    //     margin: 2vh;
                    // }
                    // @include tablet {
                    //     width: 30vh;
                    //     height: 30vh;
                    //     margin-top: 5vh;
                    // }
                    .content-container {
                        font-family: $first-font;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 6;
                        &:hover {
                            .hovered {
                                background-color: rgba(32, 32, 32, 0.2);
                            }
                        }
                        .hovered {
                            background-color: transparent;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            transition: .2s ease-in-out;
                            z-index: -1;
                        }
                        .title-advices {
                            color: white;
                            font-size: 24px;
                            height: 24px;
                            overflow: hidden;
                            @include phone {
                                font-size: 22px;
                            }
                            @include limit-screen {
                                font-size: 25px;
                                font-weight: 600;
                            }
                            @include tablet {
                                font-size: 28px;
                            }
                            @include little-phone{
                                font-size: 32px;
                                height: auto;
                            }
                        }
                        .usage-container {
                            background-color: white;
                            width: 100px;
                            height: 100px;
                            border-radius: 1000px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            margin-top: 20px;
                            @include phone {
                                width: 70px;
                                height: 70px;
                            }
                            @include little-phone{
                                width: 100px;
                                height: 100px;
                            }
                            .image-usage {
                                width: 30px;
                                height: 30px;
                                margin-bottom: 5px;
                                @include phone {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                            .pourcentage {
                                font-size: 22px;
                                @include phone {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
                .cooking {
                    background: center / cover no-repeat url('/images/ecoguide_hub/cooking_thumb.jpg');
                    @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);
                }
                .washanddry {
                    background: center / cover no-repeat url('/images/ecoguide_hub/washanddry_thumb.jpg');
                    @include fadeIn($duration: 0.5s, $function: ease-in-out, $delay: 0.1s);
                }
                .lightning {
                    background: center / cover no-repeat url('/images/ecoguide_hub/light_thumb.jpg');
                    @include fadeIn($duration: 0.5s, $function: ease-in-out, $delay: 0.2s);
                }
                .cooling {
                    background: center / cover no-repeat url('/images/ecoguide_hub/clim_thumb.jpg');
                    @include fadeIn($duration: 0.5s, $function: ease-in-out, $delay: 0.3s);
                }
                .heating {
                    background: center / cover no-repeat url('/images/ecoguide_hub/heating_thumb.jpg');
                    @include fadeIn($duration: 0.5s, $function: ease-in-out, $delay: 0.4s);
                }
                .cold {
                    background: center / cover no-repeat url('/images/ecoguide_hub/cold_thumb.jpg');
                    @include fadeIn($duration: 0.5s, $function: ease-in-out, $delay: 0.5s);
                }
                .ecs {
                    background: center / cover no-repeat url('/images/ecoguide_hub/ecs_thumb.jpg');
                    @include fadeIn($duration: 0.5s, $function: ease-in-out, $delay: 0.6s);
                }
                .multimedia {
                    background: center / cover no-repeat url('/images/ecoguide_hub/tic_thumb.jpg');
                    @include fadeIn($duration: 0.5s, $function: ease-in-out, $delay: 0.7s);
                }
            }
        }
    }
    .title-uncomplete-survey {
        color: red;

        a, a:visited {color: red !important;}
    }
    #advices-panel{
        flex: 1;
    }
    .advices-container {
        display: none;
        @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .main-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;
            max-width: 1200px;
            width: 100%;

            @include tablet {
                max-width: 90%;
            }
            @include phone {
                max-width: 90%;
            }
            @include limit-screen {
                max-width: 90%;
            }
            .main-title-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                position: relative;
                img {
                    width: 30px;
                    height: 30px;
                }
                .title{
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
                .arrow-left {
                    transform: rotate(90deg);
                    position: absolute;
                    left: calc(50% - 250px);
                    @include phone{
                        left: calc(50% - 180px);
                    }
                    @include little-phone{
                        left: calc(50% - 150px);
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                .arrow-right {
                    transform: rotate(-90deg);
                    position: absolute;
                    right: calc(50% - 250px);
                    @include phone{
                        right: calc(50% - 180px);
                    }
                    @include little-phone{
                        right: calc(50% - 150px);
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .subtitle {                
                font-size: 18px;
                font-weight: 400;
                line-height: 22px;

                strong{
                    font-weight: 600;
                }
            }
            .pourcentage {
                margin: 0 5px;
                font-weight: 700;
            }
            .previous-btn {
                background-color: $primary-color;
                width: 100px;
                height: 35px;
                font-size: 14px;
                font-weight: 400;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                align-self: flex-start;
                margin-top: 50px;
                margin-bottom: 10px;
                transition: .2s ease-in-out;
                &:hover {
                    cursor: pointer;
                    background-color: $primary-color-hover;
                }
                .arrow-left {
                    width: 15px;
                    margin-right: 15px;
                    transform: rotate(90deg);
                }
            }
            .advices-pane {
                font-family: $first-font;
                width: 100%;
                height: 200px;
                margin-bottom: 25px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                position: relative;
                .left-container-leftside {
                    width: 455px;
                    @include phone {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
                .right-container-leftside {
                    width: 65%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    padding: 30px;
                    background-color: #f7f7f7;
                    @include phone {
                        font-size: 20px;
                        z-index: 4;
                        width: 100%;
                        color: white;
                        font-weight: 300;
                        background-color: rgba(black, .5);
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
                .left-container-rightside {
                    width: 65%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    padding: 30px;
                    background-color: #f7f7f7;
                    @include phone {
                        font-size: 20px;
                        z-index: 4;
                        width: 100%;
                        color: white;
                        font-weight: 300;
                        background-color: rgba(black, .5);
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
                .right-container-rightside {
                    width: 455px;
                    @include phone {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
}