/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.body-newaccount {
    $bg-transparent-color: transparentize($alert-color, 0.8);
    background-color: white;
    
    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding: inherit;

        .first-step {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            padding: inherit;

            transition: 0.25s ease-in-out;

            .left-sign-in {
                width: 50%;
                height: 100vh;
                background-image: url("../../../images/login_bg.jpg");
                background-repeat: no-repeat;
                background-size: cover;
                overflow: hidden;
                background-position: center;
                @include tablet {
                    background-image: none;
                    position: absolute;
                    width: 100%;
                }
                @include phone {
                    background-image: none;
                    position: absolute;
                    width: 100%;
                }
                .logo-eni {
                    width: 100px;
                    position: relative;
                    margin-left: 10vh;
                    margin-top: 5vh;
                    @media #{$big-screen} {
                        width: 150px;
                    }
                    @media #{$very-large-screen} {
                        width: 200px;
                    }
                    @media #{$big-phone-screen} {
                        width: 100px;
                    }
                    @include phone {
                        width: 50px;
                        margin-top: 10px !important;
                        margin-left: 10px;
                    }
                }
                .slogan-middle {
                    position: absolute;
                    text-align: center;
                    bottom: 50px;
                    width: 50%;
                    .slogan {
                        color: white;
                        font-family: $third-font;
                        font-weight: 400;
                        font-size: 20px;
                        @media #{$big-screen} {
                            font-size: 24px;
                        }
                        @media #{$very-large-screen} {
                            font-size: 32px;
                        }
                    }
                }
            }
    
            .right-sign-in {
                width: 50%;
                min-height: 100vh;
                background-color: white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
    
                @include fadeIn;
    
                @include tablet{
                    background-color: transparent;
                    width: 100%;
                }
    
                @include phone{
                    background-color: transparent;
                    width: 100%;
                    display: block;
                    margin-top: 100px;
                }
    
                .sub-container {
                    background-color: white;
                    text-align: center;

                    @media #{$little-height-new-account} {
                        overflow-y: scroll;
                    }
                    @include tablet {
                        background-color: transparent;
                    }
                    @include phone {
                        background-color: transparent;
                        padding-bottom: 80px;
                        margin-bottom: 30px;

                        @include phone {
                            padding-bottom: 210px;;
                        }
                    }
    
                    .title-log {
                        font-family: $first-font;
                        font-size: 32px;
                        font-weight: 300;
                        color: black;
                        padding: 0 20px;
                        margin-bottom: 20px;

                        @include tablet {
                            font-size: 48px;
                        }
                        @media #{$very-large-screen} {
                            font-size: 62px;
                        }
                        @include phone {
                            font-size: 25px;
                        }
                    }
    
                    form {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        transition: .2s ease-in-out;
    
                        input {
                            width: 240px; // height: 35px;
                            border-radius: 50px;
                            border: none;
                            background-color: #eeeeee;
                            padding: 10px 15px;
                            font-family: $first-font;
                            margin-bottom: 20px;
                            outline: none;
                            transition-duration: 0.24s;
                            font-size: 14px;
                            &.error {
                                border-color: $alert-color;
                                &::placeholder {
                                    color: $alert-color;
                                }
                                &:hover {
                                    cursor: pointer;
                                }
                            }
    
                            @include tablet {
                                font-size: 20px;
                                width: 350px;
                            }
                            @media #{$very-large-screen} {
                                font-size: 20px;
                                width: 450px; // height: 64px;
                                margin-bottom: 30px;
                                padding: 20px;
                                height: 20px;
                            }
    
                            &:focus::-webkit-input-placeholder {
                                opacity: 0.5;
                            }
                            &::placeholder {
                                color: black;
                            }
                            &:hover {
                                background-color: #e6e6e6;
                            }
                        }
    
                        .birth-date-container {
                            .label-birth {
                                font-family: $first-font;
                                font-size: 16px;
                                margin-top: 30px;
                                margin-bottom: 10px;
    
                                @include tablet {
                                    font-size: 20px;
                                }
                                @media #{$very-large-screen} {
                                    font-size: 30px;
                                }
                            }
    
                            select {
                                padding: 8px 14px;
                                border: none;
                                font-family: $first-font;
                                font-size: 14px;
                                box-shadow: none;
                                background: transparent;
                                background-image: none;
                                -webkit-appearance: none;
                                width: 80px;
                                background: #eeeeee url("../../../images/icons/chevron_down_solid.svg") no-repeat 90% 50%;
                                background-size: 10px;
                                transition: .2s ease-in-out;
                                margin: 10px 5px;
                                border-radius: 100px;
                                &:focus {
                                    outline: none;
                                }
                                &:hover {
                                    cursor: pointer;
                                    background-color: #e6e6e6;
                                }
    
                                @include tablet {
                                    width: 100px;
                                    font-size: 18px;
                                }
    
                                @media #{$very-large-screen} {
                                    width: 150px;
                                    height: 40px;
                                }
                            }
                            .select-month {
                                min-width: 120px;
                            }
                            .error {
                                color: $alert-color;
                                &:hover {
                                    cursor: pointer;
                                }
                            }
                        }
    
                        .error-input {
                            margin-bottom: 30px;
                            color: #a94442;
                            font-size: 16px;
                            font-family: $first-font;
                            font-weight: $bold-weight;
                        }
    
                        .phone-number {
                            padding-left: 70px;
                            width: 270px;
    
                            @include tablet {
                                font-size: 20px;
                                width: 380px;
                            }
    
                            @media #{$very-large-screen} {
                                width: 480px;
                            }
                        }
    
                        .flag-container {
                            z-index: 1;
                            padding: 0 0px 0 0px;
                            .selected-flag {
                                padding-right: 90px;
                                position: absolute;
                                left: 0;
                                padding: 10px 15px;
                                width: 60px;
                            }
                            &:hover .selected-flag {
                                background-color: rgba(0, 0, 0, 0.1);
                                border-radius: 30px 0 0 30px;
                            }
                            .country-list {
                                background-color: white;
                                left: 0;
                                font-family: $first-font;
                                top: 100%;
                                box-shadow: none;
                                border-radius: 10px;
                                padding-right: 100px;
                            }
                        }
                        
                        .next-btn, .prev-btn {
                            @include tablet {
                                width: 350px;
                                font-size: 20px;
                            }
                        }
                    }
                }
    
                .footer-right {
                    bottom: 0;
                    width: 50%;
                    font-family: $first-font;
                    font-size: 12px;
                    position: fixed;
                    
                    @include tablet {
                        width: 100%;
                    }
                    
                    @include phone {
                        position: absolute;
                        width: 100%;
                        height: 210px;;
                    }

                    @media #{$big-screen} {
                        font-size: 16px;
                        margin-top: 65px;
                        height: 70px;
                    }

                    @media #{$very-large-screen} {
                        font-size: 20px;
                        margin-top: 65px;
                        height: 90px;
                    }

                    footer .container-footer a {
                        margin-right: 20px;
                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }

        .consent-step {
            display: block;
            text-align: center;
            font-family: $first-font;
            transition: 0.25s ease-in-out;

            .logo-container {
                text-align: left;
                padding: 20px;
                @include phone {
                    padding-bottom: 10px;
                } 

                img {
                    width: 65px;

                    @include phone {
                        width: 45px;
                    } 
                }
            }

            img.consent-logo {
                max-width: 85%;
                height: 140px;
                margin-bottom: 40px;

                @include phone {
                    width: 100px;
                }
            }

            .consent-title {
                font-size: 22px;
                margin-bottom: 20px;
                max-width: 800px;
                @include phone {
                    margin-right: 20px;
                    margin-left: 20px;
                }
            }

            .consent-buttons {
                margin-top: 80px;
                margin-bottom: 20px;

                @include phone {
                    margin-top: 30px;
                }

                button {
                    margin-bottom: 10px;
                    width: 200px;
                    height: 35px;
                    border-radius: 50px;
                    border: none;
                    cursor: pointer;

                    &.refuse-consent {
                        color: white;
                        background-color: #444444;
                    }
                    &.accept-consent {
                        background-color: $primary-color;
                    }
                }
            }

            .consent-buttons-big {
                @include phone {
                    display: none;
                }
            }
            .consent-buttons-small {
                display: none;
                @include phone {
                    display: block;
                }
            }


        }

        .consent-recap {
            position: relative;
            min-height: 100vh;

            .recap-container {
                padding-bottom: 80px;
                margin-bottom: 30px;

                @include phone {
                    padding-bottom: 210px;;
                }
            }

            .consents-recap-title {
                font-weight: 600;
                font-size: 35px;
                margin-bottom: 50px;

                @include tablet {
                    margin-top: 10px;
                }

                @include phone {
                    margin-top: 10px;
                    margin-bottom: 30px;
                    font-size: 22px;
                }
            }

            p {
                margin: auto;
                width: 80%;
                margin-bottom: 30px;

                @include phone {
                    font-size: 14px;
                } 
            }

            ul {
                width: 80%;
                margin: 0px auto 0px auto;

                li {
                    display: flex;
                    padding: 20px 0px;
                    text-align: left;
                    border-bottom: solid 1px #eee;

                    
                    .consent-recap-elem-image {
                        width: 60px;
                        @include phone {
                            width: 40px;
                        } 
                    }
                    
                    .consent-recap-elem-text {
                        padding-left: 20px;
                        width: 100%;
                        
                        .recap-elem-title {
                            font-weight: 600;
                            margin-bottom: 10px;
                            display: flex;
                            justify-content: space-between;

                            .buttons {
                                min-width: 175px;
                                text-align: right;

                                @include phone {
                                    min-width: 80px;
                                    width: 80px;
                                }

                                button {
                                    padding: 0px;
                                    margin: 0;
                                    width: 80px;
                                    background: #eee;
                                    height: 22px;
                                    font-size: 11px;

                                    @include phone {
                                        margin-bottom: 5px;
                                    }

                                    &.accepted.selected {
                                        background-color: #8fcd77;
                                    }
                                    &.refused.selected {
                                        background-color: #cf4c46;
                                        color: white;
                                    }
                                }
                            }
                        }
    
                        .recap-elem-desc {
                            color: #444444;

                            @include phone {
                                font-size: 14px;
                            } 
                        }
                    }
                }
            }

            .button-group {
                width: 80%;
                text-align: right;
                margin: 0px auto 20px auto;
                
                button {
                    width: fit-content;
                    background: none;
                    color: blue;
                    text-decoration: underline;
                    margin: 0;
                }
            }

            #cgu-error, #consent-error {
                color: #a94442;
                margin-top: 10px;
            }

            .no-consent-notice {
                margin-top: 10px;
                margin-bottom: 10px;
                color: red;
            }

            .checkbox-container {
                margin: auto;
                max-width: 90%;
                width: fit-content;

                > label {
                    width: auto;

                    &.error {
                        color: #a94442;
                    }
                }

                input.error {
                    border-color: #a94442;
                }
            }

            button {
                margin-top: 20px;
                margin-bottom: 10px;
                width: 200px;
                height: 35px;
                border-radius: 50px;
                border: none;
                cursor: pointer;
                background-color: $primary-color;
            }

            footer {
                position: absolute;

                @include phone {
                    height: 210px;;
                }
            }
        }
    }
}