.body-rejoin-question  {
     background-color:#f7f7f7;
     
     .question-view {
         background-color: #FFD500;
         color:black;
         font-family: "Open Sans";
     
         /*== HEADER ==*/
         header { margin-bottom: 70px;}
         header img { width: 90px; }
         
         @media screen and (max-width:767px) {
             header img { width: 37px; }
         }
         
     
          @media screen and (max-width:767px) {
             header { margin-bottom: 21px; }
         }
         
         /*==  TITLE ==*/
         h1 {
             margin-bottom: 25px;
             text-align: center;
             font-family: "Eni";
             font-weight: 500;
             font-size: 60px;
             line-height: 1;
         }
         
         h1 .top-title {
             font-family: "Eni", Arial, sans-serif;
             font-size: 40px;
             font-weight: 500;
         }
         
         h1 .top-title br { display: none; }
         h1 strong { font-family: "Eni"; font-weight: 700;}
         
         h1 a {
             color: black;
             text-decoration: underline
         }
         
         h1 a:hover {
             color: black;
             opacity: .7;
         }
         
         h1 sup { font-size: 40px; }
         
         @media screen and (max-width:1199px) {
             h1 { font-size: 45px; }
             h1 .top-title { font-size: 35px; }
             h1 sup { font-size: 30px; }
         }
         
         @media screen and (max-width:991px) {
             h1 .top-title br { display: inline; }
         }
         
         @media screen and (max-width:767px) {
             h1 { font-size: 45px; }
             h1 .top-title { font-size: 35px; }
             h1 sup { font-size: 20px; }
         }
         
         @media screen and (max-width:575px) {
             h1 { font-size: 28px; }
             h1 .top-title {
                 padding-top: 15px;
                 font-size: 22px;
                 display: block;
            }
         }
         
         
         /*==  BUTTONS  ==*/
         .button-bloc {
             margin: 0 auto;
             display: table;
             text-align: center;
         }
         
         .button-bloc a {
             display: inline-block;
             color: black;
             text-align: center;
             background-color: white;
             font-size: 28px;
             width: 200px;
             border-radius: 37px;
             padding: 11px 0;
             margin: 0 15px 0 15px;
             text-decoration: none;
             transition: .2s;
         }
         
         .button-bloc a.oui-btn:hover { color: #9AD877 }
         .button-bloc a.non-btn:hover { color: #C83257 }
             
        .button-bloc a {
            font-size: 20px;
            width: 130px;
            margin: 0 8px 10px 15px;
            padding: 7px 0;
        }
         
         .picture-bg { max-width: 100%; display: block; }
         .modal-container { display: none; }
         
         /*==  MODAL  ==*/
         .modal-linky {
            position:fixed;
            align-items: center;
            justify-content: center;
            top:0;
            left:0;
            width:100%;
            height: 100%;
            background:rgba(0, 0, 0, 0.7);
            animation: fadeIn .3s both;
        }
         
        .modal-wrapper {
            overflow: auto;
            width: 900px;
            max-height: calc(100vh - 30px);
            padding: 20px;
            position: absolute;
            background-color: white;
            max-width: calc(100vw - 30px);
         }
         
         .modal-wrapper h2 {
             font-family: "Eni"; font-weight: 700;
             font-size: 36px;
             margin-top: 20px;
             margin-bottom: 20px;
             text-align: center;
         }
         
         .modal-wrapper h2 sup {
             font-size: 22px;
             text-decoration:none;
         }
         
         .modal-wrapper p {
             font-size:16px;
             text-align:left;
             line-height: 1.5;
         }
         
         .modal-wrapper p sup { font-size: 14; }
         
         .modal-wrapper p:first-of-type { margin-top: 20px; }
         
         .modal-wrapper .modal-picture {
             width: 140px;
             margin: 0 30px;
             float:left;
             vertical-align: baseline;
         }
         
         .modal-wrapper .js-modal-close {
            position: absolute;
            display: block;
            height: auto;
            background-color: transparent;
            border:none;
            top:0;
            right:0;
            transition: 0.25s ease-in-out;
         }
         
         
         @media screen and (max-width:767px) {
            .modal-wrapper p { font-size:16px; }
            .modal-wrapper h1 { font-size:30px; }
            .modal-wrapper .modal-picture { display:none; }
         }
         
         @media screen and (max-width:575px) {
             .modal-wrapper h1 {
                 font-size:28px;
                 padding-top: 10px;
             }
             .modal-wrapper p { font-size:16px; }
             .modal-wrapper .btn-modal-close {
                 position: absolute;
                 right:0px;
                 top:0px;
                 display: block;
                 height: auto;
             }
         }
         
        
        @keyframes fadeIn {
            from { opacity:0; }
            to { opacity:1; }
        }
        
         @keyframes slideFromTop {
            from { top: 0; transform: translate(-50%, -100%); }
            to { top: 47%; transform: translate(-50%, -100%); }   
        }
    }
}


