/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.body-profile {
    display: flex;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;
    font-family: $second-font;

    .crop-modal {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        z-index: 10000;

        .content-modal {
            display: block;
            position: absolute;
            text-align: center;
            top: 10%;
            left: 35%;
            width: 500px;
            height: 450px;
            background-color: #fff;
            z-index: 10000;
            box-shadow: 0px 0px 36px -10px rgba(0, 0, 0, 0.25);

            > .modal-title {
                font-family: $first-font;
                font-size: 25px;
                margin: 20px 0 20px 0;
            }

            .resized-croopie {
                height: 60%;
            }

            .validate-crop {
                margin-top: 50px;
                width: 200px;
                padding: 0 12px;
                height: 35px;
                border-radius: 40px;
                font-size: 14px;
                background-color: $button-color;
                border: none;
                color: white;
                margin-left: 12px;
                font-weight: 300;
                outline: none;
                transition: .2s ease-in-out;
                cursor: pointer;

                &:hover {
                    cursor: pointer;
                    background-color: $button-color-hover;
                }
            }

            @include limit-screen {
                left: 25%;
            }


            @include tablet {
                left: 20%;
            }


            @include phone {
                top: 150px;
                left: 0;
                width: 100%;
            }
        }
    }

    .checkbox-container {
        justify-content: left;
    }

    .container-profile {
        flex: 1;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 100px;

        @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);

        .main-profile {
            max-width: 1360px;
            width: 100%;
            height: 100%;

            @include tablet {
                width: 'inherit';
            }


            .sub-container {
                display: flex;
                flex-direction: row;
                text-align: left;
                justify-content: center;
                align-content: center;
                width: 100%;

                @include phone {
                    flex-direction: column;
                }


                @include tablet {
                    justify-content: center;
                }


                .section-label {
                    margin-bottom: 30px;
                    font-size: 24px;
                }

                .menu-profile {
                    // margin-left: 200px;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;

                    @include phone {
                        width: 100%;
                        height: 100%;
                        overflow-y: hidden;
                        overflow-x: scroll;
                        clear: both;
                        flex-direction: row;
                        position: relative;
                        padding: 20px 0;
                    }


                    @include tablet {
                        width: auto;
                        margin-left: 20px;
                        margin-right: 40px;
                    }


                    .btn {
                        position: relative;
                        width: auto;
                        display: flex;
                        justify-content: center;
                        padding: 8px 35px;
                        margin-top: 10px;
                        background-color: #e7e7e7;
                        border-radius: 40px;
                        font-size: 14px;
                        text-decoration: none;
                        color: black;
                        transition: .1s ease-in-out;
                        flex-shrink: 0;

                        &.signout-btn i {
                            position: absolute;
                            right: 25px;

                            @include tablet {
                                display: none;
                            }


                            @include phone {
                                display: none;
                            }


                            @include little-phone {
                                display: none;
                            }
                        }

                        @include phone {
                            width: auto;
                            margin: 0 5px !important;
                            padding: 7px 20px;
                            text-align: left;
                            flex-shrink: 0;
                            display: flex;
                            justify-content: center;
                            font-size: 18px;

                            :last-of-type {
                                margin-right: 50px;
                            }
                        }

                        @include tablet {
                            font-size: 14px;
                            width: 200px;
                            flex-shrink: 0;
                            padding: 10px 20px;
                            overflow: hidden;
                        }

                        &:hover {
                            cursor: pointer;
                            background-color: #c7c7c7;
                        }
                    }

                    .active {
                        background-color: #505050;
                        color: white;

                        &:hover {
                            cursor: pointer;
                            background-color: #505050;
                        }
                    }
                }

                hr {
                    margin: 0 30px;
                    opacity: 0.3;

                    @include phone {
                        display: none;
                    }


                    @include tablet {
                        margin: 0;
                    }
                }

                .notification-container {
                    > .checkbox-container {
                        margin-top: 24px;
                    }
                }

                .my-profile-container,
                .notification-container,
                .privacy-container {
                    display: flex;
                    flex-direction: column;
                    width: 600px;

                    @include phone {
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                        margin-top: 20px;
                    }


                    @include tablet {
                        margin-left: 40px;
                        margin-right: 20px;
                        width: 'inherit';
                    }


                    .first-name-container {
                        margin-right: 25px;
                    }

                    .name-label {
                        display: flex;
                        flex-direction: row;
                        font-size: 14px;
                        font-weight: 400;

                        @include phone {
                            font-size: 16px;
                        }


                        @include tablet {
                            font-size: 16px;
                        }


                        span {
                            margin-left: 5px;
                        }
                    }

                    .profile-picture {
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    .profile-image {
                        width: 120px;
                        height: 120px;
                        border-radius: 100px;
                        margin: 16px 0;
                        overflow: hidden;
                        position: relative;
                        background-image: url("../../../images/icons/user_circle_ico.svg");

                        @include phone {
                            width: 150px;
                            height: 150px;
                        }


                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 100%;
                            position: absolute;

                            &#profile-picture-spinner {
                                display: none;
                                position: absolute;
                                width: 120px;
                            }
                        }
                    }

                    .change-profile-picture {
                        width: 260px;
                        height: 25px;
                        font-size: 12px;
                        -webkit-appearance: none;
                        font-weight: 300;
                        background-color: $primary-color;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 40px;
                        transition: .2s ease-in-out;
                        overflow: hidden;
                        cursor: pointer;

                        @include phone {
                            font-size: 16px;
                            padding: 5px 0px;
                            width: calc(100% - 40px);
                        }

                        @include tablet {
                            font-size: 16px;
                            padding: 5px 0;
                        }

                        &:hover {
                            background-color: $primary-color-hover;
                            cursor: pointer;
                        }
                    }

                    input[type="file"] {
                        //Works on IE9 mini
                        display: none;
                    }

                    .title-section{
                        margin: 20px 0;
                    }

                    .checkbox-container {
                        font-family: $second-font;
                        padding: 5px 0px;

                        label {
                            padding-right: 20px;
                            width: unset;
                        }
                    }

                    .warning-enedis-optin, .warning-grdf-optin {
                        margin-top: 30px;
                        color: $alert-color;
                        text-align: justify;
                        font-size: 18px;
                        display: none;
                        margin-bottom: 20px;

                        @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0s);

                        strong {
                            font-weight: 600;
                        }

                        @include phone {
                            padding: 0 20px;
                        }
                    }

                    .action-button {
                        background-color: $primary-color;
                        font-family: $second-font;
                        padding: 10px 20px;
                        border-radius: 100px;
                        border: none;
                        width: fit-content;
                        box-shadow: none;
                        color: black;
                        text-decoration: none;
                        font-weight: 300;
                        font-size: 14px;
                        transition: .2s ease-in-out;
                        cursor: pointer;
                        margin-bottom: 15px;

                        &:hover {
                            background-color: $primary-color-hover;
                        }
                    }

                    .mail-container {
                        width: 100%;

                        @include phone {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                        }


                        @include tablet {
                            width: auto;
                            flex-shrink: 1;
                        }


                        .label-email {
                            font-size: 14px;
                            margin-top: 32px;
                            margin-bottom: 10px;
                            font-weight: 400;

                            @include phone {
                                align-self: flex-start;
                                margin-left: 20px;
                                font-size: 16px;
                            }
                        }

                        .error-email-label {
                            display: none;
                            font-size: 14px;
                            margin-top: 20px;
                            margin-bottom: 20px;
                            font-weight: 400;
                            color: $alert-color;

                            @include phone {
                                align-self: flex-start;
                                margin-left: 20px;
                                font-size: 16px;
                            }
                        }

                        .input-email {
                            width: 400px;
                            height: 22px;
                            outline: none;
                            border: 1px solid #c2c2c2;
                            border-radius: 30px;
                            padding-left: 20px;
                            font-size: 13px;
                            background-color: #f7f7f7;
                            -webkit-appearance: none;

                            @include phone {
                                width: calc(100% - 60px);
                                height: 31px;
                                font-size: 16px;
                            }


                            @include tablet {
                                width: calc(100% - 40px);
                                font-size: 14px;
                            }
                        }

                        .error-border-email {
                            border: 1px solid $alert-color;
                            background-color: rgba($alert-color, 0.1);
                        }
                    }

                    .mobile-container {
                        width: 100%;

                        @include phone {
                            display: flex;
                            justify-content: center;
                            flex-direction: column;
                            align-items: center;
                        }


                        .label-phone {
                            font-size: 14px;
                            margin-top: 32px;
                            margin-bottom: 10px;
                            font-weight: 400;

                            @include phone {
                                align-self: flex-start;
                                margin-left: 20px;
                                font-size: 16px;
                            }


                            @include tablet {
                                font-size: 16px;
                            }
                        }

                        .input-phone {
                            width: 400px;
                            height: 22px;
                            outline: none;
                            border: 1px solid #c2c2c2;
                            border-radius: 30px;
                            padding-left: 20px;
                            font-size: 13px;
                            background-color: #f7f7f7;
                            -webkit-appearance: none;

                            @include phone {
                                width: calc(100% - 60px);
                                font-size: 14px;
                                height: 31px;
                            }


                            @include tablet {
                                width: calc(100% - 40px);
                                font-size: 14px;
                            }
                        }
                    }

                    .age-displayer {
                        font-size: 14px;
                        margin-top: 32px;
                        margin-bottom: 10px;
                        font-weight: 400;

                        @include phone {
                            align-self: flex-start;
                            margin-left: 20px;
                            font-size: 16px;
                        }


                        @include tablet {
                            font-size: 16px;
                        }
                    }

                    .checkbox-container {
                        width: 100%;

                        @include phone {
                            align-self: flex-start;
                            margin-left: 15px;
                        }


                        @include tablet {
                            align-self: flex-start;
                            margin-left: -5px;
                        }
                    }

                    .button-container {
                        margin-top: 20px;

                        .cancel-btn {
                            width: 100px;
                            height: 35px;
                            border-radius: 40px;
                            font-size: 14px;
                            border: none;
                            background-color: #505050;
                            color: white;
                            font-weight: 300;
                            outline: none;
                            transition: .2s ease-in-out;
                            cursor: pointer;

                            &:hover {
                                cursor: pointer;
                                background-color: #5d5d5d;
                            }
                        }

                        .validate-btn {
                            width: 200px;
                            padding: 0 12px;
                            height: 35px;
                            border-radius: 40px;
                            font-size: 14px;
                            background-color: $button-color;
                            border: none;
                            color: white;
                            margin-left: 12px;
                            font-weight: 300;
                            outline: none;
                            transition: .2s ease-in-out;
                            cursor: pointer;

                            &:hover {
                                cursor: pointer;
                                background-color: $button-color-hover;
                            }
                        }
                    }

                    .confirmation-label {
                        display: none;
                        margin-top: 50px;

                        .label {
                            color: $button-color;
                            font-size: 20px;
                        }
                    }

                    .rgpd-download-notice-ko { color: red; font-size: 14px; margin-bottom: 15px; }
                    .rgpd-download-notice-ok { color: green; font-size: 14px; margin-bottom: 15px; }
                }

                p.consent-info {
                    font-size: 14px;
                    line-height: 16px;
                }

                .no-consent-notice {
                    margin-top: 10px;
                    margin-bottom: 10px;
                    color: red;
                    font-size: 14px;
                }

                ul.consents {
                    padding: 0px 15px 15px 15px;

                    li {
                        display: flex;
                        padding: 15px 0px;

                        text-align: left;

                        border-bottom: solid 1px #eee;

                        .recap-elem-title {
                            font-weight: 600;
                            margin-bottom: 10px;
                            display: flex;
                            justify-content: space-between;
                            font-size: 14px;
                        }

                        .consent-recap-elem-image {
                            width: 40px;
                            height: 60px;
                            margin-right: 20px;
                        }

                        .recap-elem-desc {
                            color: #444444;
                            font-size: 12px;
                        }

                        .switch {
                            display: flex;
                            justify-content: center;
                            align-items: center;
            
                            input[type=checkbox]{
                                height: 0;
                                width: 0;
                                visibility: hidden;
                            }
                            
                            label {
                                cursor: pointer;
                                text-indent: -9999px;
                                width: 30px;
                                height: 13px;
                                background: rgb(228, 162, 161);
                                display: block;
                                border-radius: 100px;
                                position: relative;
                            }
                            
                            label:after {
                                content: '';
                                position: absolute;
                                top: -2px;
                                left: -2px;
                                width: 17px;
                                height: 17px;
                                background: rgb(207, 76, 70);
                                border-radius: 90px;
                                transition: 0.3s;

                                box-shadow: 2px 1px 2px #0000002e;
                            }
                            
                            input:checked + label {
                                background: rgb(197, 229, 185);

                                &:after {
                                    background: rgb(143, 205, 119);
                                }
                            }
                            
                            input:checked + label:after {
                                left: calc(100% + 2px);
                                transform: translateX(-100%);
                            }
                            
                            label:active:after {
                                width: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
}
