/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

footer {
    $bg-transparent-color: transparentize($alert-color, 0.8);

    width: 100%;
    height: 80px;
    background-color: $black;
    font-family: $second-font;
    display: flex;
    justify-content: center;
    position: relative;
    font-size: 13px;
    margin-top: 100px;
    bottom: 0;
    margin-top: 30px;

    @include tablet {}


    @include phone {
        font-size: 14px;
        height: 100%;
    }


    @include limit-screen {}


    .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }

    .modal {
        display: none;
        position: fixed;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        z-index: 1000;

        .dark-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        
            background-color: rgba(255, 255, 255, 0.3);            
        }

        .modal-content {
            text-align: center;
            background-color: #fefefe;
            margin: auto;
            padding: 20px;
            font-family: $first-font;
            font-weight: 300;
            font-size: 14px;
            width: 35%;
            box-shadow: 0px 0px 36px -10px rgba(0, 0, 0, 0.25);
            z-index: 1200;

            > h2 {
                margin-top: 10px;
                font-size: 20px;
                font-weight: $title-weight;
            }

            @include bounceInDown($duration: 0.5s, $function: ease);
        }

        .contact-icon {
            width: 100px;
            height: 100px;
        }

        .decr {
            margin-top: 20px;
            font-family: $second-font;
            line-height: 26px;
            font-size: 16px;
        }

        .number {
            font-size: 1.3em;
            font-weight: bold;
        }
    }

    .container-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 1200px;
        width: 100%;
        height: 100%;
        justify-content: flex-end;

        @include tablet {
            width: calc(100% - 40px);
        }


        @include phone {
            width: calc(100% - 40px);
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }


        @include limit-screen {
            width: calc(100% - 40px);
        }


        .powered-by {
            color: white;
            font-weight: 600;
            margin-left: 30px;

            @include phone {
                margin-left: 0px;
                margin-top: 20px;
                margin-bottom: 20px;
                font-size: 16px;
            }


            a {
                color: inherit;
                font-weight: 400;
                text-decoration: inherit;
                transition: .2s ease-in-out;

                &:hover {
                    text-decoration: underline;
                    color: #208cd4;
                }
            }
        }

        .mentions-legales, .cookies, .confidentials-terms, .contact {
            color: white;
            cursor: pointer;
            text-decoration: none;
            width: auto;

            @include phone {
                margin-top: 10px;
                background-color: rgb(49, 49, 49);
                border-radius: 100px;
                padding: 6px 15px;

                &:first-of-type {
                    margin-top: 20px;
                }
            }
        }

        .confidentials-terms:after, .mentions-legales:after, .contact:after {
            content: '|';
            color: white;
            margin: 0px 10px 0px 10px;

            @include phone {
                display: none;
            }
        }
    }
}
