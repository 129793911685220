@import url('https://fonts.googleapis.com/css?family=Lobster'); //Will be deleted but wait for now 
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,700"); //Not used for now but wait before deleting 
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

@font-face {
    font-family: "Eni";
    src: url("/fonts/EniLine-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Eni";
    src: url("/fonts/EniLine-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Eni";
    src: url("/fonts/EniLine-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

