/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.next-btn, .force-next-btn {
    justify-content: center;
    font-family: $first-font;
    position: relative;
    margin-top: 50px;
    width: 270px;
    height: 35px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    border: 0px;
    background-color: $primary-color;
    outline: none;
    font-size: 14px;
    transition: 0.25s ease-in-out;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    color: black;
    .arrow-right {
        width: 20px;
        position: absolute;
        top: calc((100% - 20px)/2);
        left: 85%;
        @media #{$big-screen} {
            width: 30px;
            left: 85%;
            top: calc((100% - 30px)/2);
        }
        @media #{$very-large-screen} {
            width: 40px;
            left: 85%;
            top: calc((100% - 40px)/2);
        }
    }
    @media #{$big-screen} {
        font-size: 20px;
        width: 380px;
        height: 48px;
    }
    @media #{$very-large-screen} {
        font-size: 25px;
        width: 480px;
        height: 64px;
    }
    &:hover {
        box-shadow: inset 20em 0em $primary-color-hover;
        cursor: pointer;
        .arrow-right {
            animation: bounce 2s infinite;
        }
    }
}

.prev-btn, .close-modal-btn{
    background-color: #444444;
    color: white;
    margin-top: 10px;
    justify-content: center;
    font-family: $first-font;
    position: relative;
    width: 270px;
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    border: 0px;
    outline: none;
    font-size: 14px;
    transition: 0.25s ease-in-out;
    padding: 0;
    cursor: pointer;
    padding: inherit;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-decoration: inherit;
    .arrow-left {
        width: 20px;
        position: absolute;
        top: calc((100% - 20px)/2);
        left: 85%;
        fill: #ffffff;
        @media #{$big-screen} {
            width: 30px;
            top: calc((100% - 30px)/2);
            left: 85%;
        }
        @media #{$very-large-screen} {
            width: 40px;
            top: calc((100% - 40px)/2);
            left: 85%;
        }
    }
    &:hover {
        box-shadow: inset 20em 0em #5f5f5f;
        cursor: pointer;
        .arrow-left {
            animation: bounceReverse 2s infinite;
        }
    }
    @media #{$big-screen} {
        font-size: 20px;
        width: 380px;
        height: 48px;
    }
    @media #{$very-large-screen} {
        font-size: 25px;
        width: 480px;
        height: 64px;
    }
}
