
#contact-modal.modal {
    display: none;
    position: fixed;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1000;

    @include phone {
        padding-top: 30px;
    }

    .dark-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
    }

    .modal-content {
        text-align: center;
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        font-family: $second-font;
        font-weight: 300;
        font-size: 14px;
        width: 35%;
        box-shadow: 0px 0px 36px -10px rgba(0, 0, 0, 0.25);
        z-index: 1200;
        position: relative;

        @include phone {
            width: 85%;
        }

        .close {
            position: absolute;
            top: 20px;
            right: 40px;
            font-size: 25px;

            &:hover {
                cursor: pointer;
            }
        }

        > h2 {
            margin-top: 10px;
            font-size: 20px;
            font-weight: $title-weight;
        }

        @include bounceInDown($duration: 0.5s, $function: ease);
    }

    .contact-icon {
        width: 100px;
        height: 100px;
    }

    .decr {
        margin-top: 20px;
        font-family: $second-font;
        line-height: 26px;
        font-size: 16px;
    }

    .number {
        font-size: 1.3em;
        font-weight: bold;
    }
}


.modal {
    display: none;
    position: fixed;
    z-index: 1200;
    padding-top: 15%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    text-align: center;

    .modal-content {
        background-color: white;
        box-shadow: 0px 0px 36px -10px rgba(0, 0, 0, 0.5);
        color: black;
        margin: auto;
        padding: 5px 10px 50px 10px;
        border-radius: 3px;
        font-family: "Eni", sans-serif !important;
        width: 50%;

        .button {
            font-family: $second-font;
            border-radius: 50px;
            position: relative;
            width: auto;
            font-size: 15px;
            background-color: $primary-color;
            height: 40px;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: 0.25s ease-in-out;
            outline: none;
            border: 0px;
            margin-top: 10px;

            @media #{$tablet-screen} {
                width: 250px;
                height: 45px;
            }

            &:hover {
                background-color: $primary-color-hover;
                cursor: pointer;
            }

            .arrow-right {
                width: 25px;
                position: absolute;
                margin-left: 20px;
                margin-top: -2px;
                animation: bounce 2s infinite;

                @media #{$tablet-screen} {
                    margin-left: 20px;
                }
            }
        }
    }
}