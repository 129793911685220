/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.body-article {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
    @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0s);
    .main-article {
        display: flex;
        flex: 1;
        align-items: center;
        flex-direction: column;
        @include fadeIn($duration: 0.3s, $function: ease-in-out, $delay: 0.1s);
        @include limit-screen {
            padding: 0 20px;
        }
        @include tablet {
            padding: 0 20px;
        }
        @include phone {
            padding: 0 20px;
            justify-content: center;
            text-align: center;
        }
        @include little-phone {
            padding: 0 20px;
        }
        .main-article-container {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 100%;
            max-width: 1360px;
            @include phone {
                flex-direction: column;
            }
            @include little-phone {
                flex-direction: column;
            }
            .left-container {
                flex: 1;
                position: relative;
                .image-article-container {
                    width: 100%;
                    z-index: 2;
                    height: 400px;
                    background-size: cover;
                    background: center no-repeat url("");
                    @include limit-screen {
                        height: 350px;
                    }
                    @include tablet {
                        height: 400px;
                    }
                    @include phone {
                        height: 250px;
                    }
                    @include little-phone {
                        height: 200px;
                    }
                }
                .ecotroks-price-container {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    z-index: 4;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    background-color: $secondary-color;
                    padding: 8px 14px;
                    border-radius: 100px;
                    color: white;
                    font-family: $first-font;
                    font-size: 20px;
                    img {
                        width: 25px;
                        margin-right: 10px;
                    }
                }
            }
            .right-container {
                flex: 1;
                font-family: $first-font;
                display: flex;
                flex-direction: column;
                padding-left: 30px;
                @include phone {
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding-left: unset;
                }
                @include little-phone {
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding-left: unset;
                }
                .article-description {
                    font-family: $second-font;
                    width: 570px;
                    font-size: 16px;
                    line-height: 20px;

                    @include tablet {
                        width: 400px;
                    }
                    @include phone {
                        width: 100%;
                        margin-top: 30px;
                    }
                    @include little-phone {
                        width: 100%;
                        margin-top: 30px;
                    }
                }
                .ecotroks-amount-article {
                    display: flex;
                    flex-direction: row;
                    margin-top: 40px;
                    font-size: 28px;
                    @include little-phone {
                        font-size: 25px;
                    }
                    .ecotroks-amount {
                        margin: 0 5px;
                        color: $button-color;
                    }
                }
                .btn-purchase {
                    background-color: $primary-color;
                    font-size: 16px;
                    padding: 10px 70px;
                    margin-top: 40px;
                    border-radius: 100px;
                    align-self: flex-start;
                    transition: .2s ease-in-out;
                    @include phone {
                        align-self: center;
                    }
                    @include little-phone {
                        align-self: center;
                    }
                    &:hover {
                        cursor: pointer;
                        background-color: $primary-color-hover;
                    }
                }
                .not-available {
                    background-color: rgba($secondary-color, .3);
                    &:hover {
                        background-color: rgba($secondary-color, .3);
                    }
                }
                .available {
                    background-color: $primary-color;
                    font-family: $second-font;
                    //font-size: 16px;
                    &:hover {
                        background-color: $primary-color-hover;
                    }
                }
            }
        }
        .btn-return {
            color: black;
            margin-top: 50px;
            font-family: $second-font;
            font-size: 16px;
            margin-bottom: 50px;
        }
    }
    .address-shipping-container {
        display: none;
        flex: 1;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;
        @include limit-screen {
            padding: 0 20px;
        }
        @include tablet {
            padding: 0 20px;
        }
        @include phone {
            padding: 0 20px;
            justify-content: center;
            text-align: center;
        }
        @include little-phone {
            padding: 0 20px;
        }
        .progression-bar-container {
            margin-top: 24px;
            margin-bottom: 24px;
            width: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            transition: .2s ease-in-out;
            .progression-bar {
                width: 70%;
                height: 5px;
                border-radius: 30px;
                background-color: #f7f7f7;
            }
            .progression-bar-active {
                width: 0%; // make the bar go forward
                height: 5px;
                border-radius: 40px;
                background-color: $primary-color;
                position: relative;
                margin-top: -5px;
                margin-right: auto;
                margin-left: 15%;
                transition: .6s ease-in-out;
            }
            .bubble-container {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                width: 70%;
                position: relative;
                margin-top: -22px;
                @include phone {
                   margin-top: -18px;
                }
                .progression-bubble {
                    height: 40px;
                    width: 40px;
                    border-radius: 20px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 300;
                    font-family: $first-font;
                    background-color: #f7f7f7;
                    transition: .2s ease-in-out;
                    transition-delay: .4s;
                    @include phone {
                        height: 30px;
                        width: 30px;
                    }
                }
                .one {
                    background-color: $primary-color;
                }
                .two {}
                .three {}
            }
        }
        .shipping-container, .shipping-container-confirmation {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 100%;
            max-width: 1360px;
            @include fadeIn($duration: 0.3s, $function: ease-in-out, $delay: 0.1s);

            @include phone{
                flex-direction: column;
            }
            .shipping-form {
                font-family: $first-font;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                flex-direction: column;
                flex: 1;
                max-width: 1360px;
                margin-right: 60px;
                @include tablet{
                    margin-right: 30px;
                }
                @include phone {
                    justify-content: center;
                    align-items: center;  
                    margin: 0;
                }
                .name-container {
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    @include tablet{
                        width: 100%;
                    }
                    input {
                        width: 330px;
                        height: 10px;
                        border-radius: 50px;
                        border: none;
                        font-size: 14px;
                        background-color: #eeeeee;
                        padding: 15px;
                        font-family: $first-font;
                        margin: 10px 0;
                        outline: none;
                        transition-duration: 0.24s;
                        @include tablet {
                            width: 100%;
                            height:10px;
                        } 
                        &::placeholder {
                            color: black;
                        }
                        &:nth-of-type(3){
                            &::placeholder {
                                color: #c2c2c2;
                            }
                        }
                        &:nth-of-type(4){
                            &::placeholder {
                                color: #c2c2c2;
                            }
                        }
                        &:nth-of-type(5){
                            &::placeholder {
                                color: #c2c2c2;
                            }
                        }
                    }
                    .error-input{
                        border: 1px solid $alert-color;
                    }
                    .label-confirm{
                        color: #c7c7c7;
                        &:first-of-type{
                            margin-top: 30px;
                        }
                    }
                    .input-confirm{
                        margin: 10px 0;
                        font-family: $second-font;
                        font-size: 18px;
                    }
                }
                .confirm{
                    width: 330px;
                    height: 380px;
                }
            }
            
            .balance-article-container{
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                font-family: $first-font;
                font-size: 16px;
                margin-top: 20px;
                margin-left: 60px;
                @include tablet{
                    margin-left: 30px;
                }
                @include phone {
                    justify-content: center;
                    align-items: center;  
                    margin: 0;
                }
                .balance-container{
                    flex-direction: column;
                    align-items: center;
                    width: 300px;
                    .balance-title{
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: row;
                        align-items: center;
                        margin-bottom: 10px;
                        &:nth-of-type(1){
                            margin-top: 30px;
                        }
                        &:nth-of-type(2){
                            color: $alert-color;
                        }
                        .former-balance{
                            margin-left: 5px;
                        }
                        .price-article{
                            margin-left: 5px;
                        }
                        .new-balance{
                            margin-left: 5px;
                        }
                        img {
                            width: 30px;
                            margin-left: 5px;
                            
                        }
                    }
                }
                .article-sumup-container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    @include fadeIn($duration: 0.3s, $function: ease-in-out, $delay: 0.1s);

                    .article-title{
                        font-size: 22px;
                        margin: 10px 0;
                    }
                    .article-image-shipping{
                        width: 300px;
                        height: 200px;
                        background-image: url('../../../images/demo-ecotroks-child.jpeg');
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                }
            }
            .alone{
                margin-top: unset;
                margin-left: unset;
                justify-content: center;
                align-items: center;
            }
        }
        .done-congrats-container{
            display: none;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 90px 0;
            @include fadeIn($duration: 0.3s, $function: ease-in-out, $delay: 0.1s);

            .round{
                width: 100px;
                height: 100px;
                border-radius: 100px;
                background-color: grey;
                position: relative;
                img{
                    width: 70px;
                    position: absolute;
                    top: calc(50% - 30px);
                    left: calc(50% - 35px);
                    path{
                        fill: blue !important;
                    }
                }
            }
            .done-message{
                color: black;
                text-align: center;
                font-family: $first-font;
                font-size: 24px;
                display: flex;
                

                &:nth-of-type(2){
                    font-size: 40px;
                    margin-top: 30px;
                }

                &:nth-of-type(3){
                    margin-top: 30px;
                    font-size: 18px;
                    color: #adadad;
                    font-family: $second-font;
                }
                &:nth-of-type(4){
                    font-size: 18px;
                    margin-top: 10px;
                    color: #adadad;
                    font-family: $second-font;
                }
            }
        }
        .error-container{
            display: none;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .round{
                width: 100px;
                height: 100px;
                border-radius: 1000px;
                margin-bottom: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $alert-color;
                img{
                    width: 80px;
                }
            }
            .error-message{
                color: $alert-color;
                text-align: center;
                font-family: $first-font;

                &:first-of-type{
                    margin: 20px;
                }
            }
        }

        .back-btn{
            background-color: #e7e7e7;
            color: black;
            margin-top: 70px;
            &:hover{
                background-color: #d7d7d7;
                box-shadow: unset;
            }
        }

        .return-btn{
            padding: unset;
        }
        .return-btn-home{
            display: none;
            padding: unset;
            @include fadeIn($duration: 0.3s, $function: ease-in-out, $delay: 0.1s);
            img{
                display: none;
            }
        }
        .go-btn-home{
            display: none;
            @include fadeIn($duration: 0.3s, $function: ease-in-out, $delay: 0.1s);
        }
    }
}

