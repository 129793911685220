.body-rejoin-no  {
    background-color: white;
    font-family: "Open Sans";

    /*== HEADER ==*/
    
    header {
        background-color: #F7F7F7;
        margin-bottom: 50px;
    }
    
    header img {
        width: 85px;
    }
    
    @media screen and (max-width:767px) {
        header { margin-bottom: 20px;
            
        }
        header img {
            width:85px;
        }      
    }
    
    h1 {
        font-size: 65px;
        text-align: center;
        margin-bottom: 30px;
        font-family: "Eni";
        font-weight: 500;
    }
    
    h2 {
        font-family: "Eni";
        font-size:32px;
    }
    
    @media screen and (max-width:1199px) {
        h1 {
            font-size: 50px;
        }
    }
    
    @media screen and (max-width:991px) {
        h1 br {
            display: none;
        }
    }

    @media screen and (max-width:767px){
        h1 { font-size: 35px; 
             margin-bottom: 25px;}
    }
    
 
    .intro-bloc {
        display: flex;
        width: 100%;
        margin-bottom: 40px;
        position: relative;
    }
    
    .intro-text {
        flex: 1;
        min-height: 200px;
        background-color: #F7F7F7;
        padding-top: 70px;
        padding-right: 30px;
        position: relative;
        z-index: 20;
        padding-bottom: 25px;
    }
    
    .intro-text p {
        font-family: "Open Sans";
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 1.3;
    }
    
    .intro-bloc h2 {
        margin-bottom: 15px;
    }
    
    .intro-bloc h2 br {
        display: none;
    }
    
    .intro-pic {
        width: 500px;
        min-height: 200px;
        background-image: url("/images/rejoin/intro_pic.jpg");
        background-size: 100%;
        background-color: #F2F2F2;
        background-position: bottom;
        background-repeat: no-repeat;
        position: relative;
        z-index: 20;
    }
    
    .intro-bg {
        width: 400%;
        background-color: #F7F7F7;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
    }
    
    @media screen and (max-width:1199px) {
        .intro-pic {
            width: 300px;
            background-size: 160%;
            background-position: bottom;
        }
    
        .intro-text {
            padding-right: 15px;
            padding-left: 15px;
        }
    
        .intro-bg {
            display: none;
        }
    
        .intro-bloc h2 {
            padding-bottom: 25px;
        }
    
        .intro-bloc h2 br {
            display: none;
        }

        .intro-text p{
            font-size:16px;
        }
    }
    
    @media screen and (max-width:991px) {
        .intro-bloc {
            flex-direction: column-reverse;
        }
    
        .intro-pic {
            width: 100%;
            background-size: 60%;
        }
    
        .intro-bloc h2 {
            margin-bottom: 0;
        }

    }
    
    @media screen and (max-width:767px) {
        .intro-bloc h2 {
            font-size: 30px;
            margin-bottom: 5px;
        }
    
        .intro-text {
            padding-right: 15px;
            padding-left:15px;
            padding-top: 25px;
        }
    
        .intro-pic {
            min-height: 135px;
        }
    }
    
    .button-bloc {
        text-align: center;
        margin-bottom: 60px;
    }
    
    .button-bloc .cta-button {
        display: table;
        margin: 0 auto;
        background-color: #FED416;
        padding: 15px 60px;
        text-decoration: none;
        color: black;
        font-size: 24px;
        border-radius: 29px;
        text-align: center;
        margin-bottom: 30px;
    }
    
    .cta-button:hover{
        text-decoration: none;
        color:black;
        background-color: #FFDE40;
    }
    .button-bloc a {
        color: black;
        text-decoration: underline;
        font-size: 16px;
    }
}