.comparaison-consumption {
    background: #fff;

    font-family: $first-font;

    position: relative;

    > .share-button {
        @extend %share-button;

        position: absolute;
        right: 15px;
        top: 25px;
    }

    > .main-content {        
        display: flex;
        flex-direction: column;
        align-items: center;

        > h2 {
            font-size: 26px;
            margin: 20px 0;
        }

        > .vertical-chart {
            width: 70%;

            > div {
                height: 27px;
                background-color: blue;
            }
        }
    }


}