.ecotroks-win {
    $bg-transparent-color: transparentize($secondary-color, 0.3);
    
    background: linear-gradient($bg-transparent-color, $bg-transparent-color), url("/images/ecotroks-win-bg.png") center;
    background-size: cover;

    text-align: center;

    font-family: $first-font;

    .share-container {
        text-align: right;
        padding: 20px 20px 0 0;
        display: block;
    }

    .share-button {
        @extend %share-button;
    }   

    .title-content {
        color: #fff;
        font-weight: $normal-weight;
        font-size: 2rem;
        margin: 0 0 35px 0;
    }

    .text-content {
        color: #fff;
        text-transform: none;
        margin-bottom: 20px;

        .text-bold {
            font-weight: $bold-weight;
        }
    }

    .ecotorks-win-container {
        display: inline-flex;

        color: #7bd06b;
        font-size: 20px;
        font-weight: $bold-weight;
        
        margin: 20px 0;
        padding: 7px 15px;
        border-radius: 30px;

        background-color: $secondary-color;

        img {
            width: 37px;
            height: 26px;
        }

        .ecotroks-text {
            height: inherit;
            padding: 2px 0 0 10px;
        }
    }
}
