// COLORS

$primary-color: #ffd401;
$secondary-color: #353841;

$primary-font-color: #000;
$primary-color-hover: #FFDE40;
$secondary-color-hover: #44464e;

$black: #353841;


$button-color: #7dc355;
$button-color-hover: #83cc59;

$alert-color: #d60036;

// FONTS

$font-stack: Helvetica, sans-serif;

$first-font: 'Eni', sans-serif;
$second-font: 'Open Sans', sans-serif; // 👼 
$third-font: 'Lobster', cursive;

// FONT WEIGHTS

$light-weight: 100;
$normal-weight: 400;
$bold-weight: 700;

$title-weight: 300;

// SCREEN SIZES
// TODO: NEED TO USE OTHER CONFIG

$big-screen: "only screen and (min-width : 1581px)";
$very-large-screen: "only screen and (min-width : 1921px)";
$little-height: "only screen and (max-height: 550px)"; // Who the fuck uses his browser like that ?
$little-height-new-account: "only screen and (max-height: 850px)"; // Who the fuck uses his browser like that ?
$big-phone-screen: "only screen and (max-device-width: 812px)";
$tablet-screen: "only screen and (min-device-width: 650px)";
$phone : "only screen and (max-device-width: 480px)";

$breakpoints: ("small": (max-width: 480px), "medium": (max-width: 768px), "large": (max-width: 1000px), "huge": (max-width: 1200px));
