@use 'sass:math';

// https://sass-guidelin.es/#responsive-web-design-and-breakpoints
@mixin respond-to($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);
    @if $raw-query {
        $query: if( type-of($raw-query)=='string', unquote($raw-query), inspect($raw-query));
        @media #{$query} {
            @content;
        }
    }
    @else {
        @error 'No value found for `#{$breakpoint}`. '+'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin little-phone {
    @media screen and (max-width: 374px) {
        @content;
    }
}

@mixin phone {
    @media screen and (max-width: 680px) {
        @content;
    }
}

@mixin big-screen {
  @media screen and (min-width: 1581px) {
      @content;
  }
}

@mixin very-large-screen {
  @media screen and (min-width: 1921px) {
      @content;
  }
}

@mixin limit-screen {
  @media screen and (min-width: 1000px) and (max-width: 1390px){
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 1000px) and  (min-width: 680px){
      @content;
  }
}

/*TEST flex fix firefox*/

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before{
      display: block;
      content: " ";
      width: 100%;
      padding-top: math.div($height, $width) * 100%;
  }

  > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
  }
}
