/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.homemade-burgermenu {
    position: relative;
    overflow: hidden;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    hr.burger {
        background-color: black;
        width: 35px;
        height: 4px;
        border: none;
    }
    .top-hr {
        // margin-bottom: -10px;
        transform: rotate(0deg);
        margin-bottom: 0px;
        // margin: 0;
    }
    .middle-hr {
        margin: 6px 0;

    }
    .bottom-hr {
        margin-top: 0px;
        transform: rotate(0deg); // margin: 0;

    }
    &:hover {
        cursor: pointer;
        // hr.burger {
        //     background-color: rgb(75, 74, 74);
        // }
    }
}

@keyframes crossBounceUp {
    0% {
        transform: rotate(0);
        margin-bottom: 0px;
    }
    10% {
        margin-bottom: -10px;
    }
    40% {
        transform: rotate(-15deg);
    }
    50% {
        transform: rotate(55deg);
    }
    60% {
        transform: rotate(35deg);
    }
    70% {
        transform: rotate(50deg);
    }
    80% {
        transform: rotate(40deg);
    }
    90% {
        transform: rotate(48deg);
    }
    100% {
        transform: rotate(45deg);
        margin-bottom: -10px;
    }
}

@keyframes burgerBounceUp {
    0% {
        transform: rotate(45deg);
        margin-bottom: -10px;
    }
    40% {
        transform: rotate(60deg);
    }
    50% {
        transform: rotate(40deg);
    }
    60% {
        transform: rotate(50deg);
    }
    
    90% {
        margin-bottom: -10px;
    }
    100% {
        transform: rotate(0deg);
        margin: 0px;
    }
}

@keyframes crossBounceDown {
    0% {
        transform: rotate(0);
        margin-top: 0px;
    }
    10% {
        margin-top: -10px;
    }
    40% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(-55deg);
    }
    60% {
        transform: rotate(-35deg);
    }
    70% {
        transform: rotate(-50deg);
    }
    80% {
        transform: rotate(-40deg);
    }
    90% {
        transform: rotate(-48deg);
    }
    100% {
        transform: rotate(-45deg);
        margin-top: -10px;
    }
}

@keyframes burgerBounceDown {
    0% {
        transform: rotate(-45deg);
        margin-top: -10px;
    }
    40% {
        transform: rotate(-60deg);
    }
    50% {
        transform: rotate(-40deg);
    }
    60% {
        transform: rotate(-50deg);
    }
    
    90% {
        transform: rotate(15deg);
        margin-top: -10px;
    }
    95% {
        margin-top: -10px;
    }
    100% {
        transform: rotate(0deg);
        margin: 0px;
    }
}

@keyframes middleHrDisappear {
    0% {
        display: inherit;
    }
    10% {
        background-color: transparent;
    }
    100%{
        background-color: transparent;
    }
}

@keyframes middleHrShow {
    0% {
        display: inherit;
        background-color: transparent;
    }
    95% {
        background-color: transparent;
    }
    100%{
        background-color: black;
    }
}