/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

/* VARIABLES DEFINITIONS */

$big-screen: "only screen and (min-width : 1581px)";
$little-height: "only screen and (max-height: 550px)";
$big-phone-screen: "only screen and (max-device-width: 812px)";
$tablet-screen: "only screen and (min-device-width: 650px)";

.body-maintenance {
    background-color: white;

    .button {
        background-color: $primary-color;
        padding: 10px 120px;
        border-radius: 100px;
        transition: .2s ease-in-out;
        font-family: $second-font;
        margin-bottom: 50px;
        font-size: 18px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: $primary-color-hover;
            cursor: pointer;
        }

        @include phone {
            font-size: 16px;
            padding: 8px 100px;
        }
    }

    @media screen and (min-width: 812px) {
        .container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            padding: inherit;
        }

        .modal {
            display: none;
            position: fixed;
            padding-top: 100px;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            z-index: 1000;

            .dark-background {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.3);
            }

            .modal-content {
                text-align: center;
                background-color: #fefefe;
                margin: auto;
                padding: 20px;
                font-family: $second-font;
                font-weight: 300;
                font-size: 14px;
                width: 35%;
                box-shadow: 0px 0px 36px -10px rgba(0, 0, 0, 0.25);
                z-index: 1200;
                position: relative;

                .close {
                    position: absolute;
                    top: 20px;
                    right: 40px;
                    font-size: 25px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                > h2 {
                    margin-top: 10px;
                    font-size: 20px;
                    font-weight: $title-weight;
                }

                @include bounceInDown($duration: 0.5s, $function: ease);
            }

            .contact-icon {
                width: 100px;
                height: 100px;
            }

            .decr {
                margin-top: 20px;
                font-family: $second-font;
                line-height: 26px;
                font-size: 16px;
            }

            .number {
                font-size: 1.3em;
                font-weight: bold;
            }
        }

        .left-sign-in {
            width: 50%;
            height: 100vh;
            background-image: url("../../../images/login_bg.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            background-position: center;

            .logo-eni {
                width: 100px;
                position: relative;
                margin-left: 10vh;
                margin-top: 5vh;

                @media #{$big-screen} {
                    width: 150px;
                }

                @media #{$big-phone-screen} {
                    width: 100px;
                }
            }

            .slogan-middle {
                position: absolute;
                text-align: center;
                bottom: 50px;
                width: 50%;

                .slogan {
                    color: white;
                    font-family: $second-font;
                    font-weight: 600;
                    font-size: 20px;

                    @media #{$big-screen} {
                        font-size: 24px;
                    }
                }
            }
        }

        .right-sign-in {
            width: 50%;
            height: 100vh;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include fadeIn($duration: 0.2s, $function: ease-in-out);

            .sub-container {
                margin-top: 50px;
                background-color: white;
                text-align: center;
                margin-bottom: 80px;

                @media #{$little-height} {
                    overflow-y: scroll;
                    margin-top: 50px;
                }

                .title-log {
                    font-family: $second-font;
                    font-size: 32px;
                    font-weight: 300;
                    color: black;
                    margin-top: 15px;

                    @media #{$big-screen} {
                        font-size: 48px;
                    }
                }

                .auxiliary-title {
                    margin-top: 15px;
                    padding: 0 15px;
                    font-family: $second-font;
                    font-size: 26px;
                    font-weight: 300;
                    color: black;

                    @media #{$big-screen} {
                        font-size: 26px;
                    }
                }
            }

            .footer-links {
                bottom: 0;
                position: fixed;
                width: 50%;
                align-items: center;
                display: flex;
                justify-content: flex-end;
                background-color: #f7f7f7;
                height: 50px;
                font-family: $second-font;
                font-size: 16px;
                color: black;
                font-weight: 300;

                @media #{$big-screen} {
                    font-size: 16px;
                    margin-top: 65px;
                    height: 70px;
                }

                a {
                    margin-right: auto;
                    margin-left: 10px;
                    font-size: 12px;
                    color: inherit;
                    cursor: pointer;
                }

                .gp {
                    text-align: right;
                    width: 45%;
                    margin-right: 10px;

                    @media #{$big-screen} {
                        bottom: 25px;
                    }

                    .slogan {
                        font-size: 12px;
                        font-weight: 300;
                        color: black;
                        font-family: $second-font;

                        .gp-title {
                            font-weight: 400;
                            color: black;
                            text-decoration: none;
                            margin-left: -1px;
                        }
                    }
                }
            }

            .legal > p {
                margin-top: 30px;
                padding: 0 20px;
                font-family: "Eni", sans-serif;
                font-size: 12px;
                color: #888;
                text-align: center;

                @media #{$big-screen} {
                    bottom: 75px;
                }

                > a {
                    color: black;
                    margin: 0px 2px;
                    text-decoration: none;

                    :visited, :active {
                        text-decoration: none;
                    }
                }
            }
        }

        .container-mobile * {
            display: none;
        }
    }

    @media screen and (max-width: 811px) {
        * {
            orientation: landscape;
        }

        .container * {
            display: none;
        }

        .container-mobile {
            height: 100vh;
            background-image: url("../../../images/login_bg_mobile.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            overflow: scroll;
            background-position: top;

            .mobile-footer {
                width: 100%;
                color: white;
                font-size: 12px;
                font-family: 'Eni';
                text-align: center;
                margin-top: 30px;

                a, a:visited, a:active {
                    color: white;
                    text-decoration: none;
                }
                div p {
                    padding: 0 15px;
                }

                .slogan-container {
                    margin-top: 50px;
                    text-align: right;
                    margin-right: 20px;
                }
            }

            .gp {
                position: absolute;
                text-align: center;
                bottom: 18px;
                right: 10px;

                @media #{$big-screen} {
                    bottom: 25px;

                    .slogan {
                        font-size: 16px;
                    }
                }

                .slogan {
                    font-size: 12px;
                    font-weight: 300;
                    color: white;
                    font-family: $second-font;

                    @media #{$big-screen} {
                        font-size: 16px;
                    }

                    .gp-title {
                        font-weight: 400;
                        color: white;
                        text-decoration: none;
                        margin-left: -1px;
                    }
                }
            }

            .logo-eni {
                width: 100px;
                position: relative;
                margin-left: 7vh;
                margin-top: 5vh;

                @media #{$tablet-screen} {
                    width: 120px;
                }

                @include little-phone {
                    width: 60px;
                }
            }

            .sub-container {
                text-align: center;

                h1 {
                    color: white;
                }

                .auxiliary-title {
                    font-size: 20px;
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
        }
    }

    @keyframes bounce {
        0%,
        55%,
        80%,
        100% {
            transform: translateX(0);
        }

        20% {
            transform: translateX(8px);
        }

        30% {
            transform: translateX(-2px);
        }

        45% {
            transform: translateX(1px);
        }

        55% {
            transform: translateX(-2px);
        }
    }

    .shadow {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(white, 0.4);
        width: 100%;
        height: 100%;
        display: none;
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 1;
        padding-top: 15%;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        text-align: center;

        .modal-content {
            background-color: white;
            box-shadow: 0px 0px 36px -10px rgba(0, 0, 0, 0.5);
            color: black;
            margin: auto;
            padding: 5px 10px 50px 10px;
            border-radius: 3px;
            font-family: "Eni", sans-serif !important;
            width: 50%;

            .button {
                font-family: $second-font;
                border-radius: 50px;
                position: relative;
                width: auto;
                font-size: 15px;
                background-color: $primary-color;
                height: 40px;
                justify-content: center;
                align-items: center;
                text-align: center;
                transition: 0.25s ease-in-out;
                outline: none;
                border: 0px;
                margin-top: 10px;

                @media #{$tablet-screen} {
                    width: 250px;
                    height: 45px;
                }

                &:hover {
                    background-color: $primary-color-hover;
                    cursor: pointer;
                }

                .arrow-right {
                    width: 25px;
                    position: absolute;
                    margin-left: 20px;
                    margin-top: -2px;
                    animation: bounce 2s infinite;

                    @media #{$tablet-screen} {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

.cookies-present {
    margin-top: 10%;
}
