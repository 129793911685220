/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

/* VARIABLES DEFINITIONS */

$big-screen: "only screen and (min-width : 1581px)";
$little-height: "only screen and (max-height: 550px)";
$big-phone-screen: "only screen and (max-device-width: 812px)";
$tablet-screen: "only screen and (min-device-width: 650px)";

.body-signin {
    background-color: white;

    @media screen and (min-width: 812px) {
        .container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            padding: inherit;
        }

        .left-sign-in {
            width: 50%;
            height: 100vh;
            background-image: url("../../../images/login_bg.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            background-position: center;

            .logo-eni {
                width: 100px;
                position: relative;
                margin-left: 10vh;
                margin-top: 5vh;

                @media #{$big-screen} {
                    width: 150px;
                }

                @media #{$big-phone-screen} {
                    width: 100px;
                }
            }

            .slogan-middle {
                position: absolute;
                text-align: center;
                bottom: 50px;
                width: 50%;

                .slogan {
                    color: white;
                    font-family: $second-font;
                    font-weight: 600;
                    font-size: 20px;

                    @media #{$big-screen} {
                        font-size: 24px;
                    }
                }
            }
        }

        .right-sign-in {
            width: 50%;
            height: 100vh;
            background-color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @include fadeIn($duration: 0.2s, $function: ease-in-out);

            .sub-container {
                margin-top: 50px;
                background-color: white;
                text-align: center;
                margin-bottom: 80px;

                @media #{$little-height} {
                    overflow-y: scroll;
                    margin-top: 50px;
                }

                .title-log {
                    font-family: $second-font;
                    font-size: 32px;
                    font-weight: 300;
                    color: black;
                    margin-top: 15px;

                    @media #{$big-screen} {
                        font-size: 48px;
                    }
                }

                .auxiliary-title {
                    margin-top: 15px;
                    padding: 0 15px;
                    font-family: $second-font;
                    font-size: 26px;
                    font-weight: 300;
                    color: black;

                    @media #{$big-screen} {
                        font-size: 26px;
                    }
                }

                form {
                    margin-top: 100px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    input {
                        width: 240px; //    height: 35px;
                        border-radius: 50px;
                        border: none;
                        background-color: #eeeeee;
                        padding: 10px 15px;
                        font-family: $second-font;
                        margin-bottom: 20px;
                        outline: none;
                        transition-duration: 0.24s;
                        font-size: 14px;

                        @media #{$big-screen} {
                            font-size: 20px;
                            width: 350px; // height: 48px;
                        }

                        &:focus::-webkit-input-placeholder {
                            opacity: 0.5;
                        }

                        &::placeholder {
                            color: black;
                        }

                        &:hover {
                            background-color: #e6e6e6;
                        }
                    }

                    div {
                        padding: 10px;
                        color: #a94442;
                        display: none;
                    }

                    .error_password {
                        padding: 10px;
                        font-size: 15px;
                        font-family: $second-font;
                    }

                    #field_password {
                        margin-bottom: 0px;
                    }

                    button {
                        justify-content: center;
                        font-family: $second-font;
                        position: relative;
                        margin-top: 50px;
                        width: 270px;
                        height: 35px;
                        border-radius: 50px;
                        border: 0px;
                        background-color: $primary-color;
                        outline: none;
                        font-size: 14px;
                        transition: 0.25s ease-in-out;
                        padding: 0;
                        cursor: pointer;

                        .arrow-right {
                            width: 20px;
                            position: absolute;
                            top: calc((100% - 20px)/2);
                            left: 85%;

                            @media #{$big-screen} {
                                width: 30px;
                                left: 85%;
                                top: calc((100% - 30px)/2);
                            }
                        }

                        @media #{$big-screen} {
                            font-size: 20px;
                            width: 380px;
                            height: 48px;
                        }

                        &:hover {
                            box-shadow: inset 20em 0em $primary-color-hover;
                            cursor: pointer;

                            .arrow-right {
                                animation: bounce 2s infinite;
                            }
                        }
                    }

                    @media #{$big-screen} {
                        margin-top: 150px;
                    }
                }

                .disabled-user {
                    display: none;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 50px;

                    .error-icon-container {
                        width: 100px;
                        height: 100px;
                        border-radius: 1000px;
                        margin-bottom: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $alert-color;

                        img {
                            width: 80px;
                        }
                    }

                    .text-disabled {
                        font-size: 18px;
                        width: 50%;
                        margin-top: 16px;
                        font-family: $second-font;

                        &:nth-of-type(2) {
                            margin-top: 0;
                            font-size: 24px;
                        }
                    }

                    .prev-btn {
                        margin-top: 50px;
                    }
                }

                .pwd-signup-container {
                    font-family: $second-font;
                    font-size: 12px;
                    color: black;
                    margin-top: 45px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    font-weight: 300;

                    @media #{$big-screen} {
                        font-size: 16px;
                        margin-top: 65px;
                    }

                    a {
                        color: inherit;
                        margin-left: 10px;
                        margin-right: 10px;
                        font-size: 14px;
                    }

                    @media #{$little-height} {
                        margin-bottom: 100px;
                    }
                }
            }

            .footer-links {
                bottom: 0;
                position: fixed;
                width: 50%;
                align-items: center;
                display: flex;
                justify-content: flex-end;
                background-color: #f7f7f7;
                height: 50px;
                font-family: $second-font;
                font-size: 16px;
                color: black;
                font-weight: 300;

                @media #{$big-screen} {
                    font-size: 16px;
                    margin-top: 65px;
                    height: 70px;
                }

                a {
                    margin-right: auto;
                    margin-left: 10px;
                    font-size: 12px;
                    color: inherit;
                    cursor: pointer;
                }

                .gp {
                    text-align: right;
                    width: 45%;
                    margin-right: 10px;

                    @media #{$big-screen} {
                        bottom: 25px;
                    }

                    .slogan {
                        font-size: 12px;
                        font-weight: 300;
                        color: black;
                        font-family: $second-font;

                        .gp-title {
                            font-weight: 400;
                            color: black;
                            text-decoration: none;
                            margin-left: -1px;
                        }
                    }
                }
            }

            .legal > p {
                margin-top: 30px;
                padding: 0 20px;
                font-family: "Eni", sans-serif;
                font-size: 12px;
                color: #888;
                text-align: center;

                @media #{$big-screen} {
                    bottom: 75px;
                }

                > a {
                    color: black;
                    margin: 0px 2px;
                    text-decoration: none;

                    :visited, :active {
                        text-decoration: none;
                    }
                }
            }
        }

        .container-mobile * {
            display: none;
        }
    }

    @media screen and (max-width: 811px) {
        * {
            orientation: landscape;
        }

        .container * {
            display: none;
        }

        .container-mobile {
            min-height: 100vh;
            position: relative;
            background-image: url("../../../images/login_bg_mobile.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            overflow: scroll;
            background-position: top;

            .container-mobile-wrapper {
                margin-bottom: 30px;
                padding-bottom: 80px;
                @include phone {
                    padding-bottom: 210px;
                }
            }

            .mobile-footer {
                width: 100%;
                color: white;
                font-size: 12px;
                font-family: 'Eni';
                text-align: center;
                margin-top: 30px;

                a, a:visited, a:active {
                    color: white;
                    text-decoration: none;
                }
                div p {
                    padding: 0 15px;
                }

                .slogan-container {
                    margin-top: 50px;
                    text-align: right;
                    margin-right: 20px;
                }
            }

            .gp {
                position: absolute;
                text-align: center;
                bottom: 18px;
                right: 10px;

                @media #{$big-screen} {
                    bottom: 25px;

                    .slogan {
                        font-size: 16px;
                    }
                }

                .slogan {
                    font-size: 12px;
                    font-weight: 300;
                    color: white;
                    font-family: $second-font;

                    @media #{$big-screen} {
                        font-size: 16px;
                    }

                    .gp-title {
                        font-weight: 400;
                        color: white;
                        text-decoration: none;
                        margin-left: -1px;
                    }
                }
            }

            .logo-eni {
                width: 100px;
                position: relative;
                margin-left: 7vh;
                margin-top: 5vh;

                @media #{$tablet-screen} {
                    width: 120px;
                }

                @include phone {
                    width: 80px;
                }
            }

            .sub-container {
                text-align: center;

                form {
                    margin-top: 24vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @include tablet {
                        margin-top: 10vh;
                    }

                    @include phone {
                        margin-top: 10vh;
                    }


                    input {
                        width: 280px;
                        border-radius: 50px;
                        border: none;
                        font-size: 14px;
                        background-color: white;
                        padding: 15px;
                        font-family: $second-font;
                        margin-bottom: 20px;
                        outline: none;
                        transition-duration: 0.24s;

                        &::placeholder {
                            color: black;
                        }

                        @media #{$tablet-screen} {
                            width: 330px;
                        }

                        @include little-phone {
                            width: calc(100% - 80px);
                        }
                    }

                    .error_password {
                        padding: 10px 40px;
                        font-size: 15px;
                        background-color: rgba(white, 0.9);
                        border-radius: 100px;
                        color: $alert-color;
                        display: none;
                        max-width: calc(100% - 40px);
                        font-family: $second-font;
                    }

                    button {
                        font-family: $second-font;
                        position: relative;
                        margin-top: 70px;
                        width: 280px;
                        height: 40px;
                        border-radius: 50px;
                        border: 0px;
                        background-color: $primary-color;
                        padding: 0.5em;
                        outline: none;
                        transition: 0.25s ease-in-out;
                        font-size: 14px;

                        @media #{$tablet-screen} {
                            margin-top: 70px;
                            width: 330px;
                            height: 45px;
                        }

                        .arrow-right {
                            width: 25px;
                            position: absolute;
                            margin-left: 40px;
                            margin-top: -2px;
                            animation: bounce 2s infinite;

                            @media #{$tablet-screen} {
                                margin-left: 50px;
                            }
                        }

                        @include phone {
                            margin-top: 20px;
                        }
                    }
                }

                .pwd-signup-container-mobile {
                    font-family: $second-font;
                    font-size: 14px;
                    color: black;
                    margin-top: 45px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    text-decoration: underline;
                    font-weight: 300;
                    color: white;

                    @media #{$tablet-screen} {
                        font-size: 18px;
                    }
                    
                    @include phone {
                        flex-direction: column;
                        margin-top: 20px;
                    }

                    a {
                        color: inherit;
                        margin-left: 20px;
                        margin-right: 20px;

                        @include phone {
                            margin-left: 0;
                            margin-right: 0;
                            margin-bottom: 10px;
                            font-size: 14px;
                        }
                    }
                }

                .disabled-user-mobile {
                    display: none;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin-top: 50px;

                    @include little-phone {
                        margin-top: 0;
                    }


                    .error-icon-container {
                        width: 100px;
                        height: 100px;
                        border-radius: 1000px;
                        margin-bottom: 50px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $alert-color;

                        img {
                            width: 80px;
                        }
                    }

                    .text-disabled {
                        color: white;
                        font-size: 18px;
                        width: 50%;
                        margin-top: 16px;
                        font-family: $second-font;

                        @include little-phone {
                            font-size: 15px;
                        }


                        &:nth-of-type(2) {
                            margin-top: 0;
                            font-size: 24px;

                            @include little-phone {
                                font-size: 18px;
                            }
                        }
                    }

                    .prev-btn {
                        margin-top: 50px;
                    }
                }
            }

            footer {
                position: absolute;
                @include phone {
                    height: 210px;
                }
            }
        }
    }

    @keyframes bounce {
        0%,
        55%,
        80%,
        100% {
            transform: translateX(0);
        }

        20% {
            transform: translateX(8px);
        }

        30% {
            transform: translateX(-2px);
        }

        45% {
            transform: translateX(1px);
        }

        55% {
            transform: translateX(-2px);
        }
    }

    .shadow {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(white, 0.4);
        width: 100%;
        height: 100%;
        display: none;
    }
}

.cookies-present {
    margin-top: 10%;
}
