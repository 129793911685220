/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.checkbox-container {
    width: 70%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    > label {
        font-family: $first-font;
        font-size: 14px;
        margin-left: 10px;
        text-align: left;
        width: 90%;
        font-weight: 300;

        @media #{$big-screen} {
            font-size: 16px;
        }

        &:hover{
            cursor: pointer;
        }
    }

    > input {
        -webkit-appearance: none;
        border: 2px solid $primary-color;
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        transition: .1s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            cursor: pointer;
        }

        &:active, &:checked {
            border: 5px solid $primary-color;
        }

        &:checked {
            background-color: $primary-color;
        }

        &:checked:after {
            content: "\f00c";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            font-style: normal;
            text-decoration: inherit;
            font-size: 21px;
            color: black;
            @include pulse($duration: 0.2s);
        }
    }
}

/** 
<div class="checkbox-container"> 
    <input type="checkbox">
    <label>J'accepte les conditions d'utilisation</label>
</div>
*/
