/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.admin-movingclients {
    .moving-badge {
        background: #eee;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin: 5px;
        display: block;
    }

    .moving-badge-orange {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin: 5px;
        background: orange;
    }

    .moving-badge-red {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin: 5px;
        background: red;
    }

    .btn-csv {
        width: 100px;
    }
}