/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.body-consumption {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
    font-family: $second-font;

    @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0s);

    .energy-type-switcher-container {
        display: none;
        align-content: center;
        justify-content: center;

        button:first-child {
            margin-right: 10px;
        }

        a {
            text-decoration: none;
        }

        button {
            transition: border-bottom 0.2s;
            display: flex;
            width: 150px;
            background: #fff;
            height: 40px;
            cursor: pointer;

            padding-bottom: 10px;

            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 4px solid #0fa2c0;

            justify-content: center;
            align-content: center;

            .icon-elec, .icon-gas {
                transition: background-color 0.2s;
                height: 30px;
                width: 30px;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-position: center;
                mask-position: center;
            }


            .icon-elec {
                background-color: #ffd400;
                -webkit-mask-image: url(/images/icons/elec_icon.svg);
                mask-image: url(/images/icons/elec_icon.svg);
            }

            .icon-gas {
                background-color: #0fa2c0;
                -webkit-mask-image: url(/images/icons/gas_icon.svg);
                mask-image: url(/images/icons/gas_icon.svg);
            }

            > .text {
                font-family: $first-font;
                font-size: 20px;
                margin: 5px 0 0 10px;
            }
        }

        .elec {
            border-bottom: 4px solid #ffd400;
        }

        .disabled-bloc {


            > button {
                border-bottom: 2px solid #e1e1e1;

                .icon-elec, .icon-gas{
                    background-color: #e1e1e1;
                }
            }

            &:hover {
                .elec {
                    border-bottom: 4px solid #ffd400;
                }

                .icon-elec{
                    background-color: #ffd400;
                }

                .gas {
                    border-bottom: 4px solid #0fa2c0;
                }

                .icon-gas{
                    background-color: #0fa2c0;
                }
            }

        }
    }

    .consumption-container {
        flex: 1;
        padding-top: 20px;

        .title {
            margin-top: 20px;
            margin-bottom: 8px;
            font-weight: 500;
            text-align: center;

            @include phone {
                justify-content: center;
                align-content: center;
                text-align: center;
            }
        }

        .no-data, .loading {
            display: none;
        }

        .no-accept-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .no-accept-enedis, .no-accept-grdf {
                width: 100%;
                display: none;
                align-items: center;
                justify-content: center;
                margin-top: 60px;
                //background-color: #F7F7F7;
                max-width: 1360px;

                //background: url('images/consumption/warning.svg') blue;

                background: url(/images/consumption/warning.svg) #F7F7F7 no-repeat;
                background-size: 30%;
                background-position: -5%;

                @include tablet {
                    background: #F7F7F7;
                }

                @include phone {
                    background: #F7F7F7;
                    flex-direction: column;
                    padding-top: 50px;
                    text-align: center;
                }

                .wrapper {
                    @include tablet {
                        width: 100%;
                    }

                    width: 80%;
                    display: flex;
                    align-items: center;

                    > .message-container {
                        padding: 0 25%;
                        font-size: 18px;
                        z-index: 1;

                        @include limit-screen {
                            font-size: 18px;
                            padding: 0 15%;
                        }

                        @include tablet {
                            font-size: 16px;
                            padding: 0 10%;
                        }

                        @include phone {
                            padding: 0 5%;
                        }
                    }

                }
                > img {
                    width: 100%;
                    max-width: 450px;
                    height: auto;

                    @include phone {
                        display: none;
                    }
                }
            }
        }

        .no-data-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .no-data-notice {
                width: 100%;
                display: none;
                align-items: center;
                justify-content: center;
                margin-top: 50px;
                margin-bottom: 50px;
                max-width: 1360px;

                background: url(/images/consumption/warning.svg) #F7F7F7 no-repeat;
                background-size: 30%;
                background-position: -5%;

                .image-container {
                    display: none;
                    text-align: center;
                }

                @include tablet {
                    background: #F7F7F7;
                }

                .btn-profile {
                    width: 300px;
                }

                .wrapper {
                    @include tablet {
                        width: 100%;
                    }

                    width: 80%;
                    display: flex;
                    align-items: center;

                    > .message-container {
                        padding: 0 25%;
                        font-size: 20px;
                        z-index: 1;

                        @include limit-screen {
                            font-size: 18px;
                            padding: 0 15%;
                        }

                        @include tablet {
                            font-size: 16px;
                            padding: 0 10%;
                        }

                        @include phone {
                            padding: 0 5%;
                        }
                    }

                }

                > img {
                    width: 100%;
                    max-width: 450px;
                    height: auto;

                    @include phone {
                        display: none;
                    }

                    @include little-phone {
                        width: 100%;
                    }
                }


                @include phone {
                    background: #F7F7F7;
                    flex-direction: column;
                    padding-top: 50px;
                    margin-top: 15px;

                    .btn-profile {
                        width: 230px;
                    }

                    .image-container {
                        display: block;
                    }
                }
            }
        }

        .no-erl-container {

          display: flex;
          align-items: center;
          justify-content: center;

          .no-erl-notice {
              width: 100%;
              padding: 40px;
              display: none;
              align-items: center;
              justify-content: center;
              margin-bottom: 50px;
              max-width: 1360px;
              background: #F7F7F7;

            @include phone {
                flex-direction: column;
                padding-top: 50px;
                text-align: center;
            }

            .wrapper {
                @include tablet {
                    width: 100%;
                }

                display: flex;
                align-items: center;

                b {
                  font-weight: bolder;
                }

                > .message-container {
                    font-size: 18px;

                    p{
                      margin-bottom: 30px;
                      line-height: 25px;
                    }

                    .svg{
                      width: 230px;
                      @include phone {
                        width: 40%;
                        height: 40%;
                      }
                      margin-bottom: 20px;
                    }

                    .links{
                      margin-top: 20px;
                      display: flex;
                      justify-content: center;
                      gap: 5%;
                      .store-link{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 10px;
                        img{
                          max-width: 150px;
                          @include phone {
                            max-width: 120px;
                          }
                          &.qr{
                            max-width: 200px;

                            @include phone {
                              display: none;
                            }
                          }
                        }
                      }
                    }


                    @include limit-screen {
                        font-size: 18px;
                        padding: 0 15%;
                    }

                    @include tablet {
                        font-size: 16px;
                        padding: 0 10%;
                    }

                    @include phone {
                        padding: 0 5%;
                    }
                }

            }

          }
        }

        .no-real-time-data-container {

          display: flex;
          align-items: center;
          justify-content: center;

          .no-real-time-data-notice {
              width: 100%;
              padding: 40px;
              display: none;
              align-items: center;
              justify-content: center;
              margin-bottom: 50px;
              max-width: 1360px;


            @include phone {
                flex-direction: column;
                text-align: center;
                padding: 0;
            }

            .wrapper {
              width: 100%;
              justify-content: center;
              background: #F7F7F7;
              padding: 20px 0;
              display: flex;
              align-items: center;
              @include phone {
                padding: 20px 0 0 0;
              }

              .message-container {
                width: 50%;
                  font-size: 18px;

                  p{
                    margin-bottom: 30px;
                  }

                  .svg{
                    width: 230px;
                    @include phone {
                      width: 40%;
                      height: 40%;
                    }
                    margin-bottom: 20px;
                  }


                  @include limit-screen {
                    font-size: 18px;
                    padding: 0 15%;
                  }

                  @include tablet {
                    font-size: 16px;
                    padding: 0 10%;
                  }

                  @include phone {
                    width: 100%;
                    padding: 0 5%;
                  }
              }
            }

            .troubleshooting {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              @include phone {
                background-color: #F7F7F7;
              }
              .steps {
                width: 80%;
                display: flex;
                flex-direction: column;
                row-gap: 15px;
                margin-top: 20px;

                @include phone {
                  width: 92%;
                }
                .step {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 20px;
                  text-align: left;
                  @include phone {
                    flex-direction: column;
                    gap: 0;
                  }

                  .step-text{
                    @include phone {
                      background-color: white;
                      font-size: 14px;
                      text-align: center;
                      padding: 20px 14px;
                      padding-top: 35px;
                    }

                  }

                  .img-container {
                    padding: 20px;
                    border-radius: 50%;
                    background-color: #ffd400;
                    @include phone {
                      padding: 10px;
                      margin-bottom: -35px;
                      z-index: 10;
                      border: 8px solid white;
                    }

                    img{
                      width: 35px;
                    }
                  }

                }
              }
              .links{
                margin-top: 20px;
                display: flex;
                justify-content: center;
                gap: 5%;
                .store-link{
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  gap: 10px;
                  img{
                    max-width: 150px;
                    @include phone {
                      max-width: 120px;
                    }
                    &.qr{
                      max-width: 200px;

                      @include phone {
                        display: none;
                      }
                    }
                  }
                }
              }
            }

          }
        }

        .btn-profile {
            margin: 10% 0;
            background-color: $primary-color;
            border: none;
            padding: 7px 35px;
            width: 200px;
            border-radius: 40px;
            text-decoration: none;
            color: black;
            transition: .2s ease-in-out;
            font-size: 14px;
            cursor: pointer;
            z-index: 1;

            &:hover {
                background-color: $primary-color-hover;
            }
        }

        .loading {
            display: flex;
            height: 55px;
        }

        .main-consumption {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .chart-consumption {
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 1360px;
                width: 100%;
                height: 100%;
                flex-direction: column;
                text-align: center;

                .button-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    max-width: 500px;
                    z-index: 6;


                    @include tablet {
                        width: 100%;
                        justify-content: center;
                    }


                    @include phone {
                        justify-content: space-evenly;
                        padding: 0 5px;
                    }


                    @include little-phone {
                        justify-content: center;
                        width: 100%;
                    }


                    &.centered {
                        justify-content: center;
                    }

                    #change-display-btn {
                        line-height: 28px;

                        img {
                            display: inline-block;
                            position: relative;
                            top: 4px;
                            width: 20px;
                            height: 20px;
                        }
                    }

                    > button {
                        background-color: #e7e7e7;
                        padding: 6px 22px;
                        border-radius: 40px;
                        font-weight: 400;
                        font-size: 12px;
                        margin: 16px 10px;
                        border: none;
                        outline: none;
                        transition: .2s ease-in-out;

                        &:hover {
                            background-color: #d4d4d4;
                            cursor: pointer;
                        }


                        @include phone {
                            padding: 7px 14px;
                            font-size: 12px;
                            margin: 14px 5px;
                        }


                        @include little-phone {
                            padding: 7px 15px;
                            font-size: 12px;
                        }
                    }
                }

                .day-choice-container {
                    display: flex;
                    margin-top: 20px;
                    position: relative;
                    width: 100%;
                    justify-content: center;
                    z-index: 10;

                    .current-date {
                        font-size: 20px;
                        margin: 0 50px;
                        align-self: center;

                        @include phone {
                            padding: 0 15px;
                            font-size: 21px;
                        }

                        @include little-phone {
                            padding: 0 15px;
                            font-size: 14px;
                        }
                    }

                    .left-arrow {
                        width: 20px;
                        height: 100%;
                        background: url("/images/icons/arrow_carousel_black.png");
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                        cursor: pointer;
                        border: none;
                        transform: rotate(180deg);
                        position: absolute;
                        top: 0;
                        left: 28%;

                        @include limit-screen {
                            left: calc(28% - 50px);
                        }


                        @include tablet {
                            left: calc(28% - 150px);
                            // background-color: #e7e7e7;
                            // border: 5px solid #e7e7e7;
                            border-radius: 1000px;
                            width: 40px;
                            height: 40px;
                            background-position: center;
                            background-size: 12px;
                            margin-top: -10px;
                        }


                        @include phone {
                            left: 20px;
                            // background-color: #e7e7e7;
                            // border: 5px solid #e7e7e7;
                            border-radius: 1000px;
                            width: 40px;
                            height: 40px;
                            background-position: center;
                            background-size: 12px;
                            margin-right: 10px;
                        }
                    }

                    .right-arrow {
                        width: 20px;
                        height: 100%;
                        background: url("/images/icons/arrow_carousel_black.png");
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                        cursor: pointer;
                        border: none;
                        position: absolute;
                        top: 0;
                        right: 28%;

                        @include limit-screen {
                            right: calc(28% - 50px);
                        }


                        @include tablet {
                            right: calc(28% - 150px);
                            // background-color: #e7e7e7;
                            // border: 5px solid #e7e7e7;
                            border-radius: 1000px;
                            width: 40px;
                            height: 40px;
                            background-position: center;
                            background-size: 12px;
                            margin-top: -10px;
                        }


                        @include phone {
                            right: 20px;
                            // background-color: #e7e7e7;
                            // border: 5px solid #e7e7e7;
                            border-radius: 1000px;
                            width: 40px;
                            height: 40px;
                            background-position: center;
                            background-size: 12px;
                            margin-left: 10px;
                        }
                    }
                }

                .chart-consumption-wrapper {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    overflow: hidden;
                    position: relative;
                    justify-content: center;

                    @include limit-screen {
                        justify-content: center; //background-color: lightpink;
                        width: inherit;
                        margin-top: -30px;
                    }


                    @include tablet {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-top: -40px;
                    }


                    @include phone {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-top: -40px;
                    }


                    .bar-chart-button {
                        position: absolute;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        z-index: 6;
                        top: 50px;
                        left: 0;

                        &:hover {
                            cursor: pointer;
                            color: #353841;
                        }

                        &.hide {
                            display: none;
                        }

                        @include limit-screen {
                            top: 50px;
                            left: 70px;
                        }


                        @include tablet {
                            top: 80px;
                            left: 20px;
                        }


                        @include phone {
                            top: 80px;
                            left: 20px;
                        }


                        @include little-phone {
                            top: 50px;
                            left: 20px;
                        }


                        .btn-bar-chart-text {
                            font-size: 14px;
                            text-decoration: underline;

                            @include phone {
                                display: none;
                            }
                        }

                        .circle-icon + .chart-icon {
                            .bar-container {
                                display: none;
                            }

                            .circle-container {
                                display: flex;
                            }
                        }

                        .bar-icon + .chart-icon {
                            .circle-container {
                                display: none;
                            }

                            .bar-container {
                                display: flex;
                            }
                        }

                        .circle-icon:after {
                            content: " stylisé";
                        }

                        .bar-icon:after {
                            content: " standard";
                        }

                        .btn-chart-icon {
                            width: 15px;
                            margin-left: 10px;
                        }

                        .chart-icon {
                            background-color: #353841;
                            width: 30px;
                            height: 30px;
                            border-radius: 400px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 10px;

                            @include phone {
                                width: 40px;
                                height: 40px;
                                margin-left: 0;
                            }


                            .bar-container {
                                width: 20px;
                                height: 20px;
                                display: flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: flex-end;

                                .bar {
                                    background-color: #353841;
                                    border: 1px solid white;
                                    width: 2px;
                                    height: 8px;
                                    margin: 0 1px;
                                    margin-bottom: 1px;
                                    transition: .2s ease-in-out;
                                }

                                .first-bar {
                                    height: 30%;
                                    animation: bounceBar1 3s infinite;
                                }

                                .second-bar {
                                    height: 60%;
                                    animation: bounceBar2 3s infinite;
                                    animation-delay: .1s;
                                }

                                .third-bar {
                                    height: 80%;
                                    animation: bounceBar3 3s infinite;
                                    animation-delay: .2s;
                                }
                            }

                            .circle-container {
                                width: 20px;
                                display: none;
                                height: 20px;

                                // display: flex;
                                justify-content: center;
                                align-items: center;

                                .circle {
                                    background-color: white;
                                    width: 7px;
                                    height: 7px;
                                    border-radius: 1000px;
                                    position: absolute;
                                }

                                .big-circle {
                                    width: 18px;
                                    height: 18px;
                                    background-color: rgba(white, 0.2);
                                    border: 1px solid white;
                                    animation: circleBounce 3s infinite;
                                }
                            }
                        }
                    }

                    .chart {
                        width: 60%;
                        position: relative; //background-color: lightcoral;
                        display: flex;

                        @include limit-screen {
                            // background-color: lightcyan;
                            width: 70vw;
                            height: 70vw;
                            max-width: 650px;
                            max-height: 650px;
                        }


                        @include tablet {
                            width: 100vw;
                            height: 100vw;
                            max-width: 850px;
                            max-height: 850px;
                        }


                        @include phone {
                            width: 120vw;
                            height: 120vw;
                            max-width: 900px;
                            max-height: 900px;
                        }


                        #rosette-chart {
                            z-index: 4;
                            width: 700px;
                            height: 700px;
                            margin-left: 58px;
                            margin-top: -50px;

                            @include limit-screen {
                                width: 100%;
                                height: 100%;
                                max-width: 650px;
                                max-height: 650px;
                                margin-left: inherit;
                                margin-top: inherit;
                            }


                            @include tablet {
                                width: 100%;
                                height: 100%;
                                max-width: 850px;
                                max-height: 850px;
                                margin-left: inherit;
                                margin-top: inherit;
                            }


                            @include phone {
                                width: 100%;
                                height: 100%;
                                max-width: 900px;
                                max-height: 900px;
                                margin-left: inherit;
                                margin-top: inherit;
                            }
                        }

                        .info-chart {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            @include limit-screen {
                                min-width: 100%;
                            }
                        }

                        .circular-chart {
                            display: flex;
                            width: 49%;
                            margin-top: -50px;
                            transform-origin: 50% 50%;
                            transform-box: fill-box;
                            transform: rotate(194deg);
                            z-index: 2;
                            align-self: center;

                            @include limit-screen {
                                width: 57%;
                                margin-top: inherit;
                            }


                            @include tablet {
                                width: 57%;
                                margin-top: inherit;
                            }


                            @include phone {
                                width: 57%;
                                margin-top: inherit;
                            }
                        }

                        .circle {
                            // stroke: $primary-color;
                            fill: none;
                            stroke-width: .5;
                            stroke-linecap: round;
                            animation: progress 1s ease-out forwards;
                        }

                        @keyframes progress {
                            0% {
                                stroke-dasharray: 0 100;
                            }
                        }

                        .no-data-rossette {
                            font-family: "Eni", sans-serif;
                            font-size: 22px;
                            position: absolute;
                            left: 29%;
                            top: 30%;
                            z-index: 8;
                            width: 38%;
                            text-align: center;
                            padding: 15px 20px;

                            img {
                                width: 90px;
                                margin-bottom: 20px;
                            }

                            @include tablet {
                                top: 36%;
                            }

                            @include phone {
                                img {
                                    display: none;
                                }

                                left: 26%;
                                top: 35%;
                                font-size: 18px;
                            }
                        }

                        .house-icon {
                            position: absolute;
                            left: 340px;

                            //top: 210px; with temperature icon
                            width: 180px;
                            z-index: 8;
                            top: 230px;

                            @include limit-screen {
                                left: calc(50% - 65px);
                                top: calc(50% - 60px);
                                width: 150px;
                            }


                            @include tablet {
                                left: calc(50% - 75px);
                                top: calc(50% - 80px);
                                width: 200px;
                            }


                            @include phone {
                                left: calc(50% - 45px);
                                top: calc(50% - 50px);
                                width: 130px;
                            }


                            @include little-phone {
                                left: calc(50% - 45px);
                                top: calc(50% - 40px);
                                width: 100px;
                            }
                        }

                        .thermometer-icon {
                            position: absolute;
                            background-color: #353841;
                            width: 20px;
                            height: 20px;
                            top: 360px;
                            padding: 10px 10px;
                            border-radius: 1000px;
                            z-index: 8;

                            &:hover {
                                cursor: pointer;
                            }

                            @include limit-screen {
                                top: calc(60% - 10px);
                            }


                            @include tablet {
                                top: calc(60% - 10px);
                            }


                            @include phone {
                                top: calc(60% - 10px);
                                width: 13px;
                                height: 13px;
                                padding: 8px;
                            }
                        }

                        .tooltip {
                            display: none;
                            position: absolute;
                            font-size: 12px;
                            width: 160px;
                            height: auto;
                            z-index: 10;
                            color: #fff;
                            background-color: rgba(#353841, 0.6);
                            padding: 10px 5px;
                            border-radius: 10px;

                            b {
                                font-weight: bold;
                            }

                            .tooltip-date {
                                margin-bottom: 10px;
                            }

                            .tooltip-metric {
                                margin-bottom: 2px;
                                text-align: left;
                            }
                        }

                        .chart-info-day {
                            display: none;

                            .sun-icon-container {
                                position: absolute;
                                top: 135px; // left: 26%;
                                width: 30px;
                                z-index: 8;
                                left: calc(50% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0s);

                                .sun-text {
                                    font-size: 14px;
                                }

                                @include limit-screen {
                                    top: 26%;
                                    width: 25px;

                                    .sun-icon {
                                        width: 25px;
                                    }
                                }


                                @include tablet {
                                    top: 26%;
                                    width: 25px;

                                    .sun-icon {
                                        width: 25px;
                                    }
                                }


                                @include phone {
                                    top: 26%;
                                    width: 20px;

                                    .sun-icon {
                                        width: 20px;
                                    }

                                    .sun-text {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .sunrise-icon-container {
                                position: absolute;
                                top: 280px;
                                left: 250px;
                                width: 30px;
                                z-index: 8;

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.02s);

                                .sunrise-text {
                                    font-size: 14px;
                                }

                                @include limit-screen {
                                    top: calc(50% - 30px);
                                    left: 28%;
                                }


                                @include tablet {
                                    top: calc(50% - 30px);
                                    left: 28%;
                                }


                                @include phone {
                                    top: calc(50% - 15px);
                                    left: 28%;

                                    .sunrise-text {
                                        font-size: 14px;
                                    }

                                    .sunrise-icon {
                                        width: 20px;
                                    }
                                }
                            }

                            .sunset-icon-container {
                                position: absolute;
                                top: 280px;
                                right: 250px;
                                width: 30px;
                                z-index: 8;

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.04s);

                                .sunset-text {
                                    font-size: 14px;
                                }

                                @include limit-screen {
                                    top: calc(50% - 30px);
                                    right: 28%;
                                }


                                @include tablet {
                                    top: calc(50% - 30px);
                                    right: 28%;
                                }


                                @include phone {
                                    top: calc(50% - 15px);
                                    right: 28%;

                                    .sunset-icon {
                                        width: 20px;
                                    }

                                    .sunset-text {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .night-icon-container {
                                position: absolute;
                                top: 420px; // left: 26%;
                                width: 25px;
                                z-index: 8;
                                left: calc(50% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.06s);

                                .night-text {
                                    font-size: 14px;
                                }

                                @include limit-screen {
                                    top: 67%;
                                    width: 25px;

                                    .night-icon {
                                        width: 20px;
                                    }
                                }


                                @include tablet {
                                    top: 67%;
                                    width: 25px;

                                    .night-icon {
                                        width: 20px;
                                    }
                                }


                                @include phone {
                                    top: 67%;
                                    width: 25px;

                                    .night-icon {
                                        width: 15px;
                                    }

                                    .night-text {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        .chart-info-week {
                            .caption {
                                position: absolute;
                                top: 420px; // left: 26%;
                                width: 25px;
                                z-index: 8;
                                font-size: 16px;
                                left: calc(50% - 15px);
                            }

                            .caption-0 {
                                top: 150px;
                                left: calc(58% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0s);

                                @include limit-screen {
                                    top: 180px;
                                }


                                @include tablet {
                                    top: 29%;
                                    left: calc(60% - 15px);
                                }


                                @include phone {
                                    top: 29%;
                                    left: calc(60% - 15px);
                                }


                                @include little-phone {
                                    top: 29%;
                                    left: calc(60% - 15px);
                                }
                            }

                            .caption-1 {
                                top: 260px;
                                left: calc(68% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.02s);

                                @include limit-screen {
                                    top: 290px;
                                    left: calc(70% - 15px);
                                }


                                @include tablet {
                                    top: 43%;
                                    left: calc(70% - 15px);
                                }


                                @include phone {
                                    top: 43%;
                                    left: calc(70% - 15px);
                                }


                                @include little-phone {
                                    top: 43%;
                                    left: calc(70% - 15px);
                                }
                            }

                            .caption-2 {
                                top: 385px;
                                left: calc(65% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.04s);

                                @include limit-screen {
                                    top: 405px;
                                }


                                @include tablet {
                                    top: 62%;
                                    left: calc(67% - 15px);
                                }


                                @include phone {
                                    top: 60%;
                                    left: calc(67% - 15px);
                                }


                                @include little-phone {
                                    top: 58%;
                                    left: calc(67% - 15px);
                                }
                            }

                            .caption-3 {
                                top: 430px;

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.06s);

                                @include limit-screen {
                                    top: 450px;
                                }


                                @include tablet {
                                    top: 69%;
                                }


                                @include phone {
                                    top: 67%;
                                }


                                @include little-phone {
                                    top: 65%;
                                }
                            }

                            .caption-4 {
                                top: 385px;
                                left: calc(36% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.08s);

                                @include limit-screen {
                                    top: 405px;
                                }


                                @include tablet {
                                    top: 62%;
                                    left: calc(34% - 15px);
                                }


                                @include phone {
                                    top: 60%;
                                    left: calc(34% - 15px);
                                }


                                @include little-phone {
                                    top: 58%;
                                    left: calc(34% - 15px);
                                }
                            }

                            .caption-5 {
                                top: 260px;
                                left: calc(32% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.1s);

                                @include limit-screen {
                                    top: 290px;
                                    left: calc(30% - 15px);
                                }


                                @include tablet {
                                    top: 43%;
                                    left: calc(30% - 15px);
                                }


                                @include phone {
                                    top: 43%;
                                    left: calc(30% - 15px);
                                }


                                @include little-phone {
                                    top: 43%;
                                    left: calc(30% - 15px);
                                }
                            }

                            .caption-6 {
                                top: 150px;
                                left: calc(42% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.12s);

                                @include limit-screen {
                                    top: 180px;
                                }


                                @include tablet {
                                    top: 29%;
                                    left: calc(40% - 15px);
                                }


                                @include phone {
                                    top: 29%;
                                    left: calc(40% - 15px);
                                }


                                @include little-phone {
                                    top: 29%;
                                    left: calc(40% - 15px);
                                }
                            }
                        }

                        .chart-info-month {
                            .caption {
                                position: absolute;
                                top: 420px; // left: 26%;
                                width: 25px;
                                z-index: 8;
                                font-size: 16px;
                                left: calc(50% - 15px);

                                @include little-phone {
                                    font-size: 14px;
                                }
                            }

                            .caption-0 {
                                top: 130px;
                                left: calc(52% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0s);

                                @include limit-screen {
                                    top: 170px;
                                }


                                @include tablet {
                                    top: 26%;
                                }


                                @include phone {
                                    top: 26%;
                                }


                                @include little-phone {
                                    top: 26%;
                                    left: calc(53% - 15px);
                                }
                            }

                            .caption-2 {
                                top: 150px;
                                left: calc(59% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.02s);

                                @include limit-screen {
                                    top: 190px;
                                    left: calc(61% - 15px);
                                }


                                @include tablet {
                                    top: 29%;
                                    left: calc(61% - 15px);
                                }


                                @include phone {
                                    top: 29%;
                                    left: calc(61% - 15px);
                                }


                                @include little-phone {
                                    top: 29%;
                                    left: calc(62% - 15px);
                                }
                            }

                            .caption-4 {
                                top: 195px;
                                left: calc(64% - 15px);

                              &.captionFeb-4{
                                top: 197px;
                                left: calc(64% - 15px);


                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.04s);

                                @include limit-screen {
                                  top: 225px;
                                  left: calc(66% - 15px);
                                }


                                @include tablet {
                                  top: 35%;
                                  left: calc(67% - 15px);
                                }


                                @include phone {
                                  top: 34%;
                                  left: calc(66% - 15px);
                                }


                                @include little-phone {
                                  top: 34%;
                                  left: calc(67% - 15px);
                                }
                              }


                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.04s);

                                @include limit-screen {
                                    top: 225px;
                                    left: calc(66% - 15px);
                                }


                                @include tablet {
                                    top: 34%;
                                    left: calc(66% - 15px);
                                }


                                @include phone {
                                    top: 34%;
                                    left: calc(66% - 15px);
                                }


                                @include little-phone {
                                    top: 34%;
                                    left: calc(67% - 15px);
                                }
                            }

                            .caption-6 {
                                top: 245px;
                                left: calc(68% - 15px);

                              &.captionFeb-6 {
                                top: 266px;
                                left: calc(69% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.06s);

                                @include limit-screen {
                                  top: 275px;
                                  left: calc(70% - 15px);
                                }


                                @include tablet {
                                  top: 42%;
                                  left: calc(71% - 15px);
                                }


                                @include phone {
                                  top: 42%;
                                  left: calc(71% - 15px);
                                }


                                @include little-phone {
                                  top: 42%;
                                  left: calc(72% - 15px);
                                }
                              }


                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.06s);

                                @include limit-screen {
                                    top: 275px;
                                    left: calc(70% - 15px);
                                }


                                @include tablet {
                                    top: 42%;
                                    left: calc(71% - 15px);
                                }


                                @include phone {
                                    top: 42%;
                                    left: calc(71% - 15px);
                                }


                                @include little-phone {
                                    top: 42%;
                                    left: calc(72% - 15px);
                                }
                            }

                            .caption-8 {
                              top: 310px;
                              left: calc(68% - 15px);

                              &.captionFeb-8 {
                                top: 339px;
                                left: calc(67% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.08s);

                                @include limit-screen {
                                  top: 330px;
                                  left: calc(70% - 15px);
                                }


                                @include tablet {
                                  top: 52%;
                                  left: calc(71% - 15px);
                                }


                                @include phone {
                                  top: 52%;
                                  left: calc(71% - 15px);
                                }


                                @include little-phone {
                                  top: 52%;
                                  left: calc(72% - 15px);
                                }
                              }
                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.08s);

                                @include limit-screen {
                                  top: 330px;
                                  left: calc(70% - 15px);
                                }


                                @include tablet {
                                  top: 51%;
                                  left: calc(71% - 15px);
                                }


                                @include phone {
                                  top: 52%;
                                  left: calc(71% - 15px);
                                }


                                @include little-phone {
                                  top: 52%;
                                  left: calc(72% - 15px);
                                }
                            }

                            .caption-10 {
                                top: 365px;
                                left: calc(66% - 15px);

                              &.captionFeb-10 {
                                top: 392px;
                                left: calc(63% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.1s);

                                @include limit-screen {
                                  top: 385px;
                                  left: calc(68% - 15px);
                                }


                                @include tablet {
                                  top: 60%;
                                  left: calc(68% - 15px);
                                }


                                @include phone {
                                  top: 59%;
                                  left: calc(68% - 15px);
                                }


                                @include little-phone {
                                  top: 59%;
                                  left: calc(69% - 15px);
                                }
                              }

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.1s);

                                @include limit-screen {
                                    top: 385px;
                                    left: calc(68% - 15px);
                                }


                                @include tablet {
                                    top: 60%;
                                    left: calc(68% - 15px);
                                }


                                @include phone {
                                    top: 59%;
                                    left: calc(68% - 15px);
                                }


                                @include little-phone {
                                    top: 59%;
                                    left: calc(69% - 15px);
                                }
                            }

                            .caption-12 {
                                top: 400px;
                                left: calc(62% - 15px);

                              &.captionFeb-12 {
                                top: 425px;
                                left: calc(57% - 15px);


                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.12s);

                                @include limit-screen {
                                  top: 420px;
                                  left: calc(64% - 15px);
                                }


                                @include tablet {
                                  top: 66%;
                                  left: calc(63% - 15px);
                                }


                                @include phone {
                                  top: 64%;
                                  left: calc(62% - 15px);
                                }


                                @include little-phone {
                                  top: 64%;
                                  left: calc(63% - 15px);
                                }
                              }

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.12s);

                                @include limit-screen {
                                    top: 420px;
                                    left: calc(64% - 15px);
                                }


                                @include tablet {
                                    top: 66%;
                                    left: calc(63% - 15px);
                                }


                                @include phone {
                                    top: 64%;
                                    left: calc(62% - 15px);
                                }


                                @include little-phone {
                                    top: 64%;
                                    left: calc(63% - 15px);
                                }
                            }

                            .caption-14 {
                                top: 430px;
                                left: calc(54% - 15px);

                              &.captionFeb-14 {
                                top: 430px;
                                left: calc(48% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.14s);

                                @include limit-screen {
                                  top: 445px;
                                }


                                @include tablet {
                                  top: 69%;
                                  left: calc(53% - 15px);
                                }


                                @include phone {
                                  top: 67%;
                                }


                                @include little-phone {
                                  top: 67%;
                                  left: calc(55% - 15px);
                                }

                              }
                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.14s);

                                @include limit-screen {
                                    top: 445px;
                                }


                                @include tablet {
                                  top: 69%;

                                }


                                @include phone {
                                    top: 67%;
                                }


                                @include little-phone {
                                    top: 67%;
                                    left: calc(55% - 15px);
                                }
                            }

                            .caption-16 {
                                top: 430px;
                                left: calc(46% - 15px);
                              &.captionFeb-16 {
                                top: 414px;
                                left: calc(41% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.16s);

                                @include limit-screen {
                                  top: 438px;
                                }


                                @include tablet {
                                  top: 65%;
                                }


                                @include phone {
                                  top: 67%;
                                }


                                @include little-phone {
                                  top: 67%;
                                  left: calc(47% - 15px);
                                }
                              }

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.16s);

                                @include limit-screen {
                                    top: 445px;
                                }


                                @include tablet {
                                  top: 69%;
                                }


                                @include phone {
                                    top: 65%;
                                }


                                @include little-phone {
                                    top: 65%;
                                    left: calc(47% - 15px);
                                }
                            }

                            .caption-18 {
                                top: 400px;
                                left: calc(39% - 15px);
                              &.captionFeb-18 {
                                top: 370px;
                                left: calc(35% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.18s);

                                @include limit-screen {
                                  top: 398px;
                                  left: calc(34% - 15px);
                                }


                                @include tablet {
                                  top: 60%;
                                  left: calc(32% - 15px);
                                }


                                @include phone {
                                  top: 59%;
                                  left: calc(34% - 15px);
                                }


                                @include little-phone {
                                  top: 59%;
                                  left: calc(34% - 15px);
                                }
                              }

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.18s);

                                @include limit-screen {
                                    top: 420px;
                                    left: calc(37% - 15px);
                                }


                                @include tablet {
                                  top: 65%;
                                  left: calc(37% - 15px);
                                }


                                @include phone {
                                    top: 64%;
                                    left: calc(38% - 15px);
                                }


                                @include little-phone {
                                    top: 64%;
                                    left: calc(39% - 15px);
                                }
                            }

                            .caption-20 {
                                top: 365px;
                                left: calc(34% - 15px);

                              &.captionFeb-20 {
                                top: 304px;
                                left: calc(32% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.2s);

                                @include limit-screen {
                                  top: 331px;
                                  left: calc(30% - 15px);
                                }


                                @include tablet {
                                  top: 51%;
                                  left: calc(29% - 15px);
                                }


                                @include phone {
                                  top: 50%;
                                  left: calc(30% - 15px);
                                }


                                @include little-phone {
                                  top: 50%;
                                  left: calc(30% - 15px);
                                }
                              }

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.2s);

                                @include limit-screen {
                                    top: 385px;
                                    left: calc(32% - 15px);
                                }


                                @include tablet {
                                    top: 60%;
                                    left: calc(32% - 15px);
                                }


                                @include phone {
                                    top: 58%;
                                    left: calc(32% - 15px);
                                }


                                @include little-phone {
                                    top: 58%;
                                    left: calc(33% - 15px);
                                }
                            }

                            .caption-22 {
                                top: 310px;
                                left: calc(32% - 15px);

                              &.captionFeb-22 {
                                top: 240px;
                                left: calc(33% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.22s);

                                @include limit-screen {
                                  top: 272px;
                                  left: calc(30% - 15px);
                                }


                                @include tablet {
                                  top: 41%;
                                  left: calc(29% - 15px);
                                }


                                @include phone {
                                  top: 40%;
                                  left: calc(31% - 15px);
                                }


                                @include little-phone {
                                  top: 40%;
                                  left: calc(31% - 15px);
                                }
                              }

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.22s);

                                @include limit-screen {
                                    top: 330px;
                                    left: calc(30% - 15px);
                                }


                                @include tablet {
                                  top: 52%;
                                  left: calc(30% - 15px);
                                }


                                @include phone {
                                  top: 50%;
                                  left: calc(31% - 15px);
                                }


                                @include little-phone {
                                  top: 50%;
                                  left: calc(31% - 15px);
                                }
                            }

                            .caption-24 {
                                top: 245px;
                                left: calc(32% - 15px);
                              &.captionFeb-24 {

                                top: 180px;
                                left: calc(37% - 15px);
                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.24s);

                                @include limit-screen {
                                  top: 220px;
                                  left: calc(35% - 15px);
                                }


                                @include tablet {
                                  top: 32%;
                                  left: calc(35% - 15px);
                                }


                                @include phone {
                                  top: 32%;
                                  left: calc(35% - 15px);
                                }


                                @include little-phone {
                                  top: 32%;
                                  left: calc(35% - 15px);
                                }
                              }

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.24s);

                                @include limit-screen {
                                    top: 275px;
                                    left: calc(30% - 15px);
                                }


                                @include tablet {
                                    top: 42%;
                                    left: calc(29% - 15px);
                                }


                                @include phone {
                                    top: 42%;
                                    left: calc(29% - 15px);
                                }


                                @include little-phone {
                                    top: 42%;
                                    left: calc(30% - 15px);
                                }
                            }

                            .caption-26 {
                              top: 190px;
                              left: calc(36% - 15px);
                              &.captionFeb-26 {

                                top: 150px;
                                left: calc(45% - 20px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.26s);

                                @include limit-screen {
                                  top: 176px;
                                  left: calc(45% - 20px);
                                }



                                @include tablet {
                                  top: 27%;
                                  left: calc(45% - 20px);
                                }


                                @include phone {
                                  top: 28%;
                                  left: calc(43% - 15px);
                                }


                                @include little-phone {
                                  top: 28%;
                                  left: calc(43% - 15px);
                                }
                              }

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.26s);

                                @include limit-screen {
                                  top: 222px;
                                  left: calc(32% - 6px);
                                }


                                @include tablet {
                                    top: 34%;
                                    left: calc(33% - 15px);
                                }


                                @include phone {
                                  top: 34%;
                                  left: calc(34% - 15px);
                                }


                                @include little-phone {
                                  top: 34%;
                                  left: calc(34% - 15px);
                                }
                            }

                            .caption-28 {
                                top: 148px;
                                left: calc(42% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.28s);

                                @include limit-screen {
                                    top: 190px;
                                    left: calc(40% - 15px);
                                }


                                @include tablet {
                                    top: 28%;
                                    left: calc(40% - 15px);
                                }


                                @include phone {
                                    top: 28%;
                                    left: calc(40% - 15px);
                                }


                                @include little-phone {
                                    top: 28%;
                                    left: calc(41% - 15px);
                                }
                            }

                            .caption-30 {
                                display: none;
                            }
                        }

                        .chart-info-year {
                            .caption {
                                position: absolute;
                                top: 420px; // left: 26%;
                                width: 25px;
                                z-index: 8;
                                font-size: 14px;
                                left: calc(50% - 15px);
                            }

                            .caption-0 {
                                top: 140px;
                                left: calc(55% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0s);

                                @include limit-screen {
                                    top: 180px;
                                    left: calc(56% - 15px);
                                }


                                @include tablet {
                                    top: 27%;
                                    left: calc(56% - 15px);
                                }


                                @include phone {
                                    top: 27%;
                                    left: calc(56% - 15px);
                                }


                                @include little-phone {
                                    top: 27%;
                                    left: calc(56% - 15px);
                                }
                            }

                            .caption-1 {
                                top: 180px;
                                left: calc(62% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.02s);

                                @include limit-screen {
                                    top: 210px;
                                    left: calc(63% - 15px);
                                }


                                @include tablet {
                                    top: 32%;
                                    left: calc(65% - 15px);
                                }


                                @include phone {
                                    top: 32%;
                                    left: calc(65% - 15px);
                                }


                                @include little-phone {
                                    top: 32%;
                                    left: calc(65% - 15px);
                                }
                            }

                            .caption-2 {
                                top: 250px;
                                left: calc(67% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.04s);

                                @include limit-screen {
                                    top: 280px;
                                    left: calc(69% - 15px);
                                }


                                @include tablet {
                                    top: 43%;
                                    left: calc(70% - 15px);
                                }


                                @include phone {
                                    top: 43%;
                                    left: calc(70% - 15px);
                                }


                                @include little-phone {
                                    top: 43%;
                                    left: calc(69% - 15px);
                                }
                            }

                            .caption-3 {
                                top: 330px;
                                left: calc(68% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.06s);

                                @include limit-screen {
                                    top: 350px;
                                    left: calc(69% - 15px);
                                }


                                @include tablet {
                                    top: 55%;
                                    left: calc(70% - 15px);
                                }


                                @include phone {
                                    top: 55%;
                                    left: calc(70% - 15px);
                                }


                                @include little-phone {
                                    top: 55%;
                                    left: calc(69% - 15px);
                                }
                            }

                            .caption-4 {
                                top: 410px;
                                left: calc(62% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.08s);

                                @include limit-screen {
                                    top: 420px;
                                    left: calc(64% - 15px);
                                }


                                @include tablet {
                                    top: 65%;
                                    left: calc(65% - 15px);
                                }


                                @include phone {
                                    top: 64%;
                                    left: calc(65% - 15px);
                                }


                                @include little-phone {
                                    top: 63%;
                                    left: calc(65% - 15px);
                                }
                            }

                            .caption-5 {
                                top: 440px;
                                left: calc(55% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.1s);

                                @include limit-screen {
                                    top: 450px;
                                    left: calc(55% - 15px);
                                }


                                @include tablet {
                                    top: 71%;
                                    left: calc(56% - 15px);
                                }


                                @include phone {
                                    top: 70%;
                                    left: calc(56% - 15px);
                                }


                                @include little-phone {
                                    top: 69%;
                                    left: calc(56% - 15px);
                                }
                            }

                            .caption-6 {
                                top: 440px;
                                left: calc(45% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.12s);

                                @include limit-screen {
                                    top: 450px;
                                    left: calc(45% - 15px);
                                }


                                @include tablet {
                                    top: 71%;
                                    left: calc(46% - 15px);
                                }


                                @include phone {
                                    top: 70%;
                                    left: calc(46% - 15px);
                                }


                                @include little-phone {
                                    top: 69%;
                                    left: calc(46% - 15px);
                                }
                            }

                            .caption-7 {
                                top: 410px;
                                left: calc(38% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.14s);

                                @include limit-screen {
                                    top: 420px;
                                    left: calc(36% - 15px);
                                }


                                @include tablet {
                                    top: 65%;
                                    left: calc(36% - 15px);
                                }


                                @include phone {
                                    top: 64%;
                                    left: calc(36% - 15px);
                                }


                                @include little-phone {
                                    top: 63%;
                                    left: calc(36% - 15px);
                                }
                            }

                            .caption-8 {
                                top: 330px;
                                left: calc(33% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.16s);

                                @include limit-screen {
                                    top: 350px;
                                    left: calc(30% - 15px);
                                }


                                @include tablet {
                                    top: 55%;
                                    left: calc(30% - 15px);
                                }


                                @include phone {
                                    top: 55%;
                                    left: calc(30% - 15px);
                                }


                                @include little-phone {
                                    top: 55%;
                                    left: calc(31% - 15px);
                                }
                            }

                            .caption-9 {
                                top: 250px;
                                left: calc(33% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.18s);

                                @include limit-screen {
                                    top: 280px;
                                    left: calc(30% - 15px);
                                }


                                @include tablet {
                                    top: 43%;
                                    left: calc(30% - 15px);
                                }


                                @include phone {
                                    top: 43%;
                                    left: calc(30% - 15px);
                                }


                                @include little-phone {
                                    top: 43%;
                                    left: calc(31% - 15px);
                                }
                            }

                            .caption-10 {
                                top: 180px;
                                left: calc(37% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.2s);

                                @include limit-screen {
                                    top: 210px;
                                    left: calc(36% - 15px);
                                }


                                @include tablet {
                                    top: 32%;
                                    left: calc(36% - 15px);
                                }


                                @include phone {
                                    top: 32%;
                                    left: calc(36% - 15px);
                                }


                                @include little-phone {
                                    top: 32%;
                                    left: calc(36% - 15px);
                                }
                            }

                            .caption-11 {
                                top: 140px;
                                left: calc(46% - 15px);

                                @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0.22s);

                                @include limit-screen {
                                    top: 180px;
                                    left: calc(45% - 15px);
                                }


                                @include tablet {
                                    top: 27%;
                                    left: calc(45% - 15px);
                                }


                                @include phone {
                                    top: 27%;
                                    left: calc(45% - 15px);
                                }


                                @include little-phone {
                                    top: 27%;
                                    left: calc(45% - 15px);
                                }
                            }
                        }

                        .rounded {
                            display: none;
                            background-color: #fff;
                            width: 347px;
                            height: 347px;
                            border-radius: 1000px;
                            position: absolute;
                            z-index: 6;
                            margin-top: -25px;

                            @include limit-screen {
                                background-color: #fff;
                                width: 49.5%;
                                height: 47.5%;
                                margin-top: 0px;
                            }


                            @include tablet {
                                width: 50%;
                                height: 50%;
                                margin-top: 0px;
                            }


                            @include phone {
                                width: 50%;
                                height: 50%;
                                margin-top: 0px;
                            }
                        }

                        @supports (-moz-appearance: none) {
                            .rounded {
                                margin-top: -50px;

                                @include limit-screen {
                                    margin-top: 0px;
                                }


                                @include tablet {
                                    margin-top: 0px;
                                }


                                @include phone {
                                    margin-top: 0px;
                                }
                            }
                        }
                    }

                    .line-chart-container {
                        padding-bottom: 50px; // background-color: blue;
                        @include limit-screen {
                            padding-bottom: 0px;
                        }


                        @include tablet {
                            height: 650px;
                            width: 80% !important;
                        }


                        @include phone {
                            height: 400px;
                            width: 80% !important;
                        }


                        #line-chart {
                            // background-color: #24b687;
                            margin-bottom: 80px;
                            width: 700px;
                            height: 450px;
                            margin-top: 70px;
                            margin-left: 50px;

                            @include limit-screen {
                                width: 550px;
                                height: 400px;
                                max-width: 650px;
                                max-height: 400px;
                                margin-top: 110px;
                                margin-bottom: 10px;
                            }


                            @include tablet {
                                width: 100%;
                                height: 100%;
                                max-width: 950px;
                                max-height: 450px;
                                margin-left: inherit;
                                margin-top: 100px;
                            }


                            @include phone {
                                width: 100%;
                                height: 100%;
                                max-width: 950px;
                                max-height: 300px;
                                margin-left: inherit;
                                margin-top: inherit;
                                margin-top: 100px;
                            }
                        }
                    }

                    .bar-chart-container {
                        // padding-bottom: 50px;
                        @include limit-screen {
                            padding-bottom: 0px;
                        }


                        @include tablet {
                            height: 650px;
                            width: 80% !important;
                        }


                        @include phone {
                            height: 400px;
                            width: 80% !important;
                        }


                        #bar-chart {
                            // background-color: #24b687;
                            margin-bottom: 80px;
                            width: 700px;
                            height: 450px;
                            margin-top: 70px;
                            margin-left: 50px;

                            @include limit-screen {
                                width: 650px;
                                height: 400px;
                                max-width: 650px;
                                max-height: 400px;
                                margin-left: inherit;
                                margin-top: 110px;
                                margin-bottom: 10px;
                            }


                            @include tablet {
                                width: 100%;
                                height: 100%;
                                max-width: 950px;
                                max-height: 450px;
                                margin-left: inherit;
                                margin-top: 100px;
                            }


                            @include phone {
                                width: 100%;
                                height: 100%;
                                max-width: 950px;
                                max-height: 300px;
                                margin-left: inherit;
                                margin-top: inherit;
                                margin-top: 100px;
                            }
                        }
                    }

                    div.consumption {
                        display: flex;
                        flex-direction: column;

                        width: 100%;
                        max-width: 400px;

                        margin-top: 40px;
                        align-items: left;
                        transition: .2s ease-in-out;

                        @include limit-screen {
                            width: 400px;
                        }


                        @include tablet {
                            margin-top: -135px;
                            max-width: 600px;
                        }

                        @include phone {
                            margin-top: -100px;
                            width: calc(100% - 40px);
                        }


                        div.title-from {
                            font-size: 25px;
                            font-weight: 300;
                            height: 30px;
                        }

                        div.kwh-wrapper {
                            width: 100%;
                            display: flex;
                            justify-content: left;

                            div.kwh-number {
                                margin-top: 32px;
                                font-size: 40px;
                                font-weight: 300;
                            }

                            .text-price {
                                text-align: left;
                                margin: 52px 0 0 20px;
                                width: 30%;
                            }

                            .small-price {
                                font-size: 18px;
                            }
                        }

                        div.price-wrapper {
                            width: 100%;
                            display: flex;
                            justify-content: left;

                            div.kwh-price {
                                margin-top: 32px;
                                font-size: 40px;
                                font-weight: 300;
                            }
                        }

                        div.consumptionKWH {
                            width: 100%;
                            height: 23px;
                            margin-top: 10px;
                            display: flex;
                            flex-direction: row;

                            div.consumptionKWH1 {
                                min-width: 10%;
                                text-align: left;
                                color: black;
                                font-weight: 500;
                                font-size: 13px;
                                padding-left: 5px;
                                line-height: 20px;
                                width: 0px;
                                overflow: hidden;
                                transition: 2s ease-in-out;
                                transition: width 1s ease-in-out 0s;
                                background-color: $primary-color; // background: linear-gradient(to right, #278fd1 60%, #24b687);
                                @include tablet {
                                    font-size: 18px;
                                }

                                &.gas {
                                    background-color: #0fa2c0;
                                    color: white;
                                }
                            }

                            div.consumptionKWH2 {
                                min-width: 10%;
                                text-align: left; // background-color: #1b445b;
                                background-color: #353841;
                                color: white;
                                font-weight: 500;
                                font-size: 13px;
                                vertical-align: center;
                                line-height: 20px;
                                padding-left: 5px;
                                margin-left: 3px;
                                width: 0px;
                                transition: 2s ease-in-out;
                                transition: width 1s ease-in-out 0s;

                                @include tablet {
                                    font-size: 18px;
                                }

                                &.gas {
                                    background-color: #DED7CA;
                                    color: black;
                                }
                            }
                        }

                        div.consumptionEUR {
                            width: 100%;
                            height: 22px;
                            margin-top: 10px;
                            display: flex;
                            flex-direction: row;

                            div.consumptionEUR1 {
                                text-align: left;
                                color: black;
                                font-weight: 500;
                                font-size: 13px;
                                padding-left: 5px;
                                line-height: 20px;
                                min-width: 12%;
                                width: 45%;
                                overflow: hidden;
                                transition: width 1s ease-in-out 0s;
                                background-color: $primary-color; // background: linear-gradient(to right, #278fd1 60%, #24b687);
                                @include tablet {
                                    font-size: 18px;
                                }
                            }

                            div.consumptionEUR2 {
                                text-align: left; // background-color: #1b445b;
                                background-color: #353841;
                                color: white;
                                font-weight: 500;
                                font-size: 13px;
                                vertical-align: center;
                                line-height: 20px;
                                padding-left: 5px;
                                margin-left: 3px;
                                min-width: 12%;
                                width: 45%;
                                transition: width 1s ease-in-out 0s;

                                @include tablet {
                                    font-size: 18px;
                                }
                            }

                            div.consumptionEUR3 {
                                text-align: left;
                                background-color: #DED7CA;
                                color: #000;
                                font-weight: 500;
                                font-size: 13px;
                                vertical-align: center;
                                line-height: 20px;
                                padding-left: 5px;
                                margin-left: 3px;
                                width: 10%;
                                transition: width 1s ease-in-out 0s;

                                @include tablet {
                                    font-size: 18px;
                                }
                            }
                        }

                        div.color-container {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            font-family: $second-font;
                            margin-top: 48px;
                            width: 100%;

                            div.color-1 {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;

                                div.color-round {
                                    background-color: $primary-color;
                                    border-radius: 100px;
                                    width: 13px;
                                    height: 13px;
                                }

                                div.color-legend {
                                    margin-left: 5px;
                                    margin-right: 5px;
                                    font-weight: 400;
                                    font-size: 14px;

                                    @include tablet {
                                        font-size: 16px;
                                    }


                                    @include little-phone {
                                        font-size: 10px;
                                    }
                                }
                            }

                            &.gas {
                                margin-top: 24px;
                                justify-content: left;

                                .color-1 {
                                    margin-right: 20px;

                                    .color-round {
                                        background-color: #0fa2c0;
                                    }
                                }
                            }

                            div.color-2 {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;

                                div.color-round {
                                    background-color: rgb(53, 56, 64);
                                    border-radius: 100px;
                                    width: 13px;
                                    height: 13px;
                                }

                                div.color-legend {
                                    margin-left: 5px;
                                    margin-right: 5px;
                                    font-weight: 400;
                                    font-size: 14px;

                                    @include tablet {
                                        font-size: 16px;
                                    }


                                    @include little-phone {
                                        font-size: 10px;
                                    }
                                }
                            }

                            div.color-3 {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;

                                div.color-round {
                                    background-color: #DED7CA;
                                    border-radius: 100px;
                                    width: 13px;
                                    height: 13px;
                                }

                                div.color-legend {
                                    margin-left: 5px;
                                    margin-right: 5px;
                                    font-weight: 400;
                                    font-size: 14px;

                                    @include tablet {
                                        font-size: 16px;
                                    }


                                    @include little-phone {
                                        font-size: 10px;
                                    }
                                }
                            }
                        }

                        div.conso-comparaison {
                            width: 100%;
                            height: 100px;
                            display: flex;
                            font-family: $second-font;
                            justify-content: center;
                            text-align: left;
                            align-content: center;
                            align-items: center;

                            .arrow-down {
                                width: 24px;
                                font-size: 28px;
                                margin-right: 16px;
                                display: none;
                                color: #24b687;
                                animation: bounceConso 5s infinite;
                            }
                            .arrow-up {
                                width: 24px;
                                font-size: 28px;
                                margin-right: 16px;
                                display: none;
                                color: #d34a4a;
                                animation: bounceConso 5s infinite;

                            }

                            .equal {
                                font-size: 28px;
                                margin-right: 16px;
                                size: 40px;
                                display: none;
                                color: black;
                                animation: bounceConso 5s infinite;
                            }

                            div.comp-text {
                                font-size: 18px;
                                font-weight: 400;

                                @include tablet {
                                    font-size: 16px;
                                }
                                @include phone {
                                    font-size: 16px;
                                }
                            }
                        }

                        div.pMaxContainer {
                            display: block;
                            text-align: left;
                            padding: 10px 0 20px 0;

                            @include tablet {
                                text-align: center;
                            }

                            @include phone {
                                text-align: center;
                            }

                            .pMax {
                                display: flex;
                                > * {
                                    vertical-align: middle;
                                }

                                > span {
                                    padding-left: 20px;

                                    > span {
                                        font-weight: bold;
                                    }
                                }
                            }
                            div.sub {
                                padding-top: 5px;

                                > span {
                                    font-weight: bold;
                                }
                            }
                        }
                    }

                }

                .gas-disclaimer {
                    display: flex;
                    align-items: center;

                    margin: 0 20px;
                    padding: 20px 35px;
                    background-color: #F7F7F7;
                    max-width: 1000px;
                    font-size: 16px;
                    text-align: left;

                    .icon-warning {
                        background-color: $primary-color;
                        padding: 15px;
                        border-radius: 1000px;
                        margin-right: 30px;

                        @include phone {
                            width: 33px;
                            padding: 10px;
                            margin: 0 20px 0 10px;
                        }
                    }

                    @include phone {
                        margin: 0;
                        padding: 20px 5px;
                        font-size: 12px;
                    }
                }

                .conso-footer {
                    text-align: center;
                    font-weight: 400;
                    font-size: 14px;
                    font-style: italic;

                    margin: 50px 0 0 0;
                    padding: 0 20px 15px 20px;

                    z-index: 1000;

                    @include phone {
                        padding-bottom: 30px;
                        font-size: 12px;
                    }

                    .conso-text {
                        padding-bottom: 5px;
                        margin: auto;

                        @include phone {
                            bottom: 40px;
                            font-size: 12px;
                            width: 80%;
                        }
                    }

                    .download-data {
                        .message {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                        .success-message {
                            color: green;
                        }
                    }

                    &.margin-hack {
                        margin: -50px auto 0 auto;
                    }
                }
            }
        }

        .usages-repartition {
            width: 100%;
            background-color: #f7f7f7;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            a, a:visited {
                color: black;
                text-decoration: underline;
            }

            @include tablet {
                margin-top: 0;
            }


            @include phone {
                margin-top: -20px;
            }


            .subtitle {
                font-size: 16px;
                font-weight: 400;

                @include phone {
                    text-align: center;
                    padding: 0 20px;
                }
            }
            .title-uncomplete-survey {
                color: red;
                a {color: red;}
            }

            .circle-charts-container {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
                flex-wrap: wrap;

                .circle-usage {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 10px 25px;

                    .circle-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        position: relative;
                        margin-top: 20px;

                        .circular-chart {
                            display: flex;
                            width: 125px; // @include limit-screen {
                            //     width: 57%;
                            //     margin-top: inherit;
                            // }
                            // @include tablet {
                            //     width: 57%;
                            //     margin-top: inherit;
                            // }
                            // @include phone {
                            //     width: 57%;
                            //     margin-top: inherit;
                            // }

                        }

                        .circle {
                            stroke: $primary-color;
                            fill: none;
                            stroke-width: 2;
                            stroke-linecap: round;
                            animation: progress 1s ease-out forwards;
                        }

                        .circle-bg {
                            fill: white;
                            stroke: #eee;
                            stroke-width: 3;
                        }

                        @keyframes progress {
                            0% {
                                stroke-dasharray: 0 100;
                            }
                        }

                        .usage-icon {
                            width: 35px;
                            position: absolute;
                            top: calc(50% - 30px);
                        }

                        .usage-pourcentage {
                            position: absolute;
                            font-family: $first-font;
                            font-size: 18px;
                            top: calc(50% + 15px);
                        }
                    }

                    .usage-title {
                        margin-top: 10px;
                        font-size: 16px;
                    }
                }
            }

            .no-consent-container {
                text-align: center;

                img {
                    width: 100px;
                    margin-bottom: 30px;
                }

                p  {
                    max-width: 60%;
                    margin: auto;

                    @include phone {
                        max-width: 90%;
                    }
                }

                a {
                    display: block;
                    margin: auto;
                    width: 200px;
                    font-size: 14px;
                    padding: 8px 14px;
                    background-color: $primary-color;
                    border-radius: 400px;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    text-decoration: none;
                    color: black;
                    transition: .2s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        background-color: $primary-color-hover;
                    }
                }
            }

            .advices-btn {
                font-size: 14px;
                padding: 8px 14px;
                background-color: $primary-color;
                border-radius: 400px;
                margin-top: 40px;
                margin-bottom: 40px;
                text-decoration: none;
                color: black;
                transition: .2s ease-in-out;

                &:hover {
                    cursor: pointer;
                    background-color: $primary-color-hover;
                }
            }
        }

        .day-advice {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include limit-screen {
                width: calc(100% - 40px);
                margin-left: 20px;
            }


            @include tablet {
                width: calc(100% - 40px);
                margin-left: 20px;
            }


            @include phone {
                width: calc(100% - 40px);
                margin-left: 20px;
            }


            .advices-pane {
                width: 100%;
                max-width: 1360px;
                height: 200px;
                line-height: 28px;
                margin-bottom: 25px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                position: relative;

                .left-container-leftside {
                    width: 40%;

                    @include phone {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                    }


                    @include little-phone {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                    }
                }

                .right-container-leftside {
                    width: 65%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 22px;
                    font-weight: 400;
                    padding: 30px;
                    text-align: center;
                    background-color: #f7f7f7;

                    @include phone {
                        font-size: 20px;
                        width: calc(100% - 40px);
                        background-color: rgba(black, 0.3);
                        z-index: 4;
                        color: white;
                    }


                    @include little-phone {
                        font-size: 20px;
                        width: calc(100% - 40px);
                        background-color: rgba(black, 0.3);
                        z-index: 4;
                        color: white;
                    }
                }
            }

            .advices-btn {
                font-family: $first-font;
                font-size: 16px;
                padding: 8px 14px;
                background-color: $primary-color;
                border-radius: 400px;
                margin-top: 20px;
                margin-bottom: 40px;
                text-decoration: none;
                color: black;
                transition: .2s ease-in-out;

                &:hover {
                    cursor: pointer;
                    background-color: $primary-color-hover;
                }
            }
        }

        .alert-container {
            position: absolute;
            background-color: #f7f7f7;
            z-index: 100000;
            width: 300px;
            height: 50px;
            top: 120px;
            right: 0;
            border-radius: 100px 0 0 100px;
            align-items: center;
            justify-content: center;

            a {
                display: flex;
                flex-direction: row;
                justify-content: center;
                text-decoration: none;
                color: #000;

                p {
                    margin-top: 10px;
                    padding-left: 10px;
                    font-size: 14px;
                    text-decoration: none;
                    padding-right: 8px;
                }

                .alert-icon {
                    background-color: $primary-color;
                    width: 30px;
                    height: 30px;
                    padding: 5px;
                    margin: 5px 0 0 5px;
                    border-radius: 100px;
                }

                transition: 0.5s;
            }

            a:hover {
                background-color: #ebebeb;
                border-radius: 100px 0 0 100px;
                height: 50px;
                transition: 0.5s;
            }

            @media screen and (max-width: 1390px) {
                position: initial;
                z-index: 0;
                width: 100%;
                height: initial;
                top: 0;
                right: 0;
                border-radius: 0;

                a {
                    flex-direction: column;
                    align-items: center;
                    transition: 0;

                    p {
                        text-align: center;
                        font-size: 16px;
                        padding: 0 10px;
                        margin: 10px 0 20px 0;
                    }

                    .alert-icon {
                        display: block;
                        width: 40px;
                        height: 40px;
                        padding: 8px;
                        margin-top: 15px;
                    }
                }

                a:hover {
                    background-color: initial;
                    border-radius: 0;
                    height: initial;
                }
            }
        }
    }

    @keyframes bounceConso {
        0%,
        20%,
        100% {
            transform: rotate(0);
        }

        4% {
            transform: rotate(-10deg);
        }

        8% {
            transform: rotate(5deg);
        }

        12% {
            transform: rotate(-3deg);
        }

        16% {
            transform: rotate(2deg);
        }
    }

    @keyframes bounceBar1 {
        0% {
            height: 30%;
        }

        10% {
            height: 26%;
        }

        15% {
            height: 33%;
        }

        18% {
            height: 28%;
        }

        22% {
            height: 31%;
        }

        24% {
            height: 29%;
        }

        25% {
            height: 30.5%;
        }

        26% {
            height: 30%;
        }
    }

    @keyframes bounceBar2 {
        0% {
            height: 60%;
        }

        10% {
            height: 56%;
        }

        15% {
            height: 63%;
        }

        18% {
            height: 58%;
        }

        22% {
            height: 61%;
        }

        24% {
            height: 59%;
        }

        25% {
            height: 60.5%;
        }

        26% {
            height: 60%;
        }
    }

    @keyframes bounceBar3 {
        0% {
            height: 80%;
        }

        10% {
            height: 76%;
        }

        15% {
            height: 83%;
        }

        18% {
            height: 78%;
        }

        22% {
            height: 81%;
        }

        24% {
            height: 79%;
        }

        25% {
            height: 80.5%;
        }

        26% {
            height: 80%;
        }
    }

    @keyframes circleBounce {
        0% {
            transform: scale(1);
        }

        10% {
            transform: scale(0.8);
        }

        15% {
            transform: scale(1.2);
        }

        18% {
            transform: scale(0.9);
        }

        22% {
            transform: scale(1.1);
        }

        24% {
            transform: scale(0.95);
        }

        25% {
            transform: scale(1);
        }
    }
}
