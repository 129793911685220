/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.body-ecotroks {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
    @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0s);
    .ecotroks-container-main {
        flex: 1;
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        
        .main-ecotroks {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            
            .button-container {
                max-width: 1040px;
                width: 100%;
                margin-top: 50px;
                display: flex;
                flex-shrink: 0;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                font-family: $first-font;
                font-size: 22px;
                @include limit-screen{
                    width: calc(100% - 40px);
                }

                @include tablet{
                    width: calc(100% - 40px);
                }

                @include phone{
                    width: calc(100% - 40px);
                }

                @include little-phone{
                    
                    font-size: 18px;
                }

                .ecotroks-btn-container {
                    flex: 1;
                    text-align: center;
                    background-color: #DED7CA;
                    margin: 0 5px;
                    padding: 10px 30px;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    transition: .2s ease-in-out;
                    &:first-of-type {
                        margin-left: 0;
                    }
                    &:last-of-type {
                        margin-right: 0;
                    }
                    &:hover {
                        cursor: pointer;
                        background-color: #eee6d7;
                    }
                    @include phone{
                        padding: 5px 0px;
                        height: 40px;
                        align-items: center;
                    }
                    @include little-phone{
                        padding: 5px 0px;
                        height: 40px;
                        align-items: center;
                    }
                    .triangle {
                        width: 0;
                        height: 0;
                        bottom: -9px;
                        left: calc(50% - 5px);
                        position: absolute;
                        border-style: solid;
                        border-width: 10px 10px 0 10px;
                        border-color: $primary-color transparent transparent transparent;
                        display: none;
                        @include little-phone{
                            left: calc(50% - 10px);
                        }
                    }
                    .active {
                        display: inherit;
                        animation: .3s comeOn;
                    }
                }
                .selected {
                    background-color: $primary-color;
                    &:hover {
                        cursor: pointer;
                        background-color: $primary-color-hover;
                    }
                }
            }
            .my-points-container {
                display: flex;
                height: 100%;
                width: 100%;
                max-width: 1040px;
                flex-direction: column;
                @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);
                
                @include limit-screen{
                    width: calc(100% - 40px);
                }

                @include tablet{
                    width: calc(100% - 40px);
                }

                @include phone{
                    width: calc(100% - 40px);
                }
                
                .title-container {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-top: 30px;
                    .title {
                        display: flex;
                        flex-direction: row;
                        text-align: center;
                        margin-bottom: 50px;
                        font-size: 50px;
                        @include phone{
                            flex-direction: column;
                            align-items: center;
                        }
                        @include little-phone{
                            flex-direction: row;
                            font-size: 20px;
                        }
                        img {
                            width: 60px;
                            height: 100%;
                            margin-left: 10px;
                            @include little-phone{
                                width: 30px;
                            }
                        }
                        .ecotroks-balance {
                            margin: 0 10px;
                            color: $button-color;
                        }
                    }
                }
                .progress-bar-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-family: $first-font;
                    font-size: 16px;
                    width: 100%;
                    margin-bottom: 100px;
                    @include little-phone{
                        margin-bottom: 50px;
                    }
                    .badge {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        flex: 1;
                        font-size: 18px;
                        img {
                            width: 60px;
                            @include phone {
                                width: 50px;
                            }
                            @include little-phone {
                                width: 40px;
                            }
                        }
                    }
                    .bronze {
                        color: brown;
                    }
                    .silver {
                        color: silver;
                    }
                    .gold {
                        color: gold;
                    }
                    .progress-bar {
                        flex: 6;
                        background-color: red;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        position: relative;
                        .progress-bar-full {
                            background-color: #DED7CA;
                            width: 100%;
                            height: 15px;
                            position: relative;
                            .progress-bar-progressing {
                                width: 0%;
                                height: 100%;
                                max-width: 100%;
                                transition: .8s ease-in-out;
                                position: absolute;
                                background-color: $primary-color;
                                &:after {
                                    content: "";
                                    display: block;
                                    background: url("../../../images/icons/user_ico_white.svg") center no-repeat;
                                    background-size: 50%;
                                    width: 50px;
                                    height: 50px;
                                    border-radius: 100px;
                                    float: right;
                                    background-color: #353841;
                                    margin: -15px -10px 0 0;
                                    @include little-phone{
                                        width: 30px;
                                        height: 30px;
                                        margin: -5px -10px 0 0;
                                    }
                                }
                                &:before {
                                    content: "";
                                    display: block;
                                    width: 0;
                                    height: 0;
                                    bottom: -26px;
                                    float: right;
                                    right: 5px;
                                    position: absolute;
                                    border-style: solid;
                                    border-width: 10px 10px 0 10px;
                                    border-color: #353841 transparent transparent transparent;
                                    @include little-phone{
                                        bottom: -15px;
                                        right: -5px;
                                    }
                                }
                            }
                        }
                        .xp-container {
                            font-family: $second-font;
                            position: absolute;
                            top: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            left: -9%;
                            transition: .8s ease-in-out;
                            @include little-phone{
                                left: unset !important;
                            }
                            .xp-amount {
                                margin-right: 8px;
                            }
                            .xp-total {
                                margin-left: 8px;
                                margin-right: 8px;
                            }
                        }
                    }
                }
                .lottery-container {
                    background-color: #f7f7f72a;
                    width: 100%;
                    height: 220px;
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    .left-container {
                        flex: 1;
                        transition: .2s ease-in-out;
                        background: url("../../../images/lottery.jpeg") center no-repeat;
                        @include little-phone{
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 2;
                        }
                        @include phone{
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 2;
                        }
                    }
                    .right-container {
                        flex: 2;
                        font-family: $first-font;
                        display: flex;
                        justify-content: center;
                        align-items: left;
                        flex-direction: column;
                        padding: 40px;
                        z-index: 4;
                        background-color: #f7f7f7;
                        transition: .2s ease-in-out;
                        @include phone{
                            color: white;
                            .lottery-btn {
                                color: black;
                            }
                            background-color: rgba(#ffffff, .3);
                            
                            align-items: center;
                            text-align: center;
                            color: white;
                        }
                        @include little-phone{
                            color: white;
                            .lottery-btn {
                                color: black;
                            }
                            background-color: rgba(#ffffff, .3);
                            padding: 0;
                            align-items: center;
                            text-align: center;
                        }
                        .title-lottery {
                            font-size: 26px;
                            margin-bottom: 20px;
                        }
                        .text-lottery {
                            font-family: $second-font;
                            font-size: 16px;
                        }
                        .lottery-btn-container {
                            text-align: center;
                            .lottery-btn {
                                margin: 20px auto 0px auto;
                                border-radius: 100px;
                                background-color: $primary-color;
                                width: 200px;
                                padding: 7px 30px;
                                text-align: center;
                                transition: .2s ease-in-out;
                                color: black;
                                &:hover {
                                    cursor: pointer;
                                    background-color: $primary-color-hover;
                                }
                            }
                        } 
                    }
                }
                .how-to-win-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: $first-font;
                    width: 100%;
                    .title {
                        margin-bottom: 20px;
                    }
                    @include little-phone{
                        .title{
                            font-size: 26px;
                            text-align: center;
                        }
                    }
                    .subtitle {
                        font-size: 20px;
                        font-weight: 300;
                        margin-bottom: 30px;
                        @include little-phone {
                            font-size: 15px;
                        }
                    }
                    .how-to-container {
                        display: flex;
                        justify-content: left;
                        flex-direction: column;
                        width: 100%;
                        margin-top: 50px;
                        .text-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 18px;
                            .ecotroks-win-amount {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                img {
                                    margin-left: 15px;
                                    width: 35px;
                                }
                            }
                        }
                        hr {
                            width: 100%;
                            height: 1.5px;
                            border: none;
                            margin: 20px 0;
                            background-color: rgba(#353841, .3);
                        }
                    }
                    .see-all-btn {
                        font-family: $first-font;
                        font-size: 16px;
                        padding: 8px 14px;
                        background-color: $primary-color;
                        border-radius: 400px;
                        margin-top: -20px;
                        margin-bottom: 40px;
                        text-decoration: none;
                        color: black;
                        transition: .2s ease-in-out;
                        &:hover {
                            cursor: pointer;
                            background-color: $primary-color-hover;
                        }
                    }
                }
                .ecotroks-historic {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin-top: -40px;
                    @include little-phone{
                        .title{
                            font-size: 26px;
                            text-align: center;
                        }
                    }
                    #ecotroksChart{
                        width: 800px;
                        height:340px;
                        @include tablet {
                            width: 600px;
                            height: 300px;
                        }
                        @include phone {
                            width: calc(100% - 20px);
                            height: 300px;
                        }
                        @include little-phone{
                            margin-top: -50px;
                        }
                    }
                }
                .last-ecotroks-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 40px;
                    .last-card-container {
                        width: 100%;
                        font-family: $second-font;
                        position: relative;
                        .progression-bar{
                            width: 4px;
                            position: absolute;
                            background-color: $primary-color;
                            height: calc(100% - 90px);
                            z-index: 10;
                            top: 40px;
                            left: -2px;
                            transition: height 1s ease-in-out;
                        }
                        .card {
                            z-index: 4;
                            display: flex;
                            background-color: #f7f7f7;
                            flex-direction: column;
                            padding: 10px 0;
                            margin-bottom: 10px;
                            position: relative;
                            border-radius: 5px;
                            @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);

                            // &:last-of-type{
                            //     margin-bottom: 50px;
                            // }
                            .first-line {
                                display: flex;
                                justify-content: flex-start;
                                flex-direction: row;
                                margin-left: 30px;
                                align-items: center;
                                color: $button-color;
                                img {
                                    width: 30px;
                                    height: 100%;
                                    margin-left: 10px;
                                }
                            }
                            .title-earned{
                                margin-left: 30px;
                                margin-top: 5px;
                            }
                            .last-time{
                                font-size: 14px;
                                color: #bbbbbb;
                                margin-left: 30px;
                                margin-top: 5px;
                            }
                            .rounded{
                                width: 25px;
                                height: 25px;
                                position: absolute;
                                top: calc(50% - (25px / 2));
                                left: calc(-25px / 2);
                                border-radius: 100px;
                                background-color: $primary-color;
                            }
                        }
                    }
                    .see-more-btn {
                        font-family: $first-font;
                        font-size: 16px;
                        padding: 8px 25px;
                        background-color: $primary-color;
                        border-radius: 400px;
                        margin-top: 30px;
                        text-decoration: none;
                        color: black;
                        transition: .2s ease-in-out;
                        &:hover {
                            cursor: pointer;
                            background-color: $primary-color-hover;
                        }
                    }
                }
            }
            .shop-container {
                height: 100%;
                max-width: 1360px;
                width: 100%;
                display: none;
                flex-direction: column;
                align-items: center;
                @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);

                .title-container {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-top: 30px;
                    .title {
                        display: flex;
                        flex-direction: row;
                        text-align: center;
                        margin-bottom: 50px;
                        @include phone{
                            flex-direction: column;
                            align-items: center;
                        }
                        @include little-phone{
                            flex-direction: row;
                            font-size: 20px;
                        }
                        img {
                            width: 60px;
                            height: 100%;
                            margin-left: 10px;
                            @include little-phone{
                                width: 30px;
                            }
                        }
                        .ecotroks-balance {
                            margin: 0 10px;
                            color: $button-color;
                        }
                    }
                }
                .product-container {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-bottom: 10px;
                    @include limit-screen{
                        width: calc(100% - 40px);
                    }
    
                    @include tablet{
                        width: calc(100% - 40px);
                    }
    
                    @include phone{
                        width: calc(100% - 40px);
                    }
                    .product-eco {
                        width: 380px;
                        height: 305px;
                        background-color: #f7f7f7;
                        text-align: center;
                        position: relative;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        font-family: 'Roboto', sans-serif;
                        margin: 10px 10px;
                        transition: .2s ease-in-out;
                        overflow: hidden;
                        cursor: pointer;
                        &:hover{
                            cursor:  pointer;
                        }

                        @include tablet {
                            width: 300px;
                            height: 255px;
                        }

                        .ecotroks-container {
                            border-radius: 30px;
                            background-color: #505050;
                            float: right;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            position: absolute;
                            align-items: center;
                            padding: 5px 10px;
                            z-index: 10;
                            right: 10px;
                            top: 8px;
                            img {
                                width: 20px;
                            }
                            .amount-eco {
                                font-family: 'Roboto', sans-serif;
                                font-size: 12px;
                                color: white;
                                margin-left: 3px;
                            }
                        }
                        .product-image-container{
                            width: 100%;
                            height: 80%;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        .image-eco {
                            width: 100%;
                            z-index: 2;
                            @include little-phone {
                                width: 120%;
                            }
                        }
                        .title-eco {
                            margin-top: 16px;
                            margin-bottom: 8px;
                            font-weight: 300;
                            font-size: 20px;
                        }
                        .text-eco {
                            font-size: 12px;
                            padding: 0 16px;
                            font-weight: 300;
                        }
                        .ecotroks-btn-product {
                            border-radius: 40px;
                            height: 20px;
                            background-color: #ffd42a;
                            position: absolute;
                            padding: 3px 16px;
                            font-weight: 400;
                            font-size: 14px;
                            opacity: 0;
                            justify-content: center;
                            align-items: center;
                            bottom: 75px;
                            display: flex;
                            z-index: 6;
                            transition: .2s ease-in-out;
                            &:hover {
                                background-color: #ffde40;
                            }
                            img.cart {
                                width: 20px;
                                flex-direction: row;
                                animation: cart 1s ease-in-out infinite alternate;
                            }
                        }
                        &:hover>div.ecotroks-btn-product {
                            cursor: pointer;
                            opacity: 1;
                            bottom: 80px;
                            transition: .2s ease-in-out;
                        }
                    }
                    .available {
                        .ecotroks-container {
                            .amount-eco {
                                color: $button-color;
                            }
                        }
                    }
                    .not-available {
                        .ecotroks-container {
                            .amount-eco {
                                color: $alert-color;
                            }
                        }
                    }
                    .new-available {
                        transition: .5 ease-in-out; // border: 3px solid gold;
                        animation: neon1 1.5s ease-in-out infinite alternate;
                    }
                }
                .button-container {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    .historic-btn {
                        padding: 8px 16px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 300;
                        font-size: 14px;
                        background-color: #ffd42a;
                        text-align: center;
                        width: 200px;
                        border-radius: 40px;
                        margin-top: 32px;
                        margin-bottom: 48px;
                        transition: .2s ease-in-out;
                        &:hover {
                            background-color: #ffde40;
                            cursor: pointer;
                        }
                    }
                }
            }
            .point-guide-container {
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 100%;
                max-width: 1360px;
                width: 100%;
                @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);

                @include limit-screen{
                    width: calc(100% - 40px);
                }
                @include tablet{
                    width: calc(100% - 40px);
                }
                @include phone{
                    width: calc(100% - 40px);
                }
                @include little-phone{
                    width: calc(100% - 40px);
                }
                
                .title-guide{
                    margin-top: 80px;
                    @include phone {
                        font-size: 26px;
                        text-align: center;
                        margin-top: 50px;
                    }
                }
                .card-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    width: 100%;
                    margin-top: 50px;
                    @include phone {
                        flex-direction: column;
                    }
                    .card {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        align-items: center;
                        justify-content: center;
                        flex: 1;
                        text-align: center;
                        font-family: $first-font;
                        background-color: #f7f7f7;
                        height: 200px;
                        transition: .2s ease-in-out;
                        &:nth-of-type(2) {
                            margin: 0 30px;
                        }

                        @include limit-screen{
                            height: 200px;
                        }
                        @include tablet {
                            height: 280px;
                        }
                        @include phone {
                            width: 70%;
                            height: 240px;
                            flex: unset;
                            margin-bottom: 50px !important;
                        }

                        @include little-phone{
                            height: 200px;
                            width: calc(100% - 20px);
                        }
                        .icon-card {
                            position: absolute;
                            top: calc(-75px / 2);
                            width: 75px;
                            height: 75px;
                            border-radius: 500px;
                            display: flex;
                            justify-content: center;
                            align-content: center;
                            border: 8px solid #f7f7f7;
                            background-color: $primary-color;
                            img {
                                width: 60%;
                            }
                            .target-icon {
                                margin-left: 10px;
                                margin-top: -10px;
                            }
                        }
                        .title-card {
                            font-size: 30px;
                            position: absolute;
                            top: 60px;
                        }
                        .text-card-container {
                            font-family: $second-font;
                            position: absolute;
                            top: 100px;
                            padding: 0 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            font-size: 17px;
                            line-height: 20px;
                            .first {
                                margin-bottom: 15px;
                                margin-top: 15px;

                                @include tablet {
                                    margin-top: 40px;
                                }
                                @include phone {
                                    margin-top: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    } 

    .footer{
        margin-bottom: 0;
        margin-top: auto;
    }

    .see-rules {
        border: none;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 20px;
        font-size: 16px;
        font-family: $second-font;
        background: none;

        .i-ico {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }

    .modal {
        display: none;
        position: fixed;
        padding-top: 50px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        z-index: 1000;

        @include tablet {
            padding-top: 0;
        }

        @include phone{
            padding: 0;
            margin: 0;
        }

        .dark-background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        
            background-color: rgba(255, 255, 255, 0.3);            
        }

        .modal-content {
            text-align: left;
            background-color: #fefefe;
            margin: auto;
            padding: 20px;
            width: 75%;
            box-shadow: 0px 0px 36px -10px rgba(0, 0, 0, 0.25);
            z-index: 1200;
            margin-bottom: 200px;

            @include tablet {
                width: 90%;
                margin-left: 3%;
            }

            @include phone{
                width: 100%;
                padding: 0px;
                margin: 0;
            }

            @include bounceInDown($duration: 0.5s, $function: ease);
        }

        .close {
            color: #aaaaaa;
            float: right;
            font-size: 28px;
            font-weight: bold;
            cursor: pointer;

            @include phone{
                margin-right: 40px;
                margin-top: 20px;
            }
        }    
    }
}

.goals-container, .goals-container-main{
    width: 100%;
    max-width: 1040px;
    display: flex;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $first-font;
    .goal-infos {
        color: #000;
        &:visited, &:active {
            color: #000;
        }
    }
    .goal-line{
        width: 100%;
        hr{
            background-color: #dddddd;
            border: none;
            height: 1px;
            margin: 12px 0;
        }
        .top-container{
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            width: 100%;
            .left-container{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                flex: 2;
                font-size: 18px;
                img{
                    width: 30px;
                    margin-right: 10px;
                }
            }
            .right-container{
                flex: 1;
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                align-items: center;
                height: 30px;
                img {
                    width: 35px;
                }
                .goal-point-price {
                    font-size: 18px;
                    margin-right: 10px;
                }
            }   
        }
        &:last-of-type{
            hr{
                display: none;
            }
            margin-bottom: 50px;
        }
    }
}

@keyframes comeOn {
    0% {
        border-width: 0px 10px 10px 10px;
    }
    100% {
        border-width: 10px 10px 0px 10px;
    }
}