/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.title{
    font-family: $first-font;
    font-size: 44px;
    color: black;
    margin-top: 50px;
    font-weight: 100;
    margin-bottom: 30px;
    text-align: center;
    @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0s);
    @include tablet {
        font-size: 38px;
    }
    @include phone {
        font-size: 32px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
}
    