/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */
.slider-usages{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;

    .tns-outer{
        width: 300px;
    }

    .tiny-slider-usages {
        font-family: $first-font;
        font-weight: 300;

        > * {
            z-index: 0;
            height: inherit;
        }

        // SLIDES

        > .slide {
            overflow: hidden;
        }
        > .slide-b-usages {
            display: none;
        } 
        > .slide-c-usages {
            display: none;
        }

        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .usage-center {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                margin-top: 50px;
                .usage-container {
                    background-color: white;
                    width: 100px;
                    border-radius: 1000px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    .image-usage {
                        width: 35px;
                        height: 35px;
                        margin-bottom: 5px;
                    }
                }
                .usage-text {
                    margin-top: 10px;
                }
                .text-card {
                    font-size: 20px !important;
                    font-weight: 300;
                    color: red;
                }
            } 
        }
    }

    // SLIDER PART

    .tns-controls, .tns-nav {
        z-index: 1;
        position: absolute;
        display: none;
    }

    .tns-nav {
        text-align: center;
        width: 100%;
        bottom: 30px;

        > button {
            padding: 0;
            background-color: #fff;
            height: 12px;
            width: 12px;
            border-radius: 100px;
            border: none;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

            &.tns-nav-active {
                background-color: $primary-color;
            }
        }
    }

    .slider-arrows-usages {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: calc(100% - 40px);
        max-width: 1360px;
        align-self: center;

        > * {
            display: inline-block;
            background-image: url(/images/icons/arrow_carousel.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            cursor: pointer;
            width: 18px;
            height: 29px;
            transition: .2s;
            z-index: 1;

            &:hover {
                opacity: .7;
                transition: .2s;
            }

            &:before {
                display: none;
            }
        }

        .arrow-prev-usages {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            margin-left: 5px;
            transition: .2s ease-in-out;
            @include limit-screen{
                margin-left: 20px;
            }
        }
        .arrow-next-usages{
            margin-right: 5px;
            @include limit-screen{
                margin-right: 20px;
            }
            z-index: 12;
        }
    }
}
