/*
 * File: _small-device-alert.scss
 * File Created: Tuesday, 30th April 2019
 * Author: Oskar Gruszczynski (o.gruszczynski@i4b.pl)
 * -----
 * GridPocket SAS Copyright (C) 2018 All Rights Reserved
 * This source is property of GridPocket SAS.
 * Please email contact@gridpocket.com for more information.
 */

div.small-device-shadow{   
    @include fadeIn($duration: 0.2s, $function: ease);
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(black, .3);
    width: 100%;
    height: 100%;
    display: none;
    z-index:9999;
}

div.container-small-device-alert{
    position: fixed;
    z-index: 10000;
    bottom: 0;
    left:0;
    flex-direction: column;
    display: none;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: white;
    width: 100%;
    height: 140px;
    font-family: $first-font;
    font-size: 16px;
    @include slideInUp(
        $duration: .2s,
        $function: ease-in-out, 
    );
    @include tablet{
        font-size: 14px;
    }
    @include phone{
        font-size: 12px;
    }
    a{
        color: black;
    }
    div.small-device-alert-text-box{    
        flex-direction: row;
        display: flex;
        justify-content: center;
        width: 270px;
        align-items: center;
        align-content: center; 
        margin-top: 5px;
        margin-bottom: 5px;
        .small-device-alert-text{
            font-weight: bold;
            line-height: 15px;
            margin-top: 10px;
        }
       
        .mobile-icon{
            width: 50px;
            margin-right: 5px;
            padding:0px;
            height: 40px;
        }
    }
    
    div.mobile-btn{
        justify-content: center;
        font-family: $first-font;
        position: relative;     
        margin-top:5px;
        margin-bottom: 5px;
        width: 270px;
        height: 35px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        border: 0px;
        background-color: $primary-color;
        outline: none;
        font-size: 14px;
        transition: 0.25s ease-in-out;       
        cursor: pointer;
        text-decoration: none;
        color: black;
        .arrow-right {
            width: 20px;
            position: absolute;
            top: calc((100% - 20px)/2);
            left: 85%;
            @media #{$big-screen} {
                width: 30px;
                left: 85%;
                top: calc((100% - 30px)/2);
            }
            @media #{$very-large-screen} {
                width: 40px;
                left: 85%;
                top: calc((100% - 40px)/2);
            }
        }
        @media #{$big-screen} {
            font-size: 20px;
            width: 380px;
            height: 48px;
        }
        @media #{$very-large-screen} {
            font-size: 25px;
            width: 480px;
            height: 64px;
        }
        &:hover {
            box-shadow: inset 20em 0em $primary-color-hover;
            cursor: pointer;
            .arrow-right {
                animation: bounce 2s infinite;
            }
        }
    }

    div.web-btn{
        font-family: $first-font;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-top:5px;
        margin-bottom:5px;
        width: 270px;
        height: 35px;             
        color: rgb(61, 60, 60);
        font-weight: 300;
        font-size: 14px;
        cursor: pointer;
        border-radius: 3px;
        transition: .2s ease-in-out;             
    }
}