/*
* File: _restore.scss
* File Created: Monday, 3rd December 2018 3:42:34 pm
* Author: Paul Mazeau (paul.mazeau@gridpocket.com)
* -----
* Last Modified: Monday, 3rd June 2019 3:53:53 pm
* Modified By: Sacha Cochin (sacha.cochin@gridpocket.com>)
* -----
* GridPocket SAS Copyright (C) 2018 All Rights Reserved
* This source is property of GridPocket SAS.
* Please email contact@gridpocket.com for more information.
*/

.body-restore {
    background-color: white;
    position: relative;
    min-height: 100vh;

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        padding: inherit;

        @include tablet {
            padding-bottom: 80px;
        }

        @include phone {
            padding-bottom: 210px;
        }
    }

    .left-sign-in {
        width: 50%;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url("../../../images/login_bg.jpg");
        overflow: hidden;

        @include tablet{
            position: absolute;
            width: 100%;
            height: calc(100vh - 80px);
        }

        @include phone{
            height: calc(100vh - 210px);
            position: absolute;
            width: 100%;
        }

        .logo-eni {
            width: 100px;
            position: relative;
            margin-left: 10vh;
            margin-top: 5vh;
            @media #{$big-screen} {
                width: 150px;
            }
            @media #{$very-large-screen} {
                width: 200px;
            }
            @media #{$big-phone-screen} {
                width: 100px;
            }
            @include phone {
                display: none;
            }
        }
        .slogan-middle {
            position: absolute;
            text-align: center;
            bottom: 50px;
            width: 50%;
            .slogan {
                color: white;
                font-family: $third-font;
                font-weight: 400;
                font-size: 20px;
                @media #{$big-screen} {
                    font-size: 24px;
                }
                @media #{$very-large-screen} {
                    font-size: 32px;
                }
            }
        }
    }

    .right-sign-in {
        width: 50%;
        height: 100vh;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include tablet {
            background-color: transparent;
            height: calc(100vh - 80px);
        }
        @include phone {
            background-color: transparent;
            width: 100%;
            height: calc(100vh - 210px);
        }
        @include fadeIn( $duration: .2s, $function: ease-in-out, );
        .sub-container-getHash {
            background-color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include tablet{
                background-color: transparent;
            }
            @include phone{
                background-color: transparent;
            }
            @media #{$little-height} {
                overflow: scroll;
            }
            .title-log {
                font-family: $first-font;
                font-size: 32px;
                font-weight: 300;
                color: black;
                @media #{$big-screen} {
                    font-size: 48px;
                }
                @media #{$very-large-screen} {
                    font-size: 62px;
                }
                @include tablet {
                    color: white;
                }
                @include phone {
                    margin-top: 50px;
                    color: white;
                }
            }
            .first-subtitle {
                margin-top: 100px;
                font-size: 14px;
                font-family: $second-font;
                font-weight: 300;
                @media #{$big-screen} {
                    font-size: 16px;
                    margin-top: 150px;
                }
                @media #{$very-large-screen} {
                    font-size: 20px;
                    margin-top: 200px;
                }
                @include tablet {
                    color: white;
                }
                @include phone {
                    color: white;
                }
            }
            .second-subtitle {
                font-size: 14px;
                font-family: $second-font;
                font-weight: 300;
                margin-bottom: 32px;
                padding: 10px;
                @media #{$big-screen} {
                    font-size: 16px;
                }
                @media #{$very-large-screen} {
                    font-size: 20px;
                }
                @include tablet {
                    color: white;
                }
                @include phone {
                    color: white;
                }
            }
            form {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 80px;
                @media #{$little-height} {
                    margin-bottom: 100px;
                }
                input {
                    width: 240px; //    height: 35px;
                    border-radius: 50px;
                    border: none;
                    background-color: #eeeeee;
                    padding: 10px 15px;
                    font-family: $second-font;
                    margin-bottom: 20px;
                    outline: none;
                    transition-duration: 0.24s;
                    font-size: 14px;
                    @media #{$big-screen} {
                        font-size: 20px;
                        width: 350px; // height: 48px;
                    }
                    @media #{$very-large-screen} {
                        font-size: 25px;
                        width: 450px; // height: 64px;
                        margin-bottom: 30px;
                        padding: 20px;
                    }
                    &:focus::-webkit-input-placeholder {
                        opacity: 0.5;
                    }
                    &::placeholder {
                        color: black;
                    }
                    &:hover {
                        background-color: #e6e6e6;
                    }
                }
                div {
                    padding: 10px;
                    margin-bottom: 24px;
                    //border: 1px solid rgb(224, 69, 69);
                    border-radius: 10px;
                    display: none;
                    font-family: $second-font;
                    font-size: 16px;
                    color: $alert-color;
                    //background-color: rgba(224, 69, 69, 0.03);
                    transition: .2s ease-in-out;
                    @include fadeIn( $duration: .4s, $function: ease-in-out, );
                }
            }
            
            .alert-success{
                color: black;
                font-family: $second-font;
                font-size: 18px;
                display: none;
                margin-top: 150px;
                @include tablet {
                    color: white;
                }

                @include phone {
                    color: white;
                }
            }
            .return-btn-success{
                display: none;
                align-self: center;
                margin-top: 100px;
                text-align: center;
            }
        }
        .sub-container-sendNewPassword {
            background-color: white;
            text-align: center;
            display: none;
            flex-direction: column;
            justify-content: center;
            @media #{$little-height} {
                overflow-y: scroll;
                // margin-top: 50px;
                // margin-bottom: 100px;
            }
            @include tablet {
                background-color: transparent;
                width: 100%;
                color: white;
            }
            @include phone {
                background-color: transparent;
                width: 100%;
                color: white;
            }
            .title-log {
                font-family: $first-font;
                font-size: 32px;
                font-weight: 300;
                color: black;
                padding: 0px 20px;
                @media #{$big-screen} {
                    font-size: 48px;
                }
                @media #{$very-large-screen} {
                    font-size: 62px;
                }
                @include tablet {                    
                    color: white;
                }
                @include phone {
                    margin-top: 50px;
                    color: white;
                }
            }
            .first-subtitle {
                margin-top: 100px;
                font-size: 12px;
                font-family: $second-font;
                font-weight: 300;
                @media #{$big-screen} {
                    font-size: 16px;
                    margin-top: 150px;
                }
                @media #{$very-large-screen} {
                    font-size: 20px;
                    margin-top: 200px;
                }
                @include tablet {
                    margin-top: 50px;
                }
                @include phone{
                    margin-top: 50px;
                }
            }
            .second-subtitle {
                font-size: 12px;
                font-family: $second-font;
                font-weight: 300;
                margin-bottom: 32px;
                padding: 10px;
                @media #{$big-screen} {
                    font-size: 16px;
                }
                @media #{$very-large-screen} {
                    font-size: 20px;
                }
            }
            form {
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                .error-message {
                    color: $alert-color;
                    font-weight: $bold-weight;
                    margin-bottom: 20px;
                    font-family: $first-font;
                }
                input {
                    width: 240px; //  height: 35px;
                    border-radius: 50px;
                    border: none;
                    background-color: #eeeeee;
                    padding: 10px 15px;
                    font-family: $first-font;
                    margin-bottom: 20px;
                    outline: none;
                    transition-duration: 0.24s;
                    font-size: 14px;
                    @media #{$big-screen} {
                        font-size: 20px;
                        width: 350px; // height: 48px;
                    }
                    @media #{$very-large-screen} {
                        font-size: 25px;
                        width: 450px; // height: 64px;
                        margin-bottom: 30px;
                        padding: 20px;
                    }
                    &:focus::-webkit-input-placeholder {
                        opacity: 0.5;
                        color: black;
                    }
                    &::placeholder {
                        color: black;
                    }
                    &:hover {
                        background-color: #e6e6e6;
                    }
                    &.error {
                        border-color: $alert-color;            
                        color: $alert-color;
                    }
                }
                div {
                    padding: 10px;
                    color: #a94442;
                    display: none
                }
            }
            .spinner {
                display: none;
                margin-top: 100px;
            }
            .success-container {
                display: none;
                justify-content: center;
                align-content: center;
                align-items: center;
                flex-direction: column;
                .success-message {
                    font-family: $first-font;
                    font-size: 16px;
                    margin-top: 32px;
                    color: $button-color;
                }
                i {
                    font-size: 100px;
                    color: $primary-color;
                    top: 32px;
                    margin-bottom: 32px;
                    position: relative;
                }
            }
            
            .error-container {
                display: none;
                justify-content: center;
                align-content: center;
                align-items: center;
                flex-direction: column;
                .error-message {
                    font-family: $first-font;
                    font-size: 16px;
                    margin-top: 32px;
                    color: $alert-color;
                }
                i {
                    font-size: 100px;
                    color: $alert-color;
                    margin: 32px 0;
                    position: relative;
                }
            }
        }
    }

    footer {
        display: none;
        position: absolute;
        @include tablet {
            display: block;
        }
        @include phone {
            display: block;
            height: 210px;
        }
    }

    .container-mobile * {
        display: none;
    }

    #recaptcha-legal {
        margin-top: 30px;
        width: 90%;
        font-family: "Eni", sans-serif;
        font-size: 12px;
        color: #888;
        text-align: center;

        @media #{$big-screen} {
            bottom: 75px;
        }
                
        > a {
            color: black;
            text-decoration: none;
            font-size: 12px;
            margin: 0;
            :visited, :active {
                text-decoration: none;
            }
        }

        @include tablet {
            color: white;
            width: 90%;
            left: 5%;
            > a {
                color: white; 
            }
        }

        @include phone {
            color: white;
            width: 96%;
            padding: 0 10px;            
            > a {                
                color: white; 
            }
        }

    }
    
    @keyframes bounce {
        0%,
        55%,
        80%,
        100% {
            transform: translateX(0);
        }
        20% {
            transform: translateX(8px);
        }
        30% {
            transform: translateX(-2px);
        }
        45% {
            transform: translateX(1px);
        }
        55% {
            transform: translateX(-2px);
        }
    }
    @keyframes bounceReverse {
        0%,
        55%,
        80%,
        100% {
            transform: translateX(0);
        }
        20% {
            transform: translateX(-8px);
        }
        30% {
            transform: translateX(2px);
        }
        45% {
            transform: translateX(-1px);
        }
        55% {
            transform: translateX(2px);
        }
    }
    .modal {
        display: none;
        /* Hidden by default */
        position: fixed;
        /* Stay in place */
        z-index: 1;
        /* Sit on top */
        padding-top: 100px;
        /* Location of the box */
        left: 0;
        top: 0;
        width: 100%;
        /* Full width */
        height: 100%;
        /* Full height */
        overflow: auto;
        /* Enable scroll if needed */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */
    }
    
    .footer-links {
        bottom: 0;
        position: fixed;
        width: 50%;
        align-items: center;
        display: flex;
        justify-content: left;
        background-color: #f7f7f7;
        height: 50px;
        font-family: $first-font;
        font-size: 12px;
        text-decoration: underline;
        color: black;
        font-weight: 300;
        @media #{$big-screen} {
            font-size: 16px;
            margin-top: 65px;
            height: 70px;
        }
        @media #{$very-large-screen} {
            font-size: 20px;
            margin-top: 65px;
            height: 90px;
        }
        @media screen and (max-width: 1000px) {
            display: none;
        }
        a {
            margin-right: 10px;
            margin-left: 10px;
            font-size: 14px;
            color: inherit;
            cursor: pointer;
        }
        .gp {
            text-align: right;
            width: 45%;
            @media #{$big-screen} {
                bottom: 25px;
                .slogan{
                    font-size: 16px;
                }
            }
            @media #{$very-large-screen} {
                bottom: 35px;
                .slogan{
                    font-size: 20px;
                }
            }
            .slogan{
                font-size: 12px;
                font-weight: 300;
                color: black;
                font-family: $first-font;
                @media #{$big-screen} {
                    font-size: 16px;    
                }
                @media #{$very-large-screen} {
                    font-size: 20px;
                }
                .gp-title{
                    font-weight: 400;
                    color: black;
                    text-decoration: none;
                    margin-left: -1px;
                }
            }
        }
    }

    /* Modal Content */
    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 80%;
    }
    /* The Close Button */
    .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }
    .close:hover,
    .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
}