/*
* File: _survey.scss
* File Created: Wednesday, 6th February 2019 5:17:35 pm
* Author: Sacha Cochin (sacha.cochin@gridpocket.com)
* -----
* Last Modified: Wednesday, 6th February 2019 5:20:17 pm
* Modified By: Sacha Cochin (sacha.cochin@gridpocket.com>)
* -----
* GridPocket SAS Copyright (C) 2019 All Rights Reserved
* This source is property of GridPocket SAS.
* Please email contact@gridpocket.com for more information.
*/

.survey-body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    .prev-btn{
        display: none;
        margin-bottom: 50px;
        margin-left: calc(50% - 190px);
        @include limit-screen{
            margin-left: calc(50% - 135px);
        }
        @include tablet{
            margin-left: calc(50% - 135px);
        }
        @include phone{
            margin-left: calc(50% - 135px);
        }
        @include little-phone{
            margin-left: calc(50% - 135px);
        }
    }
    
    .next-btn{
        margin-left: calc(50% - 190px);
        display: none;
        @include limit-screen{
            margin-left: calc(50% - 135px);
        }
        @include tablet{
            margin-left: calc(50% - 135px);
        }
        @include phone{
            margin-left: calc(50% - 135px);
        }
        @include little-phone{
            margin-left: calc(50% - 135px);
        }
    }
    
    .checkbox-container {
        padding-top: 50px;
    }
    
    .loading {
        position: relative;
        width: 100%;
        display: flex;
        top: 50px;
        left: 0;
        height: calc(100vh - 200px);
        flex-direction: column;
        @include limit-screen{
            top: 30px;
        }
        @include tablet {
            top: 20px;
        }
    
        .loader-3-dots{
            height: 100%;
            @include limit-screen{
                margin-left: 70px;
                margin-top: 30px;
            }
            @include tablet {
                .desktop-loader{
                    margin-top: 40px;
                }
            }
            @include phone{
                .mobile-loader{
                    padding-right: 0px;
                    justify-content: center;
                    .dot-2{
                        right: unset;
                    }
                    .dot-1 {
                    }
                    .dot{
                        margin: 0;
                    }
                }
            }
    
            @include little-phone{
                .mobile-loader{
                    padding-right: 0px;
                    justify-content: center;
                    .dot-2{
                        right: unset;
                    }
                    .dot-1 {
                    }
                    .dot{
                        margin: 0;
                    }
                }
            }
        }
    
        .spinner {
            height: 60px;
            width: 60px;
        }
    }
}






#survey {
    @include fadeIn($duration: 0.3s, $function: ease-in-out);
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $second-font;
    font-size: 20px;
    flex: 1;
    margin-bottom: 30px;
    position: relative;

    .container {
        width: 100%;
        max-width: 1360px;
    }
    .btn-default {
        color: #333;
        background-color: #fff;
        border-color: #ccc;
    }
    
    .prev-btn {
        text-align: center;
    }
    h3 span {
        color: grey;
        font-size: 15px;
    }
    .ionicon {
        font-size: 3em;
    }
    .text_container p {
        font-size: 10px;
    }
    .cachet_etat {
        width: 400px;
    }
    .text-center {
        text-align: center;
    }
    @media screen and (max-width: 992px) {
        .cachet_etat {
            width: 320px;
        }
    }
    .line_timeline,
    .icon_timeline {
        position: relative;
        display: inline-block;
    }
    .line_timeline {
        width: 100px;
        margin-left: 2px;
        border-bottom: 2px solid #b5c8d4;
        margin-right: 2px;
        margin-left: 4px;
    }
    .icon_timeline {
        top: 3px;
        margin: -4px;
    }
    .error_text_incomplete_part {
        text-align: center;
        color: #ff3737d3;
        display: none;
        font-size: 14px;
        margin-top: 5%;
    }
    .nav_part {
        display: inline;
        height: 30px;
    }
    .nav_part .part_label {
        font-size: 15px;
        height: 55px;
        cursor: pointer;
        position: absolute;
        left: 50%;
        bottom: -25%;
        transform: translate(-50%);
        width: 124px;
        color: #b5c8d4;
        z-index: 8;
    }
    i.fa-exclamation {
        color: #b5c8d4;
        display: none;
    }
    .nav_part.previous_active .line_timeline,
    .nav_part.active .line_timeline,
    .nav_part.previous_active .icon,
    .nav_part.active .icon {
        border-color: #ffd300;
    }
    .nav_part.active .part_label,
    .nav_part.previous_active .part_label,
    .nav_part.previous_active i.fa-check,
    .nav_part.previous_active i.fa-exclamation {
        color: #1a262f;
    }
    .nav_part.active i.fa-exclamation,
    .nav_part.previous_active i.fa-check {
        color: white;
    }
    .icon {
        width: 24px;
        height: 24px;
        line-height: 20px;
        border: 2px solid #b5c8d4;
        border-radius: 50%;
        display: block;
        position: relative;
        top: 4px;
    }
    .title .info_button {
        text-align: right;
        padding-top: 20px;
        font-size: 120%;
        color: #8f9a7e;
        cursor: pointer;
        position: relative;
        top: 5px;
    }
    .title .info_button i {
        font-size: 160%;
        vertical-align: bottom;
    }
    .complete .icon {
        background: #b5c8d4;
    }
    .active .icon,
    .previous_active.complete .icon {
        background: #ffd300;
    }
    /* Complete part */
    .nav_part i.fa-check {
        display: none;
        color: white;
    }
    /* Complete part */
    .nav_part.complete i.fa-check {
        display: inline;
    }
    /* Complete part */
    .nav_part.warning i.fa-exclamation {
        display: inline;
    }
    .nav_part.warning .icon,
    .nav_part.complete .icon {
        top: 1px;
        margin-top: -11px;
    }
    @media screen and (max-width: 1199px) and (min-width: 992px) {
        .btn-group.btn-group-justified .btn {
            padding: 5px;
        }
    }
    @media screen and (max-width: 992px) {
        .line_timeline {
            width: 80px;
        }
        .part .col-md-4 {
            border: none !important;
        }
    }
    .mobile_nav {
        display: none;
        margin-bottom: 20px;
    }
    @media screen and (max-width: 768px) {
        .nav_container.pc {
            display: none;
        }
        .mobile_nav {
            display: block;
            margin-top: 15px;
        }
        .mobile_nav .part_label {
            display: none;
        }
        .mobile_nav .line_timeline {
            width: 11%;
            border-width: 2px;
            margin-left: 4px;
            top: 0px;
        }
        .mobile_nav .fa-stack {
            font-size: 18px;
            cursor: pointer;
        }
        .mobile_nav .active .part_label {
            display: block;
            top: 40px;
            font-size: 19px;
        }
        .mobile_nav .active[data-navcustom="part1"] .part_label {
            transform: translate(-15%);
            text-align: left;
        }
        .mobile_nav .active[data-navcustom="part5"] .part_label {
            transform: translate(-87%);
            text-align: right;
        }
    }
    .pd30 {
        padding: 30px;
    }
    .pd40 {
        padding: 40px;
    }
    .pd20 {
        padding: 20px;
    }
    .next_container {
        text-align: center;
    }
    /*
  .form-control.active{
    margin-top: 3px;
  }
  */
    .part label.other p {
        position: relative;
        top: 50%;
    }
    .part .active p {
        color: black !important
    }
    .part {
        position: fixed;
        z-index: 1;
        top: 0;
        visibility: hidden;
        opacity: 0;
    }
    .display_part {
        position: relative;
        display: block;
        visibility: visible;
        height: initial;
        transition: all ease 1s;
        opacity: 1;
    }
    .part .col-md-4 {
        padding-right: 27px;
        padding-left: 27px;
        
    }
    .part .col-md-4:first-child {
        border-left: none;
        position: relative;
        border-right: solid #1a262f 1px;
    }
    .part .col-md-4:last-child {
        border-right: none;
        position: relative;
        border-left: solid #1a262f 1px;
    }
    .question {
        /*box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.3);
    border-radius:4px;*/
        margin-bottom: 30px;
        /*background: rgba(255,255,255,0.8);*/
        padding: 10px;
    }
    .part .question h3 {
        text-transform: none;
    }
    .radio_group {
        display: none;
    }
    /**SELECT SURCHAGE **/
    .btn-group button.dropdown-toggle {
        min-width: 100%;
        text-align: right;
        overflow: hidden;
    }
    .btn-group ul.dropdown-menu {
        min-width: 100%;
    }
    .btn-group button.dropdown-toggle span.selected {
        float: left;
        width: 95%;
        overflow: hidden;
        text-align: left;
    }
    @media screen and (max-width: 544px) {
        .answers {
            text-align: center;
        }
    }
    .answers+.input {
        margin-top: 3px;
    }
    /********* BTN WITH SVG ****************/
    .part .question .pickup_answer.btn {
        width: 95px;
        height: 95px;
        margin-top: 3px;
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;
        position: relative;
        z-index: 1;
        /*overflow: hidden;*/
    }
    .pickup_answer.btn-default.active,
    .pickup_answer.btn-default.active.focus,
    .pickup_answer.btn-default.active:focus,
    .pickup_answer.btn-default.active:hover,
    .pickup_answer.btn-default.focus:active,
    .pickup_answer.btn-default:active:focus,
    .pickup_answer.btn-default:active:hover {
        border-color: #ffd300 !important;
        border: solid 2px;
        color: black !important;
        background-color: white !important;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.4);
    }
    .btn-default.active,
    .bootstrap-select .btn-default.active,
    .btn-default.active:focus,
    .btn-default.focus:active,
    .btn-default:active:focus,
    .btn-default:active:hover {
        color: #1a262f !important;
        border-color: #d4d4d4 !important;
        background-color: #ffd300 !important;
    }
    .part .question .btn .img_container {
        height: 60px;
        margin-bottom: 5px;
        padding-top: 15px;
    }
    .part .question .btn .img_container .default {
        max-height: 90%;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
    }
    .part .question .btn .img_container>* {
        z-index: 0;
        vertical-align: middle;
        filter: gray;
        filter: grayscale(100%) contrast(70%) brightness(125%);
        -webkit-filter: grayscale(100%) contrast(70%) brightness(125%);
        -ms-filter: grayscale(100%) contrast(70%) brightness(125%);
        text-align: center;
        display: inline-block;
    }
    .part .question .btn .area_click {
        cursor: pointer;
    }
    .part .question btn-default:hover {
        background-color: #eeeeee !important;
    }
    .part .question .btn:hover .img_container>*,
    .part .question .btn.active .img_container>* {
        filter: none;
        -webkit-filter: none;
        -ms-filter: none;
    }
    .part .question .btn .text_container {
        font-size: .8em;
        position: relative;
    }
    .part .question .pickup_answer.btn.active p {
        color: #ffd300;
    }
    .rangeslider__fill {
        background: #ffd300 !important;
    }
    .rangeslider.rangeslider--horizontal {
        height: 15px;
    }
    .rangeslider__handle {
        top: -6px !important;
        width: 27px !important;
        height: 27px !important;
        box-shadow: none !important;
    }
    .part .question .rangeslider--horizontal {
        margin-top: 30px;
    }
    input[type="range"] {
        padding: 0;
        height: 20px;
        margin-top: 15px;
    }
    .pickup_answer.text-center.btn.btn-default.active::before {
        content: "\f00c";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        width: 20px;
        height: 20px;
        position: absolute;
        top: -10px;
        color: white;
        right: -10px;
        z-index: +1;
        display: block;
        border-radius: 50%;
        background: #ffd300;
    }
    .btn_previous.btn-secondary-gpo:after {
        content: '';
    }
}

#finish_button{
    margin-left: calc(50% - 190px);
    justify-content: center;
    font-family: $first-font;
    // position: absolute;
    width: 270px;
    height: 35px;
    // bottom: 30px;
    margin-bottom: 0;
    margin-top: auto;
    border-radius: 50px;
    display: none;
    align-items: center;
    border: 0px;
    background-color: $primary-color;
    outline: none;
    font-size: 14px;
    transition: 0.25s ease-in-out;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    color: black;
    @media #{$big-screen} {
        font-size: 20px;
        width: 380px;
        height: 48px;
    }
    @media #{$very-large-screen} {
        font-size: 25px;
        width: 480px;
        height: 64px;
    }
    @include limit-screen{
        margin-left: calc(50% - 135px);
    }
    @include tablet{
        margin-left: calc(50% - 135px);
    }
    @include phone{
        margin-left: calc(50% - 135px);
    }
    @include little-phone{
        margin-left: calc(50% - 135px);
    }
    
    &:hover {
        box-shadow: inset 20em 0em $primary-color-hover;
        cursor: pointer;
    }
}

.hidden{
    visibility: hidden;
}