.change-password-modal {
    $bg-transparent-color: transparentize($alert-color, 0.8);

    .error-message {
        display: none;
    }

    &.modal {
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        z-index: 6;
    }

    .dark-background {
        position: fixed; /* Stay in place */
        padding-top: 100px; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4);
        z-index: -1;

        @include fadeIn($duration: 0.5s, $function: ease);
    }


    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border-radius: 10px;
        font-family: $first-font;
        font-weight: 300;
        font-size: 14px;
        width: 35%;

        > .spinner {
            display: none;
        }

        > h2 {
            font-size: 20px;
            font-weight: $title-weight;
        }

        @include bounceInDown($duration: 0.5s, $function: ease);

        .password-form {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: left;
            align-items: left;
            text-align: left;

            > input {
                width: 95%;
                height: 26px;
                margin-bottom: 10px;
                border-radius: 30px;
                background-color: #f7f7f7;
                border: 1px solid #c2c2c2;
                font-family: 'Roboto', sans-serif;
                font-size: 13px;
                padding-left: 20px;
                outline: none;

                &.error {
                    border-color: $alert-color;
                    background-color: $bg-transparent-color;
                    color: $alert-color;
                }
            }

            > label {
                margin: 12px 0px;
            }

            > button {
                border: none;
                border-radius: 30px;
                width: 40%;
                color: #000;
                background-color: $primary-color;
                transition: .2s ease-in-out;
                font-family: 'Roboto', sans-serif;
                font-size: 13px;
                position: relative;
                margin-left: 60%;
                margin-top: 20px;
                padding: 10px 8px;

                &:hover {
                    background-color: $primary-color-hover;
                    cursor: pointer;
                }
            }
        }
    }

    .error-message {
        color: $alert-color;
        font-weight: $bold-weight;
        margin-bottom: 20px;
    }

    .close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }

    .close:hover,
    .close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
}
