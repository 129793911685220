%primary-button {
    border-radius: 30px;
    font-size: 16px;
    background-color: $secondary-color;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: .2s ease-in-out;
    padding: 7px 20px;
    &:hover {
        background-color: $secondary-color-hover;
    }
}

%secondary-button {
    @extend %primary-button;
    
    background-color: $primary-color;
    color: $secondary-color;
}

%share-button {
    background-image: url('/images/icons/share.png');
    border: none;
    background-color: transparent;
    cursor: pointer;
    
    width: 30px;
    height: 30px;
}

.alert-icon {
    color: #d60036;
}