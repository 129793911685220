/*
* File: _admin-dashboard.scss
* File Created: Wednesday, 26th December 2018 10:41:17 am
* Author: Paul Mazeau (paul.mazeau@gridpocket.com)
* -----
* Last Modified: Tuesday, 19th February 2019 11:29:19 am
* Modified By: Sacha Cochin (sacha.cochin@gridpocket.com>)
* -----
* GridPocket SAS Copyright (C) 2018 All Rights Reserved
* This source is property of GridPocket SAS.
* Please email contact@gridpocket.com for more information.
*/

.admin-dashboard {
    font-family: $first-font;
    background-color: white;
    width: 100%;
    height: 100%;
    position: relative;
    @include fadeIn($duration: 0.3s, $function: ease-in-out, $delay: 0.1s);

    #download-csv-button {
        width: 40px;
        height: 40px;
        background: #e0e0e0;
        text-align: center;
        padding: 10px;
        color: black;
        border-radius: 4px;
        margin-left: 12px;
    }

    .refresh-button {
        display: none;
        width: auto;
        padding: 10px 32px;
        margin-top: 45px;
        background-color: $primary-color;
        transition: .2s ease-in-out;
        color: black;
        &:hover {
            cursor: pointer;
            text-decoration: none;
            background-color: $primary-color-hover;
        }
    } 

    .overlay {
        .loader-3-dots {
            margin-top: 33vh;
        }
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 999;
        background: rgba(0, 0, 0, 0.3);
    }
    .admin-dashboard-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .top-date-selector-container {
            display: flex;
            width: 70%;
            background-color: #f7f7f7;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            padding: 16px 32px;
            margin-top: 16px;
            .picker-container {
                margin-top: 16px;
                display: flex;
                justify-content: center;
                flex-direction: row;
                
                .date-selector {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                    @include phone{
                        flex-direction: column;
                    }
                    .date-picker {
                        border: 1px solid grey;
                        width: 240px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        align-items: center;

                        @include tablet {
                            width: 170px;
                            height: 20px;
                        }

                        @include phone{
                            width: 170px;
                            height: 20px;
                            &:first-of-type{
                                margin-bottom: 20px;
                            }
                        }

                        .from-title {
                            background-color: $primary-color;
                            width: 40px;
                            height: 40px;
                            position: relative;
                            border: 1px solid grey;
                            border-left: 0px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            @include tablet{
                                width: 20px;
                                height: 20px;
                            }
                            @include phone{
                                width: 20px;
                                height: 20px; 
                            }
                        }
                        .from-date,
                        .to-date {
                            position: relative;
                            background-color: #f7f7f7;
                            width: 200px;
                            height: 100%;
                            font-weight: 500;
                            outline: none;
                            border: none;
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.5);
                        }
                    }
                    .space-between {
                        width: 40px;
                        height: 100%;
                    }
                }
            }
        }
        .admin-stats-container {
            display: flex;
            background-color: #f7f7f7;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            align-items: center;
            width: 70%;
            padding: 16px 32px;
            margin-top: 32px;

            i {
                margin-bottom: 12px;
            }

            .stats-container {
                display: flex;
                justify-content: center;
                text-align: center;
                align-self: center;
                margin-top: 16px;
                width: 100%;
                .align-center-container {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                    width: 800px;
                    flex-wrap: wrap;
                    .stats {
                        height: 140px;
                        width: 200px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        .number-stats {
                            font-size: 28px;
                            font-weight: 300;
                        }
                        .title-stats {
                            font-size: 16px;
                        }
                        .positive {
                            color: $button-color;
                        }
                        .negative {
                            color: $alert-color;
                        }
                    }
                }
            }
        }
        .admin-chart-analysis {
            display: flex;
            background-color: #f7f7f7;
            justify-content: space-around;
            flex-direction: column;
            text-align: center;
            width: 70%;
            padding: 16px 32px;
            margin-top: 32px;
            position: relative;
            .title {
                margin-bottom: 20px;
            }
            .top-button-container {
                display: flex;
                justify-content: space-around;
                flex-direction: row;
                margin-bottom: 50px;
                select {
                    padding: 8px 12px;
                    border: none;
                    position: absolute;
                    left: 100px;
                    font-size: 14px;
                    box-shadow: none;
                    background: transparent;
                    background-image: none;
                    -webkit-appearance: none;
                    width: 200px;
                    height: 40px;
                    background: $primary-color url("../../../images/icons/chevron_down_solid.svg") no-repeat 90% 50%;
                    background-size: 10px;
                    transition: .2s ease-in-out;
                    margin: 10px 5px;
                    border-radius: 0px;
                    &:focus {
                        outline: none;
                    }
                    &:hover {
                        cursor: pointer;
                        background-color: $primary-color-hover;
                    }
                    @media #{$big-screen} {}
                    @media #{$very-large-screen} {}
                }
                .button-container {
                    display: flex;
                    flex-direction: row;
                    position: absolute;
                    right: 100px;
                    height: 100%;
                    .button {
                        width: 100%;
                        font-size: 14px;
                        background-color: $primary-color;
                        margin: 10px 5px;
                        height: 40px;
                        padding: 0 16px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        transition: .2s ease-in-out;
                        &:hover {
                            background-color: $primary-color-hover;
                            cursor: pointer;
                        }
                    }
                    .active {
                        background-color: rgba($primary-color-hover, 0.8);
                        &:hover {
                            background-color: rgba($primary-color-hover, 0.8);
                        }
                    }
                }
            }
        }
        .admin-chart-pie {
            display: flex;
            background-color: #f7f7f7;
            justify-content: space-around;
            flex-direction: column;
            text-align: center;
            padding: 16px 32px;
            margin-top: 32px;
            width: 70%;
            position: relative;
            align-items: center;
            .title {
                margin-bottom: 20px;
            }
            .pie-chart-container {
                display: flex;
                justify-content: center;
                flex-direction: row;
                width: 100%;
                .chart-legend {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    position: absolute;
                    left: 50px;
                    top: 110px;

                    @include tablet{
                        display: none;
                    }

                    @include phone{
                        display: none;
                    }

                    .legend {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin: 5px 0;
                        width: 350px;
                        .color {
                            background-color: $primary-color;
                            width: 45px;
                            height: 25px;
                            border-radius: 5px;
                        }
                        .title-legend {
                            margin-left: 10px;
                            font-size: 14px;
                        }
                    }
                }
                #pie-chart{
                    @include tablet{
                        width: 350px;
                        height: 150px;
                    }
                }
            }
            .bottom-button-container {
                display: flex;
                flex-direction: row;
                width: 70%;
                .button {
                    width: 100%;
                    font-size: 14px;
                    background-color: $primary-color;
                    margin: 10px 5px;
                    height: 40px;
                    padding: 0 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    transition: .2s ease-in-out;
                    &:hover {
                        background-color: $primary-color-hover;
                        cursor: pointer;
                    }
                }
                .active {
                    background-color: rgba($primary-color-hover, 0.8);
                    &:hover {
                        background-color: rgba($primary-color-hover, 0.8);
                    }
                }
            }
        }
        .admin-ecotroks-analysis {
            display: flex;
            background-color: #f7f7f7;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            align-items: center;
            padding: 16px 32px;
            margin-top: 32px;
            margin-bottom: 64px;
            width: 70%;
            .top-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @include tablet{
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                @include phone{
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .top-users {
                    display: flex;
                    justify-content: flex-start;
                    width: 50%;
                    flex-direction: column;
                    align-items: center;
                    height: 100%;
                    margin-top: 16px;
                    @include tablet {
                        width: 100%;
                    }

                    @include phone{
                        width: 100%;
                    }
                    .title-top-users {
                        font-size: 18px;
                        font-weight: 300;
                        margin-bottom: 16px;
                        margin-top: 10px;
                    }

                    .top-users-container{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        

                        .user-card{
                            background-color: white;
                            width: 100%;
                            display: flex;
                            padding: 5px 10px;
                            margin-top: 10px;

                            &:first-of-type{
                                margin-top: 0px;
                            }

                            .eco-won{
                                margin-right: 0;
                                margin-left: auto;
                                padding-left: 20px;
                                color: $button-color;
                            }
                            .eco-spent{
                                margin: 0 20px;
                                color: $alert-color;
                            }
                            .fa-trophy {
                                color: $primary-color;
                                margin-left: 16px;
                                font-size: 16px;
                            }
                        }
                    }
                }
                .ecotroks-amount-container {
                    display: flex;
                    justify-content: center;
                    width: 50%;
                    flex-direction: column;
                    align-items: center;
                    height: 100%;
                    margin-top: 16px;
                    width: 70%;

                    @include tablet {
                        width: 100%;
                    }

                    @include phone {
                        width: 100%;
                    }
                    .title-amount-ecotroks {
                        font-size: 18px;
                        font-weight: 300;
                        margin-bottom: 16px;
                    }
                    .amount-container {
                        background-color: white;
                        margin-top: 5px;
                        width: 90%;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        .amount {
                            color: $primary-color;
                            font-size: 60px;
                            font-weight: 100;
                            margin-top: 50px;
                            text-align: center;
                            align-items: center;
                            img {
                                width: 50px;
                                margin-left: 10px;
                                margin-top: -10px;
                            }
                        }
                        .evolution-stats {
                            margin-bottom: 40px;
                        }
                        .positive {
                            color: $button-color;
                        }
                        .negative {
                            color: $alert-color;
                        }
                        .subtitle-evolution {
                            color: #bdbdbd;
                            font-weight: 100;
                            margin-bottom: 16px;
                        }
                    }
                    .button-ecotroks {
                        width: auto;
                        padding: 10px 32px;
                        margin-top: 45px;
                        background-color: $primary-color;
                        transition: .2s ease-in-out;
                        color: black;
                        &:hover {
                            cursor: pointer;
                            text-decoration: none;
                            background-color: $primary-color-hover;
                        }
                    }
                }
            }
            .bottom-container {
                width: 100%;
                #chart-ecotroks {
                    margin-bottom: 10px;
                }
                .title-chart {
                    font-size: 18px;
                    font-weight: 300;
                    margin-top: 32px;
                    margin-bottom: -16px;
                }
                .legend-container{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    .legend {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin: 0px 16px;
                        width: auto;
                        .color {
                            background-color: $primary-color;
                            width: 45px;
                            height: 25px;
                            border-radius: 5px;
                        }
                        .title-legend {
                            margin-left: 10px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .title{
        margin: 0;
    }
}

.admin-shop, .admin-users, .admin-movingclients {
    font-family: $first-font;
    // * {
    //     margin: 0;
    //     padding: 0px;
    // }
    // display: flex;
    background-color: white;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    
    .btn-primary {
        background: #ffd42a;
        border-color: #ffd42a;
        color: black;
    }

    .admin-shop-container, .admin-users-container {
        max-width: 1200px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
        align-self: center;
        .box {
            display: flex;
            background-color: #f7f7f7;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            padding: 16px 32px;
            margin-top: 16px;
            .title{
                margin: 0;
            }
        }
    }
    hr.hr-style{
        top: 0;
    }
}

.admin-users {
    #user_table_length {
        margin-bottom: 8px;
    }

    .caret {
        color: black;
    }
    
    .contract_table {
        background-color: #ffd42a !important;
        width: 100%;

        >tbody>tr:nth-of-type(2n+1) {
            background-color: rgba(250, 250, 250, 0.1) !important;
        }
        
        >tbody>tr:hover {
            background-color: rgba(250, 250, 250, 0.3) !important;
            cursor: pointer;
        }
    }

    table.user_table >tbody>tr.odd,
    table.user_table>tbody>tr.odd+tr.details {
        background-color: rgba(200, 200, 200, 0.3);
    }
    
    tr.details {
        >td {
            padding: 20px !important;
            padding-right: 40px !important;
            padding-left: 40px !important;
            color: black;
        }
        tr {
            background-color: transparent !important;
        }
    }

    .contract_table mark {
        background: rgba(255, 255, 255, 1);
        color: #ffd42a;
    }
    
    mark {
        background: #ffd42a;
        color: black;
    }
    
    th {
        text-align: center !important;
    }
    
    .table-responsive {
        clear: both;
    }
    
    .options {
        display: flex;
        flex-direction: row;
        align-items: end;
        position: relative;
        float: right;

        >div {
            display: flex;
            margin: 3px;
        }
        div.active span {
            color: #ffd42a;
        }
        .checkbox_group {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            top: 3px;
        }
        .checkbox_group span {
            margin-right: 7px;
            font-size: 12px;
            color: #888;
            
        }
    }

    .modal {
        padding-top: 0;
        .modal-content {
            width: 100%;
            padding: 0;
        }
    }
}