.body-rejoin-yes {
    background-color: white;
    font-family: "Open Sans";
    
    /*== HEADER ==*/
    header {
        background-color: #F7F7F7;
        margin-bottom: 50px;
    }
    
    
    header img {
        width: 85px;
    }
    
    @media screen and (max-width:767px) {
        header { margin-bottom : 20px }
        header img { width: 37px; }
    }
    
    h1 {
        font-size: 55px;
        text-align: center;
        margin-bottom: 30px;
        font-family: "Eni";
        font-weight: 500;
    }
    
    /*== INTRO BLOCK ==*/
    h2 { 
        font-family:"Eni";
        font-size:32px; }
    
    @media screen and (max-width:1199px) {
        h1 { font-size: 50px; }
    }
    
    @media screen and (max-width:991px) {
        h1 br { display:none; }
    }
    
    @media screen and (max-width:767px) {
        h1 { 
            font-size: 35px;
            margin-bottom: 25px;
        }
    }
    
    .footer-rejoin {
        background-color: #272A34;
        padding: 30px 0;
        text-align: right;
    }
    
    .footer-rejoin ul {
        list-style: none;
        margin: 0;
        padding: 0
    }
    
    .footer-rejoin ul li {
        display: inline-block;
        border-right: 1px solid white
    }
    
    .footer-rejoin ul li a {
        color: white;
        padding: 7px 40px;
        font-size: 14px;
    }
    
    .footer-rejoin ul li a:hover {
        color: white
    }
    
    .footer-rejoin ul li:last-child {
        border: none
    }
    
    .footer-rejoin ul li:last-child a {
        padding-right: 0;
    }
    
    @media (max-width:767px) {
        .footer-rejoin ul li {
            display: block;
            border: none
        }
    
        .footer-rejoin ul li a,
        .footer-rejoin ul li:last-child a {
            padding: 7px 40px
        }
    
        .footer-rejoin {
            text-align: center;
        }
    }
    
    
    .intro-bloc {
        display: flex;
        width: 100%;
        margin-bottom: 40px;
        position: relative;
    }
    
    .intro-text {
        flex: 1;
        background-color: #F7F7F7;
        padding-top: 30px;
        padding-right: 30px;
        position: relative;
        z-index: 20;
        padding-bottom: 30px;
    }
    
    .intro-text p {
        font-family: "Open Sans";
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 1.3;
    }
    
    .intro-bloc h2 {
        margin-bottom: 15px;
        font-size:40px;
    }
    
    .intro-bloc h2 br { display:none; }
    
    .intro-pic {
        width: 500px;
        min-height: 200px;
        background-image: url("/images/rejoin/intro_pic.jpg");
        background-size: 100%;
        background-color: #F2F2F2;
        background-position: bottom;
        background-repeat: no-repeat;
        position: relative;
        z-index: 20;
    }
    
    .intro-bg {
        width: 400%;
        background-color: #F7F7F7;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
    }
    
    @media screen and (max-width:1199px) {
        .intro-pic {
            width: 300px;
            background-size: 100%;
            background-position: bottom;
        }
    
         .intro-text { padding: 40px 30px 10px; }
    
        .intro-text p {
                font-family: "Open Sans";
                font-size: 16px;
        }
    
        .intro-bg { display: none; }
        .intro-bloc h2 { font-size:30px; }
        .intro-bloc h2 br { display:none; }
    }
    
    @media screen and (max-width:991px) {
        .intro-bloc { flex-direction: column; }
        .intro-pic {
            width: 100%;
            background-size: 60%;
        }
        .intro-bloc h2 { margin-bottom: 5px; }
        .intro-text p {
            font-family: "Open Sans";
            font-size: 16px;
        }
    }
    
     @media screen and (max-width:767px) {
        .intro-bloc h2 { font-size:30px; }
        .intro-text{
            padding-right: 15pgit commit –m “Description du commit”x;
            padding-top: 25px;
            padding-left:15px;
        }
        .intro-pic { display: none; }
    } 
    
    
    /*== CTA ==*/
    .cta-button {
        display: table;
        margin:0 auto;
        background-color: #FED416;
        padding: 15px 60px;;
        text-decoration: none;
        color: black;
        font-size: 24px;
        border-radius: 29px;
        text-align: center;
        margin-bottom: 40px;
    }
    
    .cta-button:hover{
        text-decoration: none;
        color:black;
        background-color: #FFDE40;
    }
    
    .button-bloc a {
        color:black;
        text-decoration:underline;
        font-size:16px;
    }
    
    @media screen and (max-width: 767px) {
        .cta-button { margin-bottom: 30px; }
    }
    
    /*== ARGUMENT BLOCK ==*/
    .argument-bloc {
        width:100%;
        height: 350px;
        overflow:hidden;
    }
    
    .argument-content {
        display:flex;
        position: relative;
    }
    
    .argument-text {
        font-family:"Eni";
        height: 350px;
        flex:1
    }
    
    .argument-pic {
        width:600px;
        background-repeat: no-repeat;
        background-size:100%;
        background-position: bottom;
        bottom: 0;
        position:relative;
    }
    
    .argument-text h2  {
        padding-top:120px;
        font-size:40px;
        line-height: 0.9;
    }
    
    .argument-text h2 span  {
        font-size:24px;
        font-family: "Open Sans";
    }
    
    .arrow-scroll {  
         background-image:  url("/images/rejoin/arrow_scroll.svg");
         width:24px;
         height: 24px;
         background-position: center center;
         background-repeat: no-repeat;
         position:absolute;
         left:50%;
         transform: translateX(-50%);
         bottom: 50px;
    }
    
    @media screen and (max-width:1199px) {
        .argument-text { padding-left: 30px; }
        .argument-text h2  {
            font-size:30px;
            line-height: 0.9;
        }
        .argument-text h2 span  {
            font-size:16px;
            font-family: "Open Sans";
        }
    }
    
    @media screen and (max-width:991px) {
        .argument-bloc { height: 270px; }
        .argument-text{ height: 270px; }
        .argument-pic { width:500px; }
        .argument-text { padding-left: 40px; }
        .argument-text h2  {
            padding-top:10%;
            font-size:30px;
            line-height: 0.9;
        }
        .arrow-scroll { display: none;}
    }
    
    @media screen and (max-width:767px) {
    
        .argument-content { flex-direction: column; }
      
        .argument-text {
            text-align:center;
            padding-left:0;

        }


        .body-rejoin-yes .intro-text {
            padding: 30px 30px 10px;
        }
       
        .argument-bloc { height: auto; }
        .argument-text h2 span  { font-size: 22px; }
        .argument-text h2  {
            padding-top:30px;
            font-size:40px;
            line-height: .7;
        }
        .argument-pic {
            width:100%;
            height:100px;
            background-size:contain;
            margin-top: 20px;

        }
    }
    
     /*== ARGUMENT ANALYSE ==*/
    .argument-bloc.analyse-argument {
        background-color: #F7F7F7;

        .argument-pic {
            background-image:  url("/images/rejoin/graph_pic.png");
            right:-130px;
        }

        span.badge-gas {
            background: #20a1c1;
            padding: 2px 10px;
            margin-right: 5px;
            border-radius: 5px;
            color: white;
            font-size: 14px;
        }
        p {
            font-family: "Open Sans";
            font-size: 16px;
            margin-bottom: 20px;
            line-height: 1.8;
            margin-top: 20px;
        }
    }

    /*== ARGUMENT DEFIS ==*/
    .argument-bloc .defis-argument{ flex-direction: row-reverse; }
    .argument-bloc.defi-argument .argument-pic {
        background-image:  url("/images/rejoin/medal_pic.png");
        width:350px;
        margin-bottom: 20px;
    }
    
    .argument-bloc.defi-argument .argument-content { flex-direction: row-reverse; }
    .argument-bloc.defi-argument .argument-text { padding-left:160px; }
    .argument-bloc .defis-argument{ flex-direction: row-reverse; }
    
     /*== ARGUMENT GIFT ==*/
     .argument-bloc.gift-argument{
        background-color: #F7F7F7;
        margin-bottom: 40px;
    }
    
     .argument-bloc.gift-argument .argument-pic {
        background-image:  url("/images/rejoin/gift_pic.png");
        width:400px;
    }
    
    @media screen and (max-width:991px) {
        .argument-bloc.analyse-argument .argument-pic{ right:0; }
        .argument-bloc.defi-argument .argument-pic {
            width:250px;
            left:30px;
        }
        .argument-bloc.gift-argument .argument-pic{ width:320px; }
        .argument-bloc.defi-argument .argument-text { padding-left:100px; }
    }
    
    @media screen and (max-width:767px) {
        .argument-bloc.defi-argument .argument-content { flex-direction: column; }
        .argument-bloc.defi-argument .argument-text { padding-left: 0px; }
        .argument-bloc.defi-argument .argument-pic {
            width:100%;
            left:0;
        }
        .argument-bloc .argument-text { padding-left:0px; height: auto; }
        .argument-bloc.gift-argument .argument-pic{ width:100%; }
    }
    
    /*== QUESTION END ==*/
    .question-end {
        text-align: center;
        margin-bottom: 60px;
    }
    .question-end p {
        font-family: "Open Sans";
        font-size: 34px;
    }
    .question-end .cta-button{ 
        margin-top: 30px;
        margin-bottom:30px; 
    }
    .question-end a:not(.cta-button) {
        color:black;
        text-decoration:underline;
    }
    
    @media screen and (max-width:767px) {
        .question-end p { font-size: 28px }
    }
}
