/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.loader-3-dots {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0s);
    .desktop-loader {
        display: flex;
        flex-direction: row;
        .dot {
            background-color: $primary-color;
            width: 12px;
            height: 12px;
            margin: 0 5px;
            border-radius: 100px;
            animation: 2s dot3Bouncing ease-in-out infinite;
            &:nth-of-type(2) {
                animation: 2s dot3Bouncing ease-in-out infinite;
                animation-delay: .1s;
            }
            &:last-of-type {
                animation: 2s dot3Bouncing ease-in-out infinite;
                animation-delay: .2s;
            }
        }
    }
    .mobile-loader {
        display: none;
    }
    @include phone {
        .desktop-loader {
            display: none;
        }
        .mobile-loader {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            position: relative;
            align-items: center;
            .dot {
                background-color: $primary-color;
                margin: 0 5px;
                border-radius: 100px;
                transform-origin: center;
            }
            .dot-1 {
                position: relative;
                width: 40px;
                height: 40px;
                animation: dot2Bouncing1 2s infinite;
                background-color: rgba($primary-color, .2);
            }
            .dot-2 {
                position: absolute;
                width: 15px;
                height: 15px;
                right: 32.5px;
                animation: dot2Bouncing2 2s infinite;
                background-color: $primary-color;
            }
        }
    }
    @include little-phone {
        .desktop-loader {
            display: none;
        }
        .mobile-loader {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            position: relative;
            align-items: center;
            .dot {
                background-color: $primary-color;
                margin: 0 5px;
                border-radius: 100px;
                transform-origin: center;
            }
            .dot-1 {
                position: relative;
                width: 40px;
                height: 40px;
                animation: dot2Bouncing1 2s infinite;
                background-color: rgba($primary-color, .2);            }
            .dot-2 {
                position: absolute;
                width: 15px;
                height: 15px;
                right: 27px;
                animation: dot2Bouncing2 2s infinite;
                background-color: $primary-color;            }
        }
    }
    @keyframes dot3Bouncing {
        0% {
            transform: translateY(0px);
        }
        25% {
            transform: translateY(5px);
        }
        50% {
            transform: translateY(-5px);
        }
        75% {
            transform: translateY(5px);
        }
        100% {
            transform: translateY(0px);
        }
    }
    @keyframes dot2Bouncing1 {
        0% {
            transform: scale(0);
        }
        40% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes dot2Bouncing2 {
        0% {
            transform: scale(0);
        }
        20% {
            transform: scale(1.2);
        }
        23%{
            transform: scale(.9);
        }
        26%{
            transform: scale(1.1);
        }
        29%{
            transform: scale(.95);
        }
        32%{
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
}