/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.container-cookies {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 0;
    flex-direction: row;
    display: none;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: rgba($primary-color, 1);
    width: 100%;
    height: 50px;
    font-family: $second-font;
    font-size: 16px;
    font-weight: 300;

    @include slideInUp($duration: 0.2s, $function: ease-in-out);

    a {
        color: black;
    }

    .cookie-agree-btn {
        padding: 10px;
        background-color: black;
        color: white;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        border-radius: 3px;
        transition: .2s ease-in-out;
        border: none;
        margin-right: 20px;
        margin-left: 20px;


        @include phone {
            margin-left: 0px;
        }


        @include tablet {
            margin-left: 20px;
        }


        &:hover {
            background-color: rgb(37, 37, 37);
        }
    }

    .cookies-text {
        margin-left: 30px;
        font-weight: 500;
    }

    .btn-list {
        display: flex;
        flex-direction: row;
    }

    @include tablet {
        font-size: 14px;
        height: 80px;

        .cookies-text {
            width: 60%;
        }
    }


    @include phone {
        font-size: 14px;
        height: 150px;

        .cookies-text {
            padding: 0 15px;
            margin-bottom: 10px;
        }

        .btn-list {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
        }
        .agree-btn {
            margin-right: 0px;
            margin-left: 0;
        }

        flex-direction: column;
    }
}

.modal-cookies {
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow-y: scroll;

    > .modal-content {
        position: relative;
        width: 60%;
        margin: 150px 0;
        font-family: $second-font;
        background-color: #fefefe;
        border-radius: 10px;
        padding: 20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2000;
        box-shadow: 0px 0px 36px -10px rgba(0, 0, 0, 0.25);

        .optin-text {
            margin-top: 5px;
            font-weight: 300;
            width: 80%;

            @include phone {
                width: 70%;
            }
        }

        > h2 {
            font-size: 30px;
            margin: 20px 0;
            font-family: $first-font;
        }

        > p {
            margin-bottom: 15px;
            padding: 0 20px;
            font-family: "Open Sans", sans-serif;
            line-height: 19px;
            font-size: 16px;
            font-weight: 300;
            text-align: justify;
        }

        .consent-container {
            display: flex;
            align-items: center;
            align-content: center;

            > h3 {
                margin-right: 20px;
            }
        }

        > button {
            background-color: $primary-color;
            border: none;
            padding: 7px 35px;
            border-radius: 40px;
            text-decoration: none;
            color: black;
            transition: .2s ease-in-out;
            font-size: 14px;
            margin-right: 10px;
            cursor: pointer;

            &:hover {
                background-color: $primary-color-hover;
            }
        }
    }

    @include phone {
        > .modal-content {
            width: 90%;
            padding: 0;
            text-align: center;
            padding-bottom: 20px;

            > h2 {
                font-size: 22px;
                padding: 20px 0;
                margin: 0;
            }

            .consent-container {
                > h3 {
                    margin-left: 20px;
                }
            }
        }
    }
}
