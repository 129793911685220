/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.body-lnd-comparaison {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .lnd-comparaison-container {
        flex: 1;
        display: flex;
        flex-direction: column;

        .header-lnd-comparaison {
            width: 100%;
            height: 140px;
            background-color: white;
            display: flex;
            justify-content: center;
            align-items: center;

            @include phone {
                height: 85px;
            }

            .header-content-container {
                max-width: 1200px;
                width: 100%;
                display: flex;
                flex-direction: row;

                .logo-eni {
                    height: 100px;
                    margin-left: 10%;
                    @include phone {
                        height: 50px;
                    }
                }

                .title-header {
                    font-family: $first-font;
                    font-size: 30px;
                    font-weight: 600;
                    align-self: flex-end;
                    margin-left: 40px;
                    @include phone {
                        font-size: 20px;
                    }
                }
            }
        }

        .top-slider-container {
            background-color: $primary-color;
            width: 100%;
            height: 330px;
            max-height: 330px;
            display: flex;
            justify-content: center;
            position: relative;
            overflow: hidden;

            @include phone {
                height: 230px;
            }

            .slider-content-container {
                max-width: 1200px;
                width: 100%;
                display: flex;
                flex-direction: row;
                position: relative;

                .bg-slider {
                    // position: absolute;
                    height: 105%;
                    right: 0px;
                    z-index: 2;
                    top: 0;
                    margin-left: 245px;
                }

                .text-slider {
                    position: absolute;
                    font-family: $first-font;
                    font-size: 40px;
                    z-index: 4;
                    width: 50%;
                    margin-top: calc(330px / 4);

                    .strong {
                        font-weight: 600;
                        margin-left: 5px;
                    }

                    @include phone {
                        margin-top: 30px;
                        font-size: 30px;
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }

        .profile-container {
            background-color: #f7f7f7;
            padding: 30px 0;
            font-family: $first-font;
            font-size: 40px;
            display: flex;
            justify-content: center;

            .profile-content-container {
                max-width: 1200px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                z-index: 9;

                @include phone {
                    margin-top: -130px;
                }

                .profile-picture-container {
                    width: 200px;
                    height: 200px;
                    border-radius: 1000px;
                    background-image: url('/images/icons/user_circle_ico.svg');
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-color: $primary-color;

                    @include phone {
                        border: solid 5px #ffd401;
                    }
                }

                .text-profile {
                    width: 60%;
                    margin-top: 20px;
                    text-align: center;

                    .strong {
                        font-weight: 600;
                        margin-left: 5px;
                    }

                    @include phone {
                        font-size: 24px;
                        width: 90%;
                    }
                }
            }
        }

        .comparaison-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;
            .saucisse-container {
                max-width: 1200px;
                width: 50%;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;
                font-family: $first-font;

                @include phone {
                    width: 90%;
                }

                .title-container {
                    font-size: 30px;
                    font-family: $first-font;
                    margin: 5px 0 20px 0;
                    font-weight: 400;
                    text-align: center;
                    margin-top: 50px;

                    @include tablet {
                        margin-top: 10px;
                    }
                    
                    @include phone {
                        margin-top: 10px;
                        font-size: 24px;
                    }
                }

                .content-container {
                    display: flex;
                    flex-direction: row;
                    width: 100%; // height: 100%;
                    justify-content: center;
                    margin-top: 15px;

                    @include phone {
                        flex-direction: column;
                    }


                    .emote-container {
                        flex: 1;
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                        flex-direction: column;

                        @include phone {
                            margin-top: 40px;
                        }


                        .emote-image {
                            width: 100px;
                            margin: 10px 0;
                            margin-right: 50px;

                            @include bounceIn($duration: 0.4s, $function: ease-in-out, $delay: 0.7s);

                            @include phone {
                                margin: auto;
                            }

                        }

                        .emote-description {
                            font-weight: 400;
                            width: 200px;
                            font-family: $second-font;
                            text-align: center;

                            @include tablet {
                                padding: 0 10px;
                            }
                            @include phone {
                                margin: auto;
                            }
                        }

                        .title-emote {
                            font-weight: 600;
                        }
                    }

                    .chart-ranking-container-placeholder {
                        padding: 50px 50px 0 50px;
                    }

                    .chart-ranking-container {
                        padding: 0 20px;
                        flex: 3;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        @include phone {
                            padding: 0;
                        }

                        .chart-tooltip-container {
                            display: flex;
                            flex-direction: row;
                            background-color: #35383f;
                        }

                        .chart-rank {
                            width: 100%;
                            height: 25px;
                            border-radius: 100px;

                            .score-alone {
                                margin-right: 0px;
                                margin-left: 30px !important;
                            }
                        }

                        .middle {
                            margin: 10px 0;
                            background-color: $primary-color;
                        }

                        .one,
                        .two {
                            width: 1%;
                            position: relative; // transition: .4s ease-in-out;
                            background-color: #9ad877;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            overflow: hidden;

                            img {
                                border-radius: 300px;
                                position: absolute;
                                left: 0;
                                width: 16px;
                                color: white;
                                top: 1px;
                                padding: 4px;
                                background-color: $button-color;
                            }

                            .user {
                                margin-left: 30px;
                                top: 5px;
                                color: white;
                                font-weight: 300;

                                @include phone {
                                    font-size: 14px;
                                    overflow: hidden;
                                }


                                @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.8s);
                            }

                            .score {
                                margin-right: 15px;
                                color: white;
                                font-weight: 300;
                                overflow: hidden;

                                @include phone {
                                    font-size: 14px;
                                    overflow: hidden;
                                }


                                @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.7s);
                            }
                        }

                        .two {
                            background-color: $primary-color;
                            width: 1%;

                            img {
                                background-color: $primary-color-hover;
                                color: black;
                            }

                            .user {
                                margin-left: 30px;
                                top: 5px;
                                color: black;
                                font-weight: 300;

                                @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.85s);
                            }

                            .score {
                                margin-right: 15px;
                                color: black;
                                font-weight: 300;
                            }
                        }

                        .three {
                            width: 1%; // -webkit-transition: width 2s ease 0s;
                            position: relative;
                            background-color: #444750;
                            display: flex; // transition: 0.4s ease-in-out;
                            justify-content: space-between;
                            align-items: center;
                            overflow: hidden;

                            img {
                                border-radius: 100px;
                                position: absolute;
                                left: 0;
                                width: 16px;
                                color: white;
                                top: 1px;
                                padding: 4px;
                                background-color: #373941;
                            }

                            .user {
                                margin-left: 30px;
                                top: 5px;
                                color: white;
                                font-weight: 300;

                                @include phone {
                                    font-size: 14px;
                                    overflow: hidden;
                                }


                                @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.9s);
                            }

                            .score {
                                margin-right: 15px;
                                color: white;
                                font-weight: 300;

                                @include phone {
                                    font-size: 14px;
                                    overflow: hidden;
                                }


                                @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.7s);
                            }
                        }

                        .color-caption-container {
                            display: none;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row;
                            margin-top: 30px;

                            .color-caption {
                                display: flex;
                                justify-content: center;
                                flex-direction: row;
                                align-items: center;
                                margin: 0 20px;

                                &:first-of-type {
                                    .color-round {
                                        background-color: #9ad877;
                                    }
                                }

                                &:last-of-type {
                                    .color-round {
                                        background-color: #444750;
                                    }
                                }

                                .color-round {
                                    width: 15px;
                                    margin-right: 5px;
                                    height: 15px;
                                    background-color: $primary-color;
                                    border-radius: 100px;
                                }

                                .caption {
                                    font-family: $first-font;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .information-container {
                    font-size: 14px;
                    margin-top: 10px;
                    font-weight: 400;
                    margin-top: 30px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    display: none;

                    @include phone {
                        margin-top: 40px;
                    }


                    @include fadeIn($duration: 0.2s, $function: ease-in-out, $delay: 0.2s);

                    .info-icon {
                        width: 8px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .icon-features-comparaison {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 50px;

            @include phone {
                margin-bottom: 10px;
            }

            .features-container {
                max-width: 1200px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                @include phone {
                    width: 90%;
                }

                .title-features {
                    font-family: $first-font;
                    font-size: 30px;
                    margin: 20px 0;
                    
                    @include phone {
                        font-size: 24px;
                    }

                }

                .icon-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80%;

                    @include phone {
                        flex-direction: column;
                        width: 100%;
                    }

                    .icon-card {
                        display: flex;
                        flex: 1;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        @include phone {
                            flex-direction: row;
                        }
                        
                        .icon {
                            height: 100%;
                            max-height: 200px;

                            @include phone {
                                max-height: 80px;
                                margin-left: -20px;
                            }
                        }

                        .subtitle-card {
                            font-family: $second-font;
                            font-size: 16px;
                            text-align: center;
                            padding: 0 80px;
                            margin-top: 20px;

                            @include phone {
                                margin: 0;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }

        .cta-join-us{
            display: none;
            background-color: $primary-color;
            padding: 10px 75px;
            border-radius: 1000px;
            text-decoration: none;
            align-self: center;
            color: black;
            font-family: $first-font;
            font-size: 22px;
            margin-bottom: 100px;
            transition: .2s ease-in-out;

            &:hover {
                cursor: pointer;
                background-color: $primary-color-hover;
            }
        }
    }
}