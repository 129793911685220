.slider-top-info {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;

    max-height: 370px;
    overflow: hidden;
    .tiny-slider-top-info {
        font-family: $first-font;
        font-weight: 300;
        height: 370px;
        max-height: 370px;

        > * {
            background-color: $primary-color;
            z-index: 0;
            height: inherit;
        }

        .gas-slide{
            background-color: #0fa2c0;
            .content-gas{
                h1, p{
                    color: white;
                }

                .bold-title {
                    &:before {
                        content: '';
                        position: absolute;
                        background-color: white;
                        width: 90px;
                        height: 4px;
                        margin-top: 110px;
                    }
                }

                .house-image-container{
                    position: relative;
                    width: 100%;
                    height: 100%;
                    margin-left: 150px;
                    z-index: 4;
                    img{
                        position: absolute;
                        top: 0;
                        left: 70px;
                        height: 100%;
                    }
                }
            }
        }

        // SLIDES

        > .slide {
            overflow: hidden;
        }

        .content {
            margin: auto;
            max-width: 1360px;
            height: inherit;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // overflow: hidden;
            flex-direction: row;
        }

        .text {
            z-index: 10;
            position: absolute;
            margin-left: 60px;
            > h1 {
                color: black;
                font-size: 44px;
                font-weight: $light-weight;
            }

            > p {
                color: black;
                margin-top: 30px;
                margin-bottom: 25px;
                font-weight: 400;
                font-size: 22px;
            }

            > a {
                font-size: 16px;
                padding: 9px 20px;
                font-family: "Open sans";
            }

            .bold {
                font-weight: $bold-weight;
            }

            .bold-title {
                font-weight: $bold-weight;

                &:before {
                    content: '';
                    position: absolute;
                    background-color: black;
                    width: 90px;
                    height: 4px;
                    margin-top: 60px;
                }
            }

            > a {
                @extend %primary-button;
                text-decoration: none;
                font-size: 16px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                align-self: flex-start;
                width: fit-content;

                img {
                    margin-left: 7px;
                    width: 15px;
                    height: 15px;
                }
                &:hover{
                    background-color: $secondary-color-hover;
                    cursor: pointer;
                }
            }
        }

        .house-image-container{
            position: relative;
            width: 100%;
            height: 100%;
            margin-left: 270px;
            z-index: 4;
            img{
                position: absolute;
                top: 0;
                left: 70px;
            }
        }

        .energy-profile-image-container{
            position: relative;
            width: 100%;
            height: 100%;
            margin-left: 270px;
            z-index: 4;
            img{
                position: absolute;
                top: 0;
                left: 70px;
            }
        }

        .gift-image-container{
            position: relative;
            width: 100%;
            height: 100%;
            margin-left: 300px;
            z-index: 4;
            img{
                position: absolute;
                top: 0;
                left: 70px;
            }
        }
    }

    // SLIDER PART

    .tns-controls, .tns-nav {
        z-index: 1;
        position: absolute;
    }

    .tns-nav {
        text-align: center;
        width: 100%;
        bottom: 30px;

        > button {
            padding: 0;
            background-color: #fff;
            height: 12px;
            width: 12px;
            border-radius: 100px;
            border: none;
            margin-right: 15px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

            &.tns-nav-active {
                background-color: $primary-color;
            }
        }
    }

    .slider-arrows {
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 370px;
        width: 100%;
        max-width: 1360px;
        align-self: center;

        > * {
            display: inline-block;
            background-image: url(/images/icons/arrow_carousel.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            cursor: pointer;
            width: 18px;
            height: 29px;
            transition: .2s;
            z-index: 1;

            &:hover {
                opacity: .7;
                transition: .2s;
            }

            &:before {
                display: none;
            }
        }

        .arrow-prev {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            margin-left: 5px;
            transition: .2s ease-in-out;
            @include limit-screen{
                margin-left: 20px;
            }
        }
        .arrow-next{
            margin-right: 5px;
            @include limit-screen{
                margin-right: 20px;
            }
            z-index: 12;
        }
    }

    @include phone {
        .tns-nav {
            > button {
                display: none;
            }
        }

        .tiny-slider-top-info {
            height: 200px;
            max-height: 200px;
        }

        .first-slide,
        .slide-c,
        .slide-e,
        .slide-b,
        .slide-d {
            background: $primary-color;
            .house-image-container{
                display: none;
            }
            .gift-image-container{
                display: none;
            }
            .energy-profile-image-container{
                display: none;
            }
            .text {
                margin-left: 20px;
                > h1 {
                    color: black;
                    font-size: 25px;
                    font-weight: $light-weight;
                }

                > p {
                    color: black;
                    margin-top: 20px;
                    margin-bottom: 25px;
                    font-size: 15px;
                }

                > a {
                    font-size: 16px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 55%;
                    padding-right: 10px;
                    img {
                        width: 10px !important;
                        height: 10px !important;
                    }
                }
            }

            .bold-title {
                &:before {
                    display: none;
                }
            }
        }

        .slide-d, .slide-e{
            .text {
                > h1 {
                    color: black;
                    font-size: 20px;
                    font-weight: $light-weight;
                }

                > p {
                    color: black;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    font-size: 12px;
                }
            }
        }

        .slider-arrows {
            display: none;

            > * {
                width: 10px;
                height: 20px;
            }

        }
    }

    @include little-phone{
        .text {
            > h1 {
                color: black;
                font-size: 20px;
                font-weight: $light-weight;
            }

            > p {
                color: black;
                margin-top: 10px;
                margin-bottom: 25px;
                font-size: 15px;
            }

            > a {
                font-size: 16px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 40%;
                padding-right: 10px;
                img {
                    width: 10px !important;
                    height: 10px !important;
                }
            }
        }
    }

    @include tablet {
        .tns-nav {
            > button {
                display: none;
            }
        }

        .tiny-slider-top-info {
            height: 300px;
            max-height: 300px;
        }

        .first-slide,
        .slide-b,
        .slide-c,
        .slide-e,
        .slide-d {
            .house-image-container{
                height: 115%;
                margin-left: 23%;
                img {
                    top: 20px;
                    height: 100%;
                }
            }
            .gift-image-container{
                height: 115%;
                margin-left: 23%;
                img {
                    top: 20px;
                    height: 100%;
                }
            }
            .energy-profile-image-container{
                height: 115%;
                margin-left: 23%;
                img {
                    top: 20px;
                    height: 100%;
                }
            }

            .text {
                > h1 {
                    color: black;
                    font-size: 40px;
                    font-weight: $light-weight;
                }

                > p {
                    color: black;
                    margin-top: 20px;
                    margin-bottom: 25px;
                    font-size: 20px;
                }

                > a {
                    font-size: 16px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 50%;
                }
            }

            .bold-title {
                &:before {
                    display: none;
                }
            }
        }

        .slider-arrows {
            height: 300px;

            > * {
                width: 20px;
                height: 20px;
            }
            .arrow-prev{
                margin-left: 20px;
            }
            .arrow-next{
                margin-right: 20px;
            }
        }
    }
}

// .banner-below-top-info {
//   width: 100%;
//   background-color: #DAD3CD;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   justify-items: center;
//   align-items: center;
//   align-content: center;
//   padding: 15px 0px;
//   font-family: "Eni", sans-serif;
//   .text-container {
//     margin-right: 25px;
//   }
//   .button-container {
//     .banner-button {
//       padding: 5px 24px;
//       background-color: #353841;
//       color: white;
//       text-decoration: none;
//     }
//   }
// }
