/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

 .body-legal{
    @include fadeIn( $duration: .2s, $function: ease-in-out, );
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
        list-style: disc;
        padding-left: 40px;
        
        li {
            line-height: 24px;
        }
    }

    .legal-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: $second-font;
        font-size: 16px;
        max-width: 1360px;
        @include limit-screen{
            padding: 0 20px;
        }
        @include tablet{
            padding: 0 20px;
        }
        @include phone{
            padding: 0 20px;
        }
        @include little-phone{
            padding: 0 20px;
        }
        .title-container{
            width: 100%;
            text-align: center;
            font-size: 40px;
            font-weight: 100;
            margin-bottom: 30px;
            margin-top: 30px;
        }
        hr {
            width: 40%;
            height: 3px;
            background-color: $primary-color;
            border: none;
            margin-bottom: 70px;
        }
        .title-legal{
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 32px;
            margin-top: 64px;
        }
        .subtitle{
            font-weight: 300;
            font-size: 20px;
            width: 70%;
            margin-bottom: 64px;
        }
        .text-legal{
            font-family: $second-font;
            margin-bottom: 10px;
            line-height: 19px;
            font-size: 16px;
            font-weight: 300;
        }
        .btn-container{
            display: flex;
            justify-content: center;
            width: 100%;
            .prev-btn{
                text-align: center;
                margin-bottom: 50px;
                margin-top: 50px;
            }
        }  
    }
}