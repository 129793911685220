.consumption-weeks {
    background: #fff;
    font-family: $first-font;
    display: flex;
    flex-direction: column;
    color: $secondary-color;

    .unit-selection {
        margin: 15px 30px 0 0;

        > button {
            float: right;

            @extend %primary-button;

            padding: 5px 30px;
        }
    }

    > h2 {
        text-align: center;
        font-size: 25px;
        height: 80px;
    }

    .cons-content {
        display: flex;
        height: 100%;

        .chart-week-container {
            width: 50%;
            height: inherit;

            > p {
                margin-left: 30px;
            }
        }

        #chart-week {
            width: 100%;
            height: 100%;
        }

        .price-yesterday {
            width: 50%;

            :not(:first-child) {
                text-align: center;
            }

            > p {
                margin-left: 30px;
            }
        }

        .price {
            font-family: $third-font;
            font-size: 110px;
            margin-top: 50px;

            @include bounceIn;
        }

        .btn-container {
            margin-top: 40px;
        }

        .button-analysis {
            @extend %secondary-button;

            color: #000;
            text-decoration: none;
        }
    }
}
