/*
 * Copyright (C) 2020 GridPocket SAS - All Rights Reserved
 * Unauthorized usage, modification or copying of this file, via any medium are strictly prohibited
 * Proprietary and confidential
 */

.feedback-button {
  font-family: Eni;
  background: #ffd401;
  border: solid 1px #f5ce0f;
  position: fixed;
  text-align: center;

  top: 50vh;
  right: -66px;
  transform: rotate(-90deg);
  padding: 5px 10px !important;
  box-shadow: #00000036 0px 0px 20px;

  z-index: 9999;

  &:hover {
    cursor: pointer;
  }

  @include phone {
    box-shadow: 1px 1px 4px #8c8c8c;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    padding: 0px 0px !important;
    transform: none;
    
    right: 15px;
    bottom: 15px;

    top: auto;
  }

  >i {
    font-size: 1.4em;
    @include phone {
      line-height: 2.6em;
    }
  }

  >#feedback-text {
    border-left: solid 1px black;
    padding: 0px 10px;
    margin-left: 5px;
    @include phone {
      display: none;
    }
  }
}

.feedback-modal-overlay {
  background: rgba(75, 75, 75, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;
  display: none;

  .modal-feedback {
    font-family: Eni;
   
    z-index: 9999;
    * {
      z-index: 9999;
    }
  
    position: fixed;
    top: 10%;
    left: calc((100% - 400px) / 2);
    width: 400px;
    background: white;
    border-radius: 3px;

    @include phone {
      width: 100%;
      left: 0px;
      top: 0px;
    }

  
    .modal-header {
      position: relative;
      background: #ffd401;
      padding: 20px;
      
      .close-button {
        position: absolute;
        display: block;
        right: 10px;
        top: 10px;
  
        &:hover {
          cursor: pointer;
        }
      }
  
      h4 {
        font-size: 28px;
        margin-bottom: 20px;
        margin-top: 25px;
      }
    }
  
    .modal-body {
      padding: 10px 0px 20px 0px;
      form {
        p {
          margin-left: 20px;
        }
        
        .stars {
          margin-bottom: 10px;
          .rate-title.has-error {
            color: #F44336;
          }
        
          ul {  
            padding: 10px 0px;
            text-align: center;
    
            list-style-type:none;
              -moz-user-select:none;
              -webkit-user-select:none;
    
            li {
              display: inline-block;
              
              i {
                font-size: 2.5em;
                color:#ccc;
              }
  
              &:hover {
                cursor: pointer;
              }
    
              &.hover {
                i {
                  color:#FFCC36;
                }
              }
              
              &.selected {
                i {
                  color:#FF912C;
                }
              }
            }
          }
        }
    
        textarea {
          border: none;
          background: #f7f7f7;
          width: calc(100% - 40px);
          height: 200px;
          resize: none;
          font-size: 14px;
          padding: 10px 20px;
  
          &.has-error {
            border: solid 1px #F44336;
            width: calc(100% - 40px - 2px);
          }
        }
  
        .charracters-count {
          font-size: 12px;
          text-align: right;
          margin-right: 20px;
  
          &.full {
            color: red;
          }
        }
    
        .options {
          padding: 10px 20px;
  
          ul {
            list-style-type:none;
            padding:0;
              -moz-user-select:none;
              -webkit-user-select:none;
    
            li {
              display: inline-block;
              margin-right: 10px;
            }
          }
        }
      }

      .thanks {
        text-align: center;
        h2 {
          font-size: 24px;
          margin-bottom: 15px;
        }

        i {
          color: #353841;
          background: #FFD300;
          border-radius: 100%;
          font-size: 3em;
          padding: 25px;
          margin: 15px;
        }
      }
  
      button {
        background-color: #ffd401;
        padding: 7px 35px;
        width: calc(100% - 40px);
        border-radius: 40px;
        text-decoration: none;
        color: black;
        transition: .2s ease-in-out;
        border: none;
        margin-left: 20px;
        margin-top: 10px;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}